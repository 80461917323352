import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink ,useLocation, useNavigate} from "react-router-dom";
import logo from "../assets/images/logoWithText.svg";
import { Button } from "react-bootstrap";
import { AppDispatch } from "../interface/userInterface";
import {  useDispatch, useSelector } from "react-redux";
import { logout, setLoginResponse, setSocialAccount } from "../store/features/auth/authSlice";
import { setUserFilters } from "../store/features/user/userSlice";



interface AdminSidebarProps {}

const AdminSidebar: React.FC<AdminSidebarProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()
  const filters = useSelector((state: any) => state?.user?.userFilters)


  const logoutFunction = ()=>{
      localStorage.clear()
      dispatch(logout(null))
      dispatch(setLoginResponse(null))
      dispatch(setSocialAccount(false))
      const filterData = {
        ...filters,
        filters: {},
      }
       dispatch(setUserFilters(filterData))
      location?.pathname?.includes("/admin")  ? navigate("/admin/login") : navigate("/login")
  }

  return (
    <div className="sidebar">
         <nav id="sidebar">
         <img src={logo} alt="logoWithText" className="dashboardLogo" />         
          <div className="sidebarMenu">
          <NavLink
          to="/admin/dashboard"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <span className="icon icon-home"></span>
          <p>{t("DASHBOARD")}</p>
        </NavLink>
        <NavLink
          to="/admin/users"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <span className="icon icon-user" />
          <p>{t("USERS")}</p>
        </NavLink>

        <NavLink
          to="/admin/upload"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <span className="icon icon-upload" />
          <p>{t("DATA_UPLOAD")}</p>
        </NavLink>

        <NavLink
          to="/admin/settings"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <span className="icon icon-settings" />
          <p>{t("SETTINGS")}</p>
          </NavLink>
            </div> 
      </nav>
      <Button className="logOut" onClick={()=> logoutFunction()}><span className="icon icon-logOut"></span>{t("LOG_OUT")}</Button>

    </div>
  );
};

export default AdminSidebar;
