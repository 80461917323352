import { USER_DASHBOARD,LEAGUE_LIST,AIRPORT_LIST ,AIRPORT_DEPARTURE} from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { listDashboardQueryParams } from "../../../interface/userInterface";


const dashBoardDetails =(query:listDashboardQueryParams )=>{
  return instance.get(USER_DASHBOARD,{ params: query });
}
const getLeagueList =()=>{
  return instance.get(LEAGUE_LIST)
}

const getAirportList =()=>{
  return instance.get(AIRPORT_LIST)
}

const getAirportDeparture =()=>{
  return instance.get(AIRPORT_DEPARTURE)
}


const userDashboardService ={
  dashBoardDetails,
  getLeagueList,
  getAirportList,
  getAirportDeparture
}

export default userDashboardService;