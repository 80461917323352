import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthHeader from "../../../components/authHeader/authHeader";
import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import CheckBox from "../../../components/checkBox/checkBox";
import googleIcon from "../../../assets/images/Icons/googleIcon.svg";
import facebookIcon from "../../../assets/images/Icons/facebookIcon.svg";
import "../auth.css";
import { IResolveParams, LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import Loader from "../../../components/loader/Loader";
import { Button, Modal } from "react-bootstrap";

interface SignInProps {
  signInForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  busy: boolean;
  // setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPassword: any;
  showPassword: boolean;
  rememberMeFunction: (value: boolean) => void,
  rememberMe: boolean;
  handleSocialLogin: any;
  errorMsg?: string;
  socialLoading: boolean,
  assiocate: boolean;
  errorMsgLogin?: string;
  setAssiocate: any;
  associateLogin:any
  emailModal:boolean
  emailProvide:any,
  setEmailModal:any
}

const SignInComponent: React.FC<SignInProps> = (props) => {
  const { t } = useTranslation();
  const { signInForm, onFormSubmit, busy, errorMsg, rememberMe, rememberMeFunction, setShowPassword, showPassword, handleSocialLogin, socialLoading, assiocate,errorMsgLogin ,setAssiocate,associateLogin,emailModal,emailProvide,setEmailModal} = props;
  console.log('errorMsg :>> ', errorMsg);
  return (
    <React.Fragment>
      <div className="authPages">
        <div className={socialLoading ? "authCenter blur-effect" : "authCenter"}>
          <div className="whiteBack">
            <AuthHeader
              headerTitle={t("SIGN_INTO_YOUR_ACCOUNT")}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                      <div className="textField">
                        <TextField
                          icon="icon icon-mail"
                          placeholder={t("EMAIL_ADDRESS")}
                          autoFocus={true}
                          formMethod={formMethod}
                          rules={signInForm.email.validate}
                          name={signInForm.email.name}
                          errors={formMethod?.formState.errors}
                          type="text"
                          auth={true}
                        />
                        <TextField
                          placeholder={t("PASSWORD")}
                          autoFocus={false}
                          formMethod={formMethod}
                          rules={signInForm.password.validate}
                          name={signInForm.password.name}
                          errors={formMethod?.formState.errors}
                          type="password"
                          showPassword={showPassword}
                          togglePasswordVisibility={() =>
                            setShowPassword((prev: any) => !prev)
                          }
                          auth={true}
                        />
                        {formMethod?.formState.errors && !formMethod?.formState.errors[signInForm.password.name] && (errorMsg && <div className="errorMsg" id='error-msg'><span className="icon-info"></span> {errorMsg}</div>)}
                      </div>
                      <CheckBox
                        checkboxClass="form-check-input"
                        id="rememberMe"
                        labelTitle={t("REMEMBER_ME")}
                        checked={rememberMe}
                        onCheckedChange={(checked) => rememberMeFunction(checked)}
                      />
                      <CustomButton
                        className="btnAuth"
                        title={t("SIGN_IN")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm >
              <div className="forgotPassCenter">
                <Link to="/forgotpassword" className="forgotPasswordLink">
                  {t("FORGOT_PASSWORD")}
                </Link>
              </div>
            </div >
            <div className="loginOptMain">
              <hr></hr><p className="loginOpt">{t("LOGIN_WITH")}</p>
              <hr></hr>
            </div>

            <div className='loginIcons'>
              {process.env.REACT_APP_FACEBOOK_APP_ID && <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
                onResolve={({ provider, data }: IResolveParams) => {
                  handleSocialLogin(provider, data)
                }}
                onReject={(err) => {
                  console.log("facebook login error: ", err)
                }}
              >
                <img
                  src={facebookIcon}
                  alt='Facebook Icon'
                  className='socialIcon'
                />
              </LoginSocialFacebook>}

              {process.env.REACT_APP_GOOGLE_APP_ID && <LoginSocialGoogle
                // isOnlyGetToken
                client_id={process.env.REACT_APP_GOOGLE_APP_ID || ''}
                scope="openid profile email"
                onResolve={({ provider, data }: IResolveParams) => {
                  handleSocialLogin(provider, data)
                }}
                onReject={(err) => {
                  console.log("google login error: ", err)
                }}
              >
                <img
                  src={googleIcon}
                  alt='Google Icon'
                  className='socialIcon'
                />
              </LoginSocialGoogle>}
            </div>

            <div className="forgotPassCenter">
              <Link to="/signup" className="forgotPasswordLink">
                {t("SIGNUP_HERE")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {socialLoading && <div className="fade offcanvas-backdrop show">
        <Loader />
      </div>}

      {assiocate && <Modal className="signup-modal" show={true} onHide={()=> setAssiocate(false)}>
        <Modal.Header >
          <Modal.Title>Associate User</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMsgLogin}</Modal.Body>
        <Modal.Footer>
          <Button className="active-yes" onClick={()=>associateLogin()}> Yes </Button>
          <Button className="deactive-no" onClick={()=> setAssiocate(false)}> No </Button>
        </Modal.Footer>
      </Modal>}

      {emailModal && <Modal className="signup-modal" show={true} onHide={()=> setEmailModal(false)}>
        <Modal.Header >
          <Modal.Title>Email </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => emailProvide(e)}>
                {(formMethod) => {
                  return (
                    <>
                      <div className="textField">
                        <TextField
                          icon="icon icon-mail"
                          placeholder={t("EMAIL_ADDRESS")}
                          autoFocus={true}
                          formMethod={formMethod}
                          rules={signInForm.email.validate}
                          name={signInForm.email.name}
                          errors={formMethod?.formState.errors}
                          type="text"
                          auth={true}
                        /> 
                      </div>

                      <CustomButton
                        className="btnAuth"
                        title={t("SIGN_IN")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm>
            </div>
        </Modal.Body>
      </Modal>}
    </React.Fragment>
  );
};
export default SignInComponent;
