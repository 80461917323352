import React, { useEffect, useRef, useState } from "react";

interface TextResizeProps {
  text: string;
  eventCard?: number; // Add eventCard prop
  defaultFontSize?: number; // Optional prop for default font size
  maxFontSize?: number; // Optional prop for maximum font size
}

const TextResize: React.FC<TextResizeProps> = ({ 
  text = "", 
  eventCard, 
  defaultFontSize = 18, // Default initial font size
  maxFontSize = 48 // Default maximum font size
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState<number>(defaultFontSize);

  useEffect(() => {
    const resizeText = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const fontSizeRatio = eventCard 
          ? (containerWidth / text.length) + eventCard // Adjust ratio for event cards
          : (containerWidth / text.length) + 5; // Adjust ratio for non-event cards
        const newFontSize = Math.min(fontSizeRatio, maxFontSize); // Use the provided max font size
        setFontSize(newFontSize);
      }
    };
    resizeText();
    window.addEventListener("resize", resizeText);
    return () => {
      window.removeEventListener("resize", resizeText);
    };
  }, [text, eventCard, maxFontSize]);
  

  return (
    <div lang="de" ref={containerRef} style={{ fontSize: `${fontSize}px`}} className="word-correct-spacing">
      {text}
    </div>
  );
};

export default TextResize;
