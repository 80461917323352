import { ADMIN_DASHBOARD } from "../../../api/endPoint";
import instance from "../../../helpers/axios";


const dashBoardDetails =(queryParams:any)=>{
  return instance.get(ADMIN_DASHBOARD,{params:queryParams});
}

const adminDashboardService ={
  dashBoardDetails
}

export default adminDashboardService;