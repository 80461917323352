import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../../store/features/toast/toastSlice";
import Constant from "../../../../utils/constant";
import ForgotPasswordComponent from "./forgotPasswordComponent";
import { AppDispatch } from "../../../../interface/authInterface";
import { forgotPassword } from "../../../../store/features/auth/authSlice";

const ForgotPasswordContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
	const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')

  useEffect(() => {
		if (token && authResponse?.data?.accessToken && authResponse?.data?.role === "admin") {
				navigate("/admin/dashboard")
		}else {
			setLoading(false)
		}
	}, [authResponse])


// forgotPasswordContainer
const onFormSubmit = async (value: any) => {
  setBusy(true);

  dispatch(forgotPassword(value)).then((resultAction) => {
    if (forgotPassword.fulfilled.match(resultAction)) {
      dispatch(
        showToast({
          message: (resultAction as any)?.payload?.message,
          type: "success",
        })
      );
      setBusy(false);
      navigate("/admin/login");
    } else if (forgotPassword.rejected.match(resultAction)) {
      setBusy(false);
      dispatch(
        showToast({
          message: resultAction?.error?.message,
          type: "error",
        })
      );
    }
  });
};

  const forgotForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t('EMAIL_ADDRESS_IS_REQUIRED')
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("ENTER_VALID_EMAIL_ADDRESS"),
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },
  };

  if (loading) return null
  
  return (
    <>
      <Helmet>
        <title>Forgot Password | Triptix Admin Portal</title>
        <meta name="description" content="Forgotten your password? Reset it on this page to log in to your admin account." />
      </Helmet>
      <ForgotPasswordComponent
        forgotForm={forgotForm}
        onFormSubmit={onFormSubmit}
        busy={busy}
      />
    </>
  );
};

export default ForgotPasswordContainer;
