import React, { useState, useEffect, ChangeEvent } from "react";
interface CheckBoxProps {
  labelTitle: any;
  id?: string;
  onCheckedChange?: (checked: boolean) => void;
  register?: React.RefObject<HTMLInputElement>;
  name?: string;
  disabled?: boolean;
  checkboxClass?: string;
  checked?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const {
    labelTitle,
    id,
    onCheckedChange,
    register,
    name,
    disabled,
    checkboxClass,
  } = props;
  const [checked, setChecked] = useState<boolean>(props.checked || false);

  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);

  const onChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onCheckedChange && onCheckedChange(e.target.checked);
  };

  return (
    <div className="checkBoxMain">
      <input
        type="checkbox"
        ref={register}
        disabled={disabled}
        name={name}
        value={labelTitle}
        id={id}
        className={"checkbox " + checkboxClass}
        checked={checked}
        onChange={onChecked}
      />
      {checked ? (
        <span className="icon-checked"></span>
      ) : (
        <span className="icon-unchecked"></span>
      )}
      <label className="checkboxLabel" id="checkLabel" htmlFor={id}>
        {labelTitle}
      </label>
    </div>
  );
};

CheckBox.defaultProps = {
  id: "",
  checked: false,
  labelTitle: "Title",
};

export default CheckBox;
