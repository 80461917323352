import React, { useEffect, useRef, useState } from 'react'
import FootballComponent from './footballComponent'
import { Helmet } from 'react-helmet';
import matchCardData from "../../utils/matchesCardData.json"
import { setFootballFilters } from '../../store/features/footballEvent/footballEventSlice';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, listDashboardQueryParams } from "../../interface/userInterface";
import { useNavigate } from 'react-router-dom';
import useWindowDimension from '../../components/hooks/useWindowDimension';
import { footballDetails,paginationFunction } from '../../store/features/footballEvent/footballEventSlice';
import { showToast } from '../../store/features/toast/toastSlice';
import moment from 'moment';
import { Flight } from '../../interface/eventInterface';

const FootballContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const dimensions = useWindowDimension();
  const [show, setShow] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchResult, setSearchResult] = useState(false);
  const [range, setRange] = useState<[number, number] | undefined>(undefined);
  const [startDate, setStartDate] = useState<any>();
  const [showFilterResult, setShowFilterResult] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [openUserDropdown, setOpenUserDropdown] = useState<string | null>(null);
  const [airportArrivalOption, setAirportArrivalOption] = useState<string[]>([])
  const [airportDepartureOption, setAirportDepartureOption] = useState<string[]>([])
  const [leaguevisePrice,setLeaugevisePrice] = useState<{ min: number; max: number }>({ min: 0, max: 0 });
  const [upcomingPrice,setUpcomingPrice] = useState<{ min: number; max: number }>({ min: 0, max: 0 });
  const filters = useSelector((state: any) => state?.footballEvent?.footballFilters)
  const [searchQuery, setSearchQuery] = useState<string>(filters?.search || "");
  const matchCardDivRef = useRef<HTMLDivElement>(null);
  const [scrollCount ,setScrollCount] = useState<number>(1)
  const isDataRef = useRef<boolean>(false)
  const airportOptions = useSelector((state: any) => state?.userDashboard?.airportOptionList)
  const leagueOptions = useSelector((state: any) => state?.userDashboard?.leagueOptionList)
  const footballDetail = useSelector((state: any)=>state?.footballEvent?.footballDetail )
  const airportDepartureList = useSelector((state: any)=>state?.userDashboard?.airportDepartureList)
  const upcomingEventsList = useSelector((state: any) => state?.footballEvent?.upcomingEventsList)
  const totalLeagues = useSelector((state: any) => state?.footballEvent?.totalLeagues)
  const countRef = useRef<number>(0)


  useEffect(()=>{
    if (numberOfKeysWithValues > 0) {
      dimensions?.width > 414 &&  setFilterModal(true)
      setShowFilterResult(true)
    }
  },[])

  useEffect(() => {
    if (filters?.departureDate) {
      setStartDate(filters?.departureDate)
    }
    if (filters?.totalPrice?.length > 0) {
      setRange(filters?.totalPrice)
    }
  }, [])

  useEffect(()=>{
    if (airportOptions) { 
      const airportArrivalOp = airportOptions?.filter((item:any) => !filters?.airportDeparture?.includes(item?.destinationAirport));
      setAirportArrivalOption(airportArrivalOp)
    }
  },[filters?.airportDeparture,airportOptions])


  useEffect(()=>{
    if (airportDepartureList) { 
      const airportDepartureOp = airportDepartureList?.filter((item:any) => !filters?.airportArrival?.includes(item?.departingAirport) );
      setAirportDepartureOption(airportDepartureOp)
    }
  },[filters?.airportArrival,airportDepartureList])


  const handleNavigate = (id: string) => {
    navigate(`/football/events/details/${id}`)
  }

  const onFilter = () => {
    setShow(!show)
    const newFilterData = {}
    filterModal && dispatch(setFootballFilters(newFilterData))
    filterModal && setScrollCount(1)
    setShowFilterResult(show ? false : true)
    setFilterModal(!filterModal)
    filterModal && setStartDate("")
  }
  const handleShow = () => {
    setShow(true)
  };
  const handleFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      airportArrival: option,
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setClearFilter(false)
  }
  const typeFilter = (option: String) => {
    const newFilterData = {
      ...filters,
      leagueName: option
    };
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setClearFilter(false)
  }
  const locationFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      airportDeparture: option,
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setClearFilter(false)
  }

  const removeDate = () => {
    const newFilterData = {
      ...filters,
      departureDate: ""
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
  }

  const handleSearch = (searchQuery: string) => {
    const newFilters = { ...filters, search: searchQuery }
    dispatch(setFootballFilters(newFilters))
    setScrollCount(1)
    setClearFilter(false)
  }

  const formatDateString = (date: any) => {
    if (date !== undefined && date !== null && typeof date !== 'string') {
      return moment(date).format('YYYY-MM-DD')
    }
  };

  useEffect(() => {
    const formattedStartDate = startDate && formatDateString(startDate);
    if (formattedStartDate) {
      const newFilterData = {
        ...filters,
        departureDate: formattedStartDate
      };
      dispatch(setFootballFilters(newFilterData));
      setScrollCount(1)
      setClearFilter(false)
    }
  }, [startDate])

  const handleRange = (e: [number, number]) => {
    setRange(e)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newFilters = { ...filters, totalPrice: range };
      dispatch(setFootballFilters(newFilters));
      setScrollCount(1)
      setClearFilter(false)
    }, 1000);

    return () => clearTimeout(timeout);
  }, [range]);


  const formatArrayToString = (array: any) => {
    // Check if array is not empty and is an array
    if (array && Array.isArray(array)) {
      return array.join(",");
    }
    else {
      return "";
    }
  };

  const getFootballList = () => {
    setLoading(true);
    const queryParams: listDashboardQueryParams = {
      search: filters?.search?.replace(/vs\./, "vs") || "",
      airportDeparture: formatArrayToString(filters?.airportDeparture),
      airportArrival: formatArrayToString(filters?.airportArrival),
      totalPrice: formatArrayToString(filters?.totalPrice),
      leagueName: formatArrayToString(filters?.leagueName),
      departureDate: filters?.departureDate || "",
      page: scrollCount,
      limit: 20,
    };
    dispatch(paginationFunction({page:scrollCount,limit:20}))
    dispatch(footballDetails(queryParams)).then((resultAction: any) => {
      if (footballDetails.fulfilled.match(resultAction)) {
        if(scrollCount * 20 <= totalLeagues){
          countRef.current =resultAction?.payload?.data?.totalLeagues
          isDataRef.current = true
         }else {
          isDataRef.current = false
         }
        // setFootballEventDetail(resultAction?.payload?.data)
        setLoading(false)
        setSearchResult(!!filters?.search)
      } else if (footballDetails.rejected.match(resultAction)) {
        dispatch(
          showToast({
            message: resultAction?.error?.message || "",
            type: "error",
          })
        );
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    getFootballList()
  }, [JSON.stringify(filters),scrollCount])

  const countNonEmptyValues = (obj: { [key: string]: any }): number => {
    return  obj && Object.values(obj).filter(value =>
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === 'string' && value.trim() !== '') ||
      (typeof value === 'object' && Object.keys(value).length > 0)
    ).length;
  };

  const { search, ...rest } = filters ?? {};
  
  const numberOfKeysWithValues = countNonEmptyValues(rest);

    const refreshClick = () => {
    const queryParams = {
      search: filters?.search || "",
      airportDeparture:"" ,
      airportArrival: "",
      totalPrice: "",
      leagueName: "",
      departureDate:  "",
      page: "",
      limit: "",
    };
    // const newFilterData = {}
    dispatch(setFootballFilters(queryParams))
    setScrollCount(1)
    setStartDate("")
    // setSearchQuery("")
    setClearFilter(true)
    setRange(undefined)
  }

  const handleRemoveArrival = (val: string) => {
    const filterDataRemove = filters?.airportArrival?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      airportArrival: filterDataRemove,
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setOpenUserDropdown(null)
  }

  const handleRemoveLeague = (val: string) => {
    const filterDataRemove = filters?.leagueName?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      leagueName: filterDataRemove
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setOpenUserDropdown(null)
  }

  const handleRemoveDeparture = (val: string) => {
    const filterDataRemove = filters?.airportDeparture?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      airportDeparture: filterDataRemove,
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setOpenUserDropdown(null)
  }

  const handleRemovePrice = () => {
    const newFilterData = {
      ...filters,
      totalPrice: []
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setOpenUserDropdown(null)
    setRange(undefined)
  }

  const handleRemoveDate = () => {
    const newFilterData = {
      ...filters,
      departureDate: "",
    }
    dispatch(setFootballFilters(newFilterData))
    setScrollCount(1)
    setStartDate("")
  }

  const handleClose = () => {
    setShow(!show);
    setFilterModal(!filterModal);
    setShowFilterResult(true)
  }



useEffect(()=>{
  // for leaguewise max price
  if (footballDetail?.length ) {
    const totalPriceArray = footballDetail?.flatMap((league: any) =>
    league?.events?.flatMap((event: any) => event?.flightList?.[0]?.totalPrice)
    );
    const filteredValuesLeague =totalPriceArray && totalPriceArray?.filter((value: any) => value !== null && value !== undefined);
    const maxTotalPriceLeague = filteredValuesLeague &&  Math.max(...filteredValuesLeague);
    const minTotalPriceObject = filteredValuesLeague && Math.min(...filteredValuesLeague)
    setLeaugevisePrice({min:minTotalPriceObject,max:maxTotalPriceLeague})
  }

  if (upcomingEventsList?.length) {
    // upcoming maxPrice
    const maxFind = upcomingEventsList?.map((data:any)=>{
      return data?.flightList?.[0]?.totalPrice
    })
    const filteredValues =maxFind &&  maxFind?.filter((value:any) => value !== null && value !== undefined);
    const maxTotalPriceObject = filteredValues && Math.max(...filteredValues);  
    const minTotalPriceObject = filteredValues && Math.min(...filteredValues)
    setUpcomingPrice({min:minTotalPriceObject,max:maxTotalPriceObject})
  }
},[])

   let maxTotalPriceOverall = Math.max((upcomingPrice?.max) || 0, (leaguevisePrice?.max)|| 0);

  const  handlePriceMekar = ()=>{
    if (upcomingPrice?.min && leaguevisePrice?.min) {
      return Math.min(upcomingPrice?.min ,upcomingPrice?.min) || 0
    }else  if(upcomingPrice?.min){
      return upcomingPrice?.min
    }else if (leaguevisePrice?.min){
      return leaguevisePrice?.min
    }
  }

  /**
 * useEffect hook to handle scroll event and update scroll count.
 */
   useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCount]);

  const handleScroll = () => {
    if (
      (matchCardDivRef.current &&
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight && isDataRef.current && scrollCount * 20 <= (countRef?.current || totalLeagues))
    ) {
      setScrollCount(scrollCount + 1);
    }
  };
  return (
    <>
      <Helmet>
        <title>Football Matches | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Explore upcoming matches across the European leagues & filter your search to catch your favourites." />
      </Helmet>
      <FootballComponent
        onFilter={onFilter}
        filterModal={filterModal}
        matchCardData={matchCardData?.matchCardData}
        handleFilter={handleFilter}
        locationFilter={locationFilter}
        typeFilter={typeFilter}
        handleNavigate={handleNavigate}
        dimensions={dimensions}
        show={show}
        setShow={setShow}
        handleShow={handleShow}
        footballEventDetail={footballDetail}
        loading={loading}
        leagueOption={leagueOptions}
        airportOption={airportArrivalOption}
        removeDate={removeDate}
        handleSearch={handleSearch}
        handleRange={handleRange}
        refresh="refresh"
        user
        numberOfKeysWithValues={numberOfKeysWithValues}
        showFilterResult={showFilterResult}
        searchResult={searchResult}
        filters={filters}
        refreshClick={refreshClick}
        handleRemoveArrival={handleRemoveArrival}
        handleRemoveLeague={handleRemoveLeague}
        handleRemoveDeparture={handleRemoveDeparture}
        handleRemovePrice={handleRemovePrice}
        handleRemoveDate={handleRemoveDate}
        handleClose={handleClose}
        startDate={startDate}
        setStartDate={setStartDate}
        football
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        openUserDropdown={openUserDropdown}
        setOpenUserDropdown={setOpenUserDropdown}
        clearFilter={clearFilter}
        range={range}
        airportDepartureOption={airportDepartureOption}
        maxTotalPriceObject={maxTotalPriceOverall ||  500}
        minTotalPriceObject={handlePriceMekar() || 0}
        dateClass ="date-picker-class"
        matchCardDivRef={matchCardDivRef}
        scrollCount={scrollCount}
        upcomingEventsList={upcomingEventsList}
      />
    </>
  )
}

export default FootballContainer