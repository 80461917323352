import Pages404 from "../pages/404/404";
import SignInContainer from "../pages/auth/signIn/signInContainer";
import ForgotPasswordContainer from "../pages/auth/forgotPassword/forgotPasswordContainer";
import OTPVerificationContainer from "../pages/auth/otpVerification/otpVerificationContainer";
import ResetPasswordContainer from "../pages/auth/resetPassword/resetPasswordContainer";
import DashboardContainer from "../pages/dashboard/dashboardContainer";
import SignUpContainer from "../pages/auth/signUp/signUpContainer";
import UserManagementContainer from "../pages/admin/userManagement/userManagementContainer";
import OnBoardingContainer from "../pages/onBoarding/onBoardingContainer";

//FOR ADMIN
import AdminSignInContainer from "../pages/admin/auth/signIn/signInContainer";
import AdminForgotPasswordContainer from '../pages/admin/auth/forgotPassword/forgotPasswordContainer';
import AdminOTPVerificationContainer from "../pages/admin/auth/otpVerification/otpVerificationContainer";
import AdminDashboardContainer from "../pages/admin/dashboard/dashboardContainer";
import ChangePasswordContainer from "../pages/admin/setting/changePassword/changePasswordContainer";
import FootballContainer from "../pages/football/footballContainer";
import EventsContainer from "../pages/events/eventsContainer";
import FileUploadManagementContainer from "../pages/admin/fileUploadManagement/fileUploadManagementContainer";
import EventsDetailsContainer from "../pages/events/eventsDetails/eventsDetailsContainer";
import SettingContainer from "../pages/settings/settingContainer";
import EventsLinksContainer from "../pages/eventLinks/eventsLinksContainer";
import ProfileContainer from "../pages/onBoarding/profile/profileContainer";

const authProtectedRoutes = [
  { path: "/dashboard", element: <DashboardContainer /> },
  {path:"/dashboard/events/details/:id", element:<EventsLinksContainer/>},
  { path: "/football", element: <FootballContainer /> },
  {path:"/football/events/details/:id", element:<EventsLinksContainer/>},
  { path: "/events", element: <EventsContainer /> },
  {path:"/events/details", element:<EventsDetailsContainer/>},
  {path:"/events/details/:id", element:<EventsLinksContainer/>},
  {path:"/settings",element:<SettingContainer/>},
];

const publicRoutes = [
  { path: "/", element: <SignInContainer /> },
  { path: "/login", element: <SignInContainer /> },
  { path: "/signup", element: <SignUpContainer /> },
  { path: "/forgotpassword", element: <ForgotPasswordContainer /> },
  { path: "/otp", element: <OTPVerificationContainer /> },
  { path: "/resetpassword/:token", element: <ResetPasswordContainer /> },
  { path: "/onboarding", element: <OnBoardingContainer /> },
  { path: "/onboarding/:id", element: <OnBoardingContainer /> },
  {path:"/profile/",element:<ProfileContainer/>},
  {path:"/profile/:id",element:<ProfileContainer/>},
  {path:"/setPassword",element:<ProfileContainer/>},
  { path: "/*", element: <Pages404 /> },
];

const adminAuthProtectedRoutes = [
  { path: "admin/dashboard", element: <AdminDashboardContainer /> },
  {path:"admin/dashboard/events/details/:id", element:<EventsLinksContainer/>},
  { path: "admin/users", element: <UserManagementContainer /> },
  { path: "admin/upload", element: <FileUploadManagementContainer /> },
  { path: "admin/settings", element: <ChangePasswordContainer /> }
];

const adminPublicRoutes = [
  { path: "admin/", element: <AdminSignInContainer /> },
  { path: "admin/login", element: <AdminSignInContainer /> },
  { path: "admin/forgotpassword", element: <AdminForgotPasswordContainer /> },
  { path: "admin/otp", element: <AdminOTPVerificationContainer /> },
];


export { authProtectedRoutes, publicRoutes, adminAuthProtectedRoutes, adminPublicRoutes };