import React from "react";
import { useTranslation } from "react-i18next";
import CardHeader from "../../../components/cardHeader/cardHeader";


const HowItWorksComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="SubTitleContainer">
        <h2>{t("HOW_IT_WORKS")}</h2>
      </div>
      <div className="howItWorks">
        <div className="subContainer">

          <div className="stepMain">
            <div className="step1Container">
              <div className="subBackContainer"><h6 className="header">{t("NEWS")}</h6>
                <CardHeader trip tripStart={t("MAN")} tripEnd={t("MAD")} className="primaryBack" />
                <CardHeader trip tripStart={t("LDN")} tripEnd={t("MIL")} className="secondaryBack" />
              </div>
            </div>
            <div className="stepSubTitle">
              1. {t("JOIN")}
              <p>{t("SIGN_UP_TO")}</p>
            </div>
          </div>

          <span className="icon-arrow"></span>
          <div className="stepMain">
            <div className="step2Container">
              <div className="stepSubContainer">
                <CardHeader title={t("BAYERN")} className="primaryBack" />
                <CardHeader title={t("DORTMUND")} className="secondaryBack" />
                <CardHeader title={t("MADRID")} className="whiteHeaderBack" />
              </div>
              <div className="stepSubTitle">
                2. {t("EXPLORE")}
                <p>{t("RECIEVE_THE_CHEAPEST")}</p>
              </div>
            </div>
          </div>
          <span className="icon-arrow"></span>
          <div className="stepMain">
            <div className="step3Container">
              <div>
                <img src="https://triptixclient.blob.core.windows.net/triptix-image/laligaLogo_rxc0vr.svg" alt="laligaLogo" />

                <div className="secondaryTitle">
                  {t("MAN")}
                  <span className="icon-aeroplane"></span>
                </div></div>
              <div className="stepSubTitle">
                3. {t("SELECT")}
                <p> {t("FILTER_BY_AIRPORT")}</p>
              </div>
            </div>
          </div>
          <span className="icon-arrow"></span>

          <div>
            <div className="stepMain">
              <div className="step4Container">
                <div>
                  <img
                    src="https://triptixclient.blob.core.windows.net/triptix-image/groundImage_ggyuwx.svg"
                    alt="groundImage"
                    className="subBackContainer"
                  />
                  <CardHeader title={t("BERNABEU")} className="secondaryBack" />
                  <CardHeader title={t("SAN_SIRO")} className="primaryBack" />
                </div>
              </div>
              <div className="stepSubTitle">
               4. {t("ATTEND")}
                <p>{t("ATTEND_THE_BIGGEST")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HowItWorksComponent;
