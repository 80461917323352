import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import calenderDateEventService from "./calenderDateEventService";

export const calenderDateEventList = createAsyncThunk("/calenderDateEvent",async(date:any)=>{
  const res = await calenderDateEventService.calenderDateEvent(date);
  return res
})

interface calendarDateEventState {
  calenderDateEventDetail:any
}

const calenderDateEventSlice = createSlice({
  name: "calenderDateEvent",
  initialState: {
    calenderDateEventDetail:null
  } as calendarDateEventState,
  reducers: {},
  extraReducers: (builder) => {
    builder
   .addCase(calenderDateEventList.pending, (state) => { })
   .addCase(calenderDateEventList.fulfilled, (state, action) => {
      state.calenderDateEventDetail = action.payload
    })
    .addCase(calenderDateEventList.rejected, (state, action) => {
      state.calenderDateEventDetail = action.error;
    });
  }
})

export default calenderDateEventSlice.reducer;