import React from "react";
import CustomButton from "../../customButton/customButton";


interface SubscriptionDetails {
  status: boolean;
  content: string;
}

interface PurchaseCardData {
  _id: number;
  subscriptionType: string;
  subscriptionPlan?: string;
  amount: string;
  description: string;
  subscriptionDetails: SubscriptionDetails[];
  plan: string;
}

interface PurchaseCardProps {
  purchaseCardData: PurchaseCardData;
  setPlan?: any;
  plan?: string;
}
const PurchaseCard: React.FC<PurchaseCardProps> = ({ purchaseCardData, setPlan, plan }) => {
  const planData: string = plan === "etclite" ? "FREE" : plan === "etcmonthly" ? "MONTHLY" : plan === "etcannual" ? "ANNUALLY" : "";

  const handleButtonClick = (plan: string) => {
    if (plan) {
      setPlan(plan);
    }

    let url = "";
    if (purchaseCardData.subscriptionType === "MONTHLY") {
      // url = "https://pay.triptix.co/b/test_6oE9AC6Ek5bicMM144"; // staging payments
      url = "https://pay.triptix.co/b/14k00C1K26Nuao8fZ4" // live payments
    } else if (purchaseCardData.subscriptionType === "ANNUALLY") {
      // url = "https://pay.triptix.co/b/test_aEUbIK1k00V26oo3cd"; // staging payments
      url = "https://pay.triptix.co/b/6oE00CbkC2xefIs3ch" // live payments

    }
    if (url) {
      window.open(url, "_self");
    }
  };
  return (
    <div className="purchaseCardContainer">

      <div className={planData === purchaseCardData?.subscriptionType ? "cardBack selecetCardBack" : "cardBack"}>
        <div className={planData === purchaseCardData?.subscriptionType ? "sideContainer sideSelectedBack" : "sideContainer"}>
          {purchaseCardData.subscriptionType}
        </div>
        <div className='sideMain'>
          <span className={planData === purchaseCardData?.subscriptionType ? "sideSubContainer selectedSideMain" : "sideSubContainer"}>{purchaseCardData.amount}</span>
          {purchaseCardData.subscriptionPlan}
        </div>
        <p className="purchaseDiv">{purchaseCardData.description}</p>
        <hr></hr>
        <div className="cardDescription">
          {purchaseCardData.subscriptionDetails.map((detail, index) => (
            <p key={index} className="cardList">
              {detail.status ? (
                <span className="icon-check-circle"></span>
              ) : (
                <span className="icon-x-circle"></span>
              )}{" "}
              {detail.content}
            </p>
          ))}
          <CustomButton title={purchaseCardData.plan} onClick={() => handleButtonClick(purchaseCardData?.subscriptionType)} />
        </div>
      </div>
    </div>
  );
};

export default PurchaseCard;
