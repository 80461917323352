import Constant from "../utils/constant";
import { BlobServiceClient } from '@azure/storage-blob';
import axios from "../helpers/axios";
/**
 * Uploads a file to a cloud storage service using FormData.
 * @param {any} file - The file to be uploaded.
 * @returns {Promise<string>} A Promise that resolves to the URL of the uploaded file.
 */

//this is uploadFile function now
// export const uploadFile = async (file: any) => {
//   const formdata = new FormData();
//   formdata.append('file', file);
//   formdata.append('cloud_name', process.env.REACT_APP_CLOUDNARY_NAME ?? '');
//   formdata.append('upload_preset', process.env.REACT_APP_CLOUDNARY_PRESET_NAME ?? '');
  

//   const res = await fetch('https://api.cloudinary.com/v1_1/dg1dhynys/upload', {
//       method: 'post',
//       mode: 'cors',
//       body: formdata
//   });

//   const json = await res.json();
//   return `${json?.secure_url}`;
//   // return `${json?.secure_url?.split('triptix/')[1]}`;
// };


export const uploadFile = async (file: any) => {
  // Azure storage account configuration
  const accountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
  const containerName = process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME;
  // const sasToken = "sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-09-20T19:43:40Z&st=2024-09-20T11:43:40Z&spr=https,http&sig=pkl6udTUBlOw%2B2ubAEqQf9miD0MUNkddPAAmN7mSWTI%3D"; // Generated SAS token from Azure
  
  if (!accountName || !containerName) {
    throw new Error("Azure Storage configuration is missing");
  }

   // Generate a unique blob name for the file
   const blobName = `${new Date().getTime()}-${file.name}`;

   // Request SAS token from the backend
   const response :any= await axios.get(`/auth/generateSas?blobName=${blobName}`);
   const { sasToken } = response;
 
   if (!sasToken) {
     throw new Error("Failed to get SAS token from the backend");
   }
  // Create the BlobServiceClient
  const blobServiceClient = new BlobServiceClient(
    `https://${accountName}.blob.core.windows.net?${sasToken}`
  );

  // Get the container client
  const containerClient = blobServiceClient.getContainerClient(containerName);

  // Generate a unique blob name for the file

  // Get the block blob client
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  // Upload file
  try {
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file);

    // Return the URL of the uploaded file
    return blockBlobClient.url;
  } catch (error) {
    console.error("Error uploading file to Azure Blob Storage:", error);
    throw new Error("File upload failed");
  }
};
/**
* Constructs a Cloudinary URL using the environment variables REACT_APP_CLOUDINARY_URL,
* REACT_APP_CLOUDINARY_NAME, and REACT_APP_CLOUDINARY_SUBFOLDER.
* @returns {string} The constructed Cloudinary URL.
*/
export  const getCloudinaryUrl =  (process.env.REACT_APP_CLOUDNARY_URL || '') +
(process.env.REACT_APP_CLOUDNARY_NAME || '') +
(process.env.REACT_APP_CLOUDNARY_SUBFLODER || '');



/**
  * Object containing valid thumbnail upload formats and their corresponding constants.
  * @type {Object}
  */
export const uploadThumNailValid = {
  "image/png": Constant.UPLOAD_THUMBNAIL_VALID.PNG,
  "image/jpg": Constant.UPLOAD_THUMBNAIL_VALID.JPG,
  "image/jpeg": Constant.UPLOAD_THUMBNAIL_VALID.JPEG,
  "image/svg+xml": Constant.UPLOAD_THUMBNAIL_VALID.SVG,
  "image/webp": Constant.UPLOAD_THUMBNAIL_VALID.WEBP,
};   