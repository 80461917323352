import React from "react";

interface SearchComponentProps {
  onSearch: (query: string) => void;
  searchVal: string;
  onClear: () => void;
  placeHolder?:string;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ searchVal, onSearch ,onClear,placeHolder}) => {
  return (
    <>
      <div className="searchFilter d-flex">
        <input
          type="text"
          placeholder={placeHolder}
          value={searchVal}
          onChange={(e) => {
            !e?.target?.value && onClear()
             onSearch(e?.target?.value)}}
        />
      {searchVal &&<span className="icon-cross" onClick={(e)=> onClear()}/>}
        </div>
    </>
  );
};

export default SearchComponent;
