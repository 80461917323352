import { CALENDER_DATE_EVENT } from "../../../api/endPoint";
import instance from "../../../helpers/axios";

const calenderDateEvent = (date:any)=>{
  return instance.get(CALENDER_DATE_EVENT,{params:date});
}

const calenderDateEventService ={
  calenderDateEvent
}

export default calenderDateEventService;