import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthHeader from "../../../components/authHeader/authHeader";
import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import CheckBox from "../../../components/checkBox/checkBox";
import googleIcon from '../../../assets/images/Icons/googleIcon.svg';
import facebookIcon from '../../../assets/images/Icons/facebookIcon.svg'
import "../auth.css";
import { IResolveParams, LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../../components/loader/Loader";

interface SignUpProps {
  signUpForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  busy: boolean;
  multiErrorFields?: any;
  showPassword: boolean;
  setTermsCondition: any;
  setShowPassword: any;
  setConfirmShowPassword: any
  confirmShowPassword: boolean
  termsCondition: boolean;
  handleSocialLogin: any;
  assiocate: boolean;
  setAssiocate:any;
  associateLogin:any,
  loginError: any,
  socialLoading:boolean
}

const SignUpComponent: React.FC<SignUpProps> = (props) => {
  const { t } = useTranslation();
  const { signUpForm,
    onFormSubmit,
    multiErrorFields,
    busy,
    showPassword,
    setTermsCondition,
    setShowPassword,
    confirmShowPassword,
    setConfirmShowPassword,
    termsCondition,
    handleSocialLogin,
    assiocate,
    setAssiocate,
    associateLogin,
    loginError,
    socialLoading
  } = props;

  return (
    <React.Fragment>
      <div className="authPages" >
        <div className="authCenter">
          <div className="whiteBack">
            <AuthHeader
              headerTitle={t("CREATE_YOUR_ACCOUNT")}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                      <div className="textField">
                        <TextField
                          icon="icon icon-mail"
                          placeholder={t("EMAIL_ADDRESS")}
                          autoFocus={true}
                          formMethod={formMethod}
                          rules={signUpForm.email.validate}
                          name={signUpForm.email.name}
                          errors={formMethod?.formState.errors}
                          type="text"
                          auth={true}
                          onChange={(value) => {
                            formMethod?.watch("email") &&
                              formMethod.trigger("email");
                            if (formMethod?.watch("confirmemail")) {
                              formMethod?.trigger("confirmemail");
                            }
                          }}
                        />
                        <TextField
                          icon="icon icon-mail"
                          placeholder={t("CONFIRM_EMAIL_ADDRESS")}
                          autoFocus={false}
                          formMethod={formMethod}
                          name={signUpForm.confirmemail.name}
                          errors={formMethod?.formState.errors}
                          type="text"
                          auth={true}
                          rules={{
                            required: {
                              value: true,
                              message: t("CONFIRM_EMAIL_REQUIRED"),
                            },
                            validate: {
                              matchPassword: (value: string) =>
                                (value &&
                                  value === formMethod?.watch("email")) ||
                                t("EMAIL_ADDRESSES_DON’T_MATCH"),
                            },
                          }}
                          onChange={(value) => {
                            formMethod?.watch("confirmemail") &&
                              formMethod.trigger("confirmemail");
                          }}
                        />
                        <TextField
                          placeholder={t("PASSWORD")}
                          autoFocus={false}
                          formMethod={formMethod}
                          rules={signUpForm.password.validate}
                          name={signUpForm.password.name}
                          errors={formMethod?.formState.errors}
                          type="password"
                          showPassword={showPassword}
                          togglePasswordVisibility={() =>
                            setShowPassword((prev: any) => !prev)
                          }
                          multiErrorFields={multiErrorFields}
                          onChange={(value) => {
                            formMethod?.watch("confirmpassword") &&
                              formMethod.trigger("confirmpassword");
                          }}
                          auth={true}
                        />
                        <TextField
                          placeholder={t("CONFIRM_PASSWORD")}
                          autoFocus={false}
                          formMethod={formMethod}
                          rules={{
                            required: {
                              value: true,
                              message: t("CONFIRM_PASSWORD_REQUIRED"),
                            },
                            validate: {
                              matchPassword: (value: string) =>
                                (value &&
                                  value === formMethod?.watch("password")) ||
                                t("PASSWORDS_DON’T_MATCH"),
                            },
                          }}
                          name={signUpForm.confirmpassword.name}
                          errors={formMethod?.formState.errors}
                          type="password"
                          showPassword={confirmShowPassword}
                          togglePasswordVisibility={() =>
                            setConfirmShowPassword((prev: any) => !prev)
                          }
                          onChange={(e) => {
                            formMethod?.watch("password") &&
                              formMethod.trigger("password");
                          }}
                          auth={true}
                        />
                      </div>
                      <CheckBox
                        checkboxClass="form-check-input"
                        id="termsCondition"
                        labelTitle={<span>
                          {t("I_AGREE_TO_ALL")}
                          <span className="termsLink">
                            <Link to="https://triptix.co/terms-conditions/" target="blank"> {t("TERMS_AND_CONDITION")}</Link>
                          </span>
                        </span>}
                        checked={termsCondition}
                        onCheckedChange={(checked) => setTermsCondition(checked)}
                      />
                      <CustomButton
                        className="btnAuth"
                        title={t("SIGN_UP")}
                        disabled={!formMethod?.formState.isValid || !termsCondition}
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm>
              <div className="authOptions">
                {t("ALREADY_GOT_AN_ACCOUNT")}
                <Link to="/login" className="authStyle">
                  {t("SIGN_IN")}
                </Link>
              </div>
            </div>
            <div className="loginOptMain">
              <hr></hr><p className="loginOpt">{t("LOGIN_WITH")}</p>
              <hr></hr>
            </div>
            <div className="loginIcons">
              {process.env.REACT_APP_FACEBOOK_APP_ID && <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
                onResolve={({ provider, data }: IResolveParams) => {
                  handleSocialLogin(provider, data)
                }}
                onReject={(err) => {
                  console.log("facebook login error: ", err)
                }}
              >
                <img
                  src={facebookIcon}
                  alt='Facebook Icon'
                  className='socialIcon'
                />
              </LoginSocialFacebook>}

              {process.env.REACT_APP_GOOGLE_APP_ID && <LoginSocialGoogle
                // isOnlyGetToken
                client_id={process.env.REACT_APP_GOOGLE_APP_ID || ''}
                scope="openid profile email"
                onResolve={({ provider, data }: IResolveParams) => {
                  handleSocialLogin(provider, data)
                }}
                onReject={(err) => {
                  console.log("google login error: ", err)
                }}
              >
                <img
                  src={googleIcon}
                  alt='Google Icon'
                  className='socialIcon'
                />
              </LoginSocialGoogle>}
            </div>
          </div>
        </div>
      </div>

      {socialLoading && <div className="fade offcanvas-backdrop show">
        <Loader />
      </div>}



      {assiocate && <Modal className="signup-modal" show={true} onHide={()=>setAssiocate(false)}>
        <Modal.Header >
          <Modal.Title>Associate User</Modal.Title>
        </Modal.Header>
        <Modal.Body>{loginError}</Modal.Body>
        <Modal.Footer>
          <Button className="active-yes" onClick={()=>associateLogin()}> Yes </Button>
          <Button className="deactive-no" onClick={()=> setAssiocate(false)}> No </Button>
        </Modal.Footer>
      </Modal>}
    </React.Fragment>
  );
};
export default SignUpComponent;







