import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ResetPasswordComponent from "./resetPasswordComponent";
import { showToast } from "../../../store/features/toast/toastSlice";
import Constant from "../../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../interface/authInterface";
import { resetPassword } from "../../../store/features/auth/authSlice";

const ResetPasswordContainer = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const navigate = useNavigate();
	const { token } = useParams()
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [confirmShowPassword, setConfirmShowPassword] = useState<boolean>(false);
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
	const [loading, setLoading] = useState<boolean>(true);
  const authToken =  localStorage.getItem('accessToken')

  useEffect(() => {
		if (token && authResponse?.data?.role === "admin") {
				navigate("/admin/dashboard")
		}else if(token && authResponse?.data?.role === "user"){
			navigate("/dashboard")
		}else {
			setLoading(false)
		}
	}, [authResponse])


	useEffect(()=>{
			if (!token && authToken ) {
				navigate("/login")
				localStorage.clear();
			}
	},[token])

	const onFormSubmit = async (value: any) => {
		setBusy(true);

		const params = {
			resetToken: token || null,
			password: value?.password,
		};
		dispatch(resetPassword(params)).then((resultAction:any) => {
			if (resetPassword.fulfilled.match(resultAction)) {
				setBusy(false);
				dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "success",
					})
				);
				if (resultAction?.payload?.data?.role === "admin") {
					navigate("/admin/login");
				} else {
					navigate("/login");
				}
			} else if (resetPassword.rejected.match(resultAction)) {
				setBusy(false);
				dispatch(
					showToast({
						message: resultAction?.error?.message,
						type: "error",
					})
				);
			}
		});
	};

	const resetForm = {
		password: {
			name: "password",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_ENTER_PASSWORD"),
				},
				validate: {
					hasLowerCase_UpperCase: (value: string) =>
						value &&
						value.match(Constant.REGEX.LOWERCASE) &&
						value.match(Constant.REGEX.UPPERCASE) !== null,
					hasNumbers: (value: string) =>
						value && value.match(Constant.REGEX.NUMBER) !== null,
					hasSpecialChar: (value: string) =>
						value &&
						value.match(Constant.REGEX.SPECIAL_CHARECTERS) !== null,
					length: (value: string) =>
						(value && value.length >= 8) ||
						"",
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
		confirmpassword: {
			name: "confirmpassword",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
	};

	const multiErrorFields = [
		{ length: t("MINIMUM_8_CHARACTERS") },
		{ hasSpecialChar: t("MINIMUM_1_SPECIAL") },
		{ hasNumbers: t("MINIMUM_1_NUMERIC") },
		{ hasLowerCase_UpperCase: t("MINIMUM_1_UPPERCASE_LOWERCASE") },
	];

if (loading) return null

	return (
		<>
			<Helmet>
				<title>Reset Password | Triptix</title>
				<meta name="description" content="Reset Password | Triptix" />
			</Helmet>
			<ResetPasswordComponent
				resetForm={resetForm}
				multiErrorFields={multiErrorFields}
				onFormSubmit={onFormSubmit}
				busy={busy}
				setShowPassword={setShowPassword}
				showPassword={showPassword}
				setConfirmShowPassword={setConfirmShowPassword}
				confirmShowPassword={confirmShowPassword} />
		</>
	);
};

export default ResetPasswordContainer;