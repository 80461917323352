import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import eventDetailsService from "./eventDetailService";


export const eventDetailsList = createAsyncThunk("/eventDetails/:id",async(id:string)=>{
  const res = await eventDetailsService.eventDetails(id);
  return res
})

interface eventDetailsState {
  eventDetailsList:any
}

const eventDetailsSlice = createSlice({
  name: "eventDetails",
  initialState: {
    eventDetailsList:[],
  } as eventDetailsState,
  reducers:{

  },
  extraReducers:(builder)=>{
      builder
      .addCase(eventDetailsList.pending, (state) => { })
      .addCase(eventDetailsList.fulfilled, (state, action) => {
        state.eventDetailsList = action.payload;
      })
      .addCase(eventDetailsList.rejected, (state, action) => {
        state.eventDetailsList = action.error;
      });
  }
})

export default eventDetailsSlice.reducer;