const Constant = {
    REGEX: {
        EMAIL: /(^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2}|com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)$)/,
        UPPERCASE: /[A-Z]/g,
        LOWERCASE: /[a-z]/g,
        NUMBER: /[0-9]/,
        NUMERIC: /\d/g,
        NAME: /^[a-zA-Z'-]+$/,
        SPECIAL_CHARECTERS: /[!@#$%^&*(),.?":{}|<>]/,
    },
    LOCALSTORAGEKEYS: {
        ACCESS_TOKEN: 'accessToken',
        RESET_PASS: 'resetPass',
        REFRESH_TOKEN: 'refreshToken',
        USER_ID: 'userId',
        TIMESTAMP:"timestamp"
    },
    SESSIONSTORAGEKEYS: {
        REMEMBER_ME: 'triptix_rememberMe',
        TRIPTIX_REMEMBER_ME_EMAIL: 'triptix_rememberMe_email',
        TRIPTIX_REMEMBER_ME_PASSWORD: 'triptix_rememberMe_password',
        OTP: 'otp',
        RESEND_OTP: 'resendOtp',
        RESEND_EMAIL: 'resendEmail',
        EXPIRE_TIME: 'expireTime',
    },
    UPLOAD_THUMBNAIL_VALID: {
        PNG: ".png",
        JPG: ".jpg",
        JPEG: ".jpeg",
        SVG: ".svg",
        WEBP: ".webp",
    },
};

export  const leagues = [
    { label: 'Allsvenskan', value: 'Allsvenskan' },
    { label: 'Austrian Bundesliga', value: 'Austrian Bundesliga' },
    { label: 'Belgian Pro League', value: 'Belgian Pro League' },
    { label: 'Bundesliga', value: 'Bundesliga' },
    { label: 'Czech First League', value: 'Czech First League' },
    { label: 'Danish Superliga', value: 'Danish Superliga' },
    { label: 'Ekstraklasa', value: 'Ekstraklasa' },
    { label: 'Eliteserien', value: 'Eliteserien' },
    { label: 'Eredivisie', value: 'Eredivisie' },
    { label: 'International Friendly', value: 'International Friendly' },
    { label: 'Ireland Premier Division', value: 'Ireland Premier Division' },
    { label: 'La Liga', value: 'La Liga' },
    { label: 'Ligue 1', value: 'Ligue 1' },
    { label: 'Premier League', value: 'Premier League' },
    { label: 'Primeira Liga', value: 'Primeira Liga' },
    { label: 'Scottish Premiership', value: 'Scottish Premiership' },
    { label: 'Serie A', value: 'Serie A' },
    { label: 'Swiss Super League', value: 'Swiss Super League' },
    { label: 'Veikkausliiga', value: 'Veikkausliiga' }
  ]


export default Constant;
