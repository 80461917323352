import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userDashboardService from "./userDashboardService";
import { listDashboardQueryParams } from '../../../interface/userInterface';

export const userDashboardDetails = createAsyncThunk("/user/listUser", async (query: listDashboardQueryParams) => {
  const res = await userDashboardService.dashBoardDetails(query);
  return res;
});

export const getUserLeagueList = createAsyncThunk("/event/leagueList",async()=>{
  const res = await userDashboardService.getLeagueList();
  return res
})

export const getUserAirportList = createAsyncThunk("/event/airportList",async()=>{
  const res = await userDashboardService.getAirportList();
  return res
})

export const getUserAirportDepartureList = createAsyncThunk("/event/departingAirportList",async()=>{
  const res = await userDashboardService.getAirportDeparture()
  return res
})




export interface userDashboardState {
	userFilters: any;
  userDashboardList:any;
  search:boolean;
  airportOptionList:any
  leagueOptionList:any,
  airportDepartureList:any,
  upgradePlan:any,
  pagination:any,
  featuredEventsList:any,
  totalNonFeaturedEvents:any
}
const userDashboardSlice = createSlice({
  name: "userDashboard",
  initialState: {
    userDashboardList: [],
    userFilters: {},
    airportOptionList: [],
    leagueOptionList:[],
    airportDepartureList: [],
    upgradePlan:{},
    pagination:{},
    featuredEventsList :[],
    totalNonFeaturedEvents:"",
  }as userDashboardState,
  reducers: {
    setUserDashboardFilters: (state, action) => {
			state.userFilters = action.payload
		},
    paginationFunction : (state,action)=>{
      state.pagination = action?.payload
    }
  },
  extraReducers:(builder)=> {
    builder
    .addCase(userDashboardDetails.pending, (state) => { })
    .addCase(userDashboardDetails.fulfilled, (state, action) => {
      const {featuredEvents,nonFeaturedEvents,totalNonFeaturedEvents} = action.payload?.data
      if (state?.pagination?.page === 1) {
        state.featuredEventsList = featuredEvents
        state.userDashboardList = nonFeaturedEvents;
        state.totalNonFeaturedEvents = totalNonFeaturedEvents;
      } else {
        state.userDashboardList = [...state.userDashboardList , ...nonFeaturedEvents]
      }
    })
    .addCase(userDashboardDetails.rejected, (state, action) => {
      state.userDashboardList = action.error;
    });
    builder
    .addCase(getUserAirportList.pending, (state) => { })
    .addCase(getUserAirportList.fulfilled, (state, action) => {
      state.airportOptionList = action?.payload?.data?.sort()
    })
    .addCase(getUserAirportList.rejected, (state, action) => {
      state.airportOptionList = action.error?.message;
    });
    builder
    .addCase(getUserLeagueList.pending, (state) => { })
    .addCase(getUserLeagueList.fulfilled, (state, action) => {
      state.leagueOptionList = action?.payload?.data?.sort()
    })
    .addCase(getUserLeagueList.rejected, (state, action) => {
      state.leagueOptionList = action.error?.message;
    });
    builder
    .addCase(getUserAirportDepartureList.pending, (state) => { })
    .addCase(getUserAirportDepartureList.fulfilled, (state, action) => {
      state.airportDepartureList = action?.payload?.data?.sort()
    })
    .addCase(getUserAirportDepartureList.rejected, (state, action) => {
      state.airportDepartureList = action.error?.message;
    });
 
  },
});

export const {setUserDashboardFilters,paginationFunction} = userDashboardSlice.actions
export default userDashboardSlice.reducer;