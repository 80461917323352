import moment from 'moment';
import React from 'react'

interface selectOptionProps {
  name?:any;
  handleRemoveLeague?:any
  handleRemoveArrival?:any;
  handleRemovePrice?:any;
  handleRemoveDate?:any;
  handleRemoveDeparture?:any
}

const SelectOptionComponent:React.FC<selectOptionProps> = (props) => {
  const {handleRemoveLeague,name,handleRemoveArrival,handleRemovePrice,handleRemoveDate,handleRemoveDeparture} = props
  return (
    <>
      <div id='filter-d-flex'>
            {name?.airportArrival && name?.airportArrival?.map((item: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  <button className="show-select-option">{item} <span className="icon-cross"  onClick={() => handleRemoveArrival(item)}></span></button>
                </React.Fragment>
              );
            })}
            {name?.airportDeparture && name?.airportDeparture?.map((item: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  <button className="show-select-option">{item} <span className="icon-cross" onClick={() => handleRemoveDeparture(item)}></span></button>
                </React.Fragment>
              );
            })}
            {name?.leagueName && name?.leagueName?.map((item: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  <button className="show-select-option">{item} <span className="icon-cross" onClick={() => handleRemoveLeague(item)}></span></button>
                </React.Fragment>
              );
            })}
            {name?.totalPrice?.length > 0 &&
              <React.Fragment>
                <button className="show-select-option" >£{name?.totalPrice[0]} - £{name?.totalPrice[1]} <span className="icon-cross" onClick={() => handleRemovePrice()}></span></button>
              </React.Fragment>
            }
            {name?.departureDate &&
              <React.Fragment>
                <button className="show-select-option" >{moment(name?.departureDate)?.format("DD-MM-YYYY")} <span className="icon-cross" onClick={() => handleRemoveDate()}></span></button>
              </React.Fragment>
            }
             {name?.month &&
              <React.Fragment>
                <button className="show-select-option" >{moment(name?.month,'YYYY/MM')?.format('MMM-YYYY')} <span className="icon-cross" onClick={() => handleRemoveDate()}></span></button>
              </React.Fragment>
            }
          </div>
    </>
  )
}

export default SelectOptionComponent