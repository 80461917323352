import React from 'react'

interface LoaderProps {
  pagination?: boolean;
  paginationUser?:boolean
}

const Loader : React.FC<LoaderProps> = ({pagination,paginationUser}) => {
  return (
    <>
      <div className={pagination ? "pagination-spinner" : paginationUser ? "pagination-user":"loading-spinner"}>
        <div className="loading"></div>
      </div>
    </>
  )
}

export default Loader