import React, { useEffect, useRef, useState } from 'react'
import EventsComponent from './eventsComponent'
import { Helmet } from 'react-helmet'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, listDashboardQueryParams } from '../../interface/userInterface';
import useWindowDimension from '../../components/hooks/useWindowDimension';
import { calenderEventDetail, setEventFilters } from '../../store/features/calenderEvent/calenderEventSlice';
import { useNavigate } from 'react-router';
import { showToast } from '../../store/features/toast/toastSlice';

const EventsContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const dimensions = useWindowDimension();
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [range, setRange] = useState<any>()
  const [filterModal, setFilterModal] = useState(false);
  const [showFilterResult, setShowFilterResult] = useState(false);
  const [openUserDropdown, setOpenUserDropdown] = useState<string | null>(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [calenderEventList, setCalenderEventList] = useState<any>("")
  const [loading, setLoading] = useState(true)
  const [airportArrivalOption, setAirportArrivalOption] = useState<string[]>([])
  const [airportDepartureOption, setAirportDepartureOption] = useState<string[]>([])
  const filters = useSelector((state: any) => state?.calenderEvent?.eventFilters)
  const airportOptions = useSelector((state: any) => state?.userDashboard?.airportOptionList)
  const airportDepartureList = useSelector((state: any) => state?.userDashboard?.airportDepartureList)
  const calenderEventDetailList = useSelector((state: any) => state?.calenderEvent?.calenderEventDetail)
  const firstEventMaxPrice = useSelector((state: any) => state?.calenderEvent?.firstEventMaxPrice)
  const maxPriceRef = useRef<number>(0)

  /**
   * Get the days of the current month along with their corresponding day of the week and date.
   * @returns An object containing the current month and an array of objects representing each day of the month.
   */
  function getCurrentMonthDays() {
    let currentDate;
    if (filters?.month) {
      currentDate = moment(filters?.month, 'YYYY-MM');
    } else {
      currentDate = moment();
    }
    const currentMonth = currentDate?.format('MMMM');
    const daysInMonth = currentDate?.daysInMonth();
    const days = [];

    const eventList = calenderEventList?.eventList || []; // Ensure eventList exists and is an array

    for (let day = 1; day <= daysInMonth; day++) {
      const date = currentDate.date(day);
      const eventData = eventList.find((event: any) => event?.dayOfMonth === day); // Find event data for the current day
      if (eventData) {
        days.push({
          dayOfMonth: day,
          dayOfWeek: date.format('dddd'),
          date: date.format('YYYY-MM-DD'),
          eventNumber: eventData?.events
        });
      } else {
        days.push({
          dayOfMonth: day,
          dayOfWeek: date.format('dddd'),
          date: date.format('YYYY-MM-DD'),
          eventNumber: 0
        });
      }
    }
    return { month: currentMonth, days };
  }
  // Example usage:
  const { month, days } = getCurrentMonthDays();


  useEffect(() => {
    if (numberOfKeysWithValues > 0) {
      dimensions?.width > 414 && setFilterModal(true)
      setShowFilterResult(true)
    }
  }, [])

  useEffect(() => {
    if (filters?.month) {
      setStartDate(filters?.month)
    }
    if (filters?.totalPrice?.length > 0) {
      setRange(filters?.totalPrice)
    }
  }, [])


  useEffect(() => {
    if (airportOptions) {
      const airportArrivalOp = airportOptions?.filter((item: any) => !filters?.airportDeparture?.includes(item?.destinationAirport));
      setAirportArrivalOption(airportArrivalOp)
    }
  }, [filters?.airportDeparture, airportOptions])


  useEffect(() => {
    if (airportDepartureList) {
      const airportDepartureOp = airportDepartureList?.filter((item: any) => !filters?.airportArrival?.includes(item?.departingAirport));
      setAirportDepartureOption(airportDepartureOp)
    }
  }, [filters?.airportArrival, airportDepartureList])

  const handleRange = (e: any) => {
    setRange(e)
  }



  const onFilter = () => {
    setShow(!show)
    const newFilterData = {}
    filterModal && dispatch(setEventFilters(newFilterData))
    setShowFilterResult(show ? false : true)
    setFilterModal(!filterModal)
    filterModal && setStartDate("")
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newFilters = { ...filters, totalPrice: range };
      dispatch(setEventFilters(newFilters));
      setClearFilter(false)
    }, 1000);

    return () => clearTimeout(timeout);
  }, [range]);

  const handleShow = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(!show);
    setFilterModal(!filterModal);
    setShowFilterResult(true)
  }

  const handleFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      airportArrival: option,
    }
    dispatch(setEventFilters(newFilterData))
    setClearFilter(false)
  }

  const locationFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      airportDeparture: option,
    }
    dispatch(setEventFilters(newFilterData))
    setClearFilter(false)
  }

  const removeDate = () => {
    const newFilterData = {
      ...filters,
      month: ""
    }
    dispatch(setEventFilters(newFilterData))
  }

  /**
 * Counts the number of non-empty values in an object.
 * @param obj - The object to count non-empty values for.
 * @returns The count of non-empty values.
 */
  const countNonEmptyValues = (obj: { [key: string]: any }): number => {
    // Check if obj is null or undefined
    if (obj === null || obj === undefined) {
      return 0;
    }

    return Object.values(obj).filter(value =>
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === 'string' && value.trim() !== '') ||
      (typeof value === 'object' && Object.keys(value).length > 0)
    ).length;
  };

  const numberOfKeysWithValues = countNonEmptyValues(filters);

  const refreshClick = () => {
    const newFilterData = {}
    dispatch(setEventFilters(newFilterData))
    setClearFilter(true)
    setStartDate("")
    setRange(undefined)

  }

  const handleRemovePrice = () => {
    const newFilterData = {
      ...filters,
      totalPrice: []
    }
    dispatch(setEventFilters(newFilterData))
    setOpenUserDropdown(null)
    setRange(undefined)
  }
  const handleRemoveDate = () => {
    const newFilterData = {
      ...filters,
      month: "",
    }
    dispatch(setEventFilters(newFilterData))
    setStartDate("")
  }
  const handleRemoveArrival = (val: string) => {
    const filterDataRemove = filters?.airportArrival?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      airportArrival: filterDataRemove,
    }
    dispatch(setEventFilters(newFilterData))
    setOpenUserDropdown(null)
  }


  const handleRemoveDeparture = (val: string) => {
    const filterDataRemove = filters?.airportDeparture?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      airportDeparture: filterDataRemove,
    }
    dispatch(setEventFilters(newFilterData))
    setOpenUserDropdown(null)
  }


  const formatArrayToString = (array: any) => {
    // Check if array is not empty and is an array
    if (array && Array.isArray(array)) {
      return array.join(",");
    }
    else {
      return "";
    }
  };


  const formatDateString = (date: any) => {
    if (date !== undefined && date !== null && typeof date !== 'string') {
      return moment(date).format('YYYY-MM')
    }
  };
  useEffect(() => {
    const formattedStartDate = startDate && formatDateString(startDate);
    if (formattedStartDate) {
      const newFilterData = {
        ...filters,
        month: formattedStartDate
      };
      dispatch(setEventFilters(newFilterData));
      setClearFilter(false)
    }
  }, [startDate])


  const getEventList = () => {
    setLoading(true);
    const queryParams: listDashboardQueryParams = {
      airportDeparture: formatArrayToString(filters?.airportDeparture),
      airportArrival: formatArrayToString(filters?.airportArrival),
      totalPrice: formatArrayToString(filters?.totalPrice),
      month: filters?.month || ""
    };
    dispatch(calenderEventDetail(queryParams)).then((resultAction: any) => {
      if (calenderEventDetail.fulfilled.match(resultAction)) {
        setCalenderEventList(resultAction?.payload?.data)
        setLoading(false)
      } else if (calenderEventDetail.rejected.match(resultAction)) {
        dispatch(
          showToast({
            message: resultAction?.error?.message || "",
            type: "error",
          })
        );
      }
      setLoading(false);
    });
  }
  useEffect(() => {
    getEventList()
  }, [JSON.stringify(filters)])

  const handleRedirect = (data: any,filter:any) => {
    navigate("/events/details", { state: { date: data?.date ,filter} })
  }

  // max price set logic here 
  useEffect(() => {
    if (numberOfKeysWithValues) {
      if (((firstEventMaxPrice || maxPriceRef.current) < calenderEventDetailList?.data?.maxTotalPrice) || firstEventMaxPrice === undefined ) {
        maxPriceRef.current = calenderEventDetailList?.data?.maxTotalPrice || 500
      } else {
        maxPriceRef.current = Math.max(firstEventMaxPrice, maxPriceRef.current) || 500
      }
    } else {
      maxPriceRef.current = calenderEventDetailList?.data?.maxTotalPrice || 500
    }
  }, [calenderEventDetailList])



  return (
    <>
      <Helmet>
        <title>Events Calendar | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="View all upcoming matches on the events calendar and find the best ticket and travel prices." />
      </Helmet>
      <EventsComponent
        month={month}
        days={days}
        openUserDropdown={openUserDropdown}
        setOpenUserDropdown={setOpenUserDropdown}
        show={show}
        setShow={setShow}
        onFilter={onFilter}
        filterModal={filterModal}
        event
        user
        airportOption={airportArrivalOption}
        handleRange={handleRange}
        handleShow={handleShow}
        handleClose={handleClose}
        dimensions={dimensions}
        handleFilter={handleFilter}
        locationFilter={locationFilter}
        numberOfKeysWithValues={numberOfKeysWithValues}
        refreshClick={refreshClick}
        refresh="refresh"
        showFilterResult={showFilterResult}
        handleRemoveDate={handleRemoveDate}
        handleRemovePrice={handleRemovePrice}
        handleRemoveArrival={handleRemoveArrival}
        handleRemoveDeparture={handleRemoveDeparture}
        clearFilter={clearFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        handleRedirect={handleRedirect}
        loading={loading}
        calenderEventList={calenderEventList}
        removeDate={removeDate}
        airportDepartureOption={airportDepartureOption}
        range={range}
        maxTotalPriceObject={maxPriceRef.current || 500}
        minTotalPriceObject={0}
        eventFilters={filters}
      />
    </>
  )
}

export default EventsContainer