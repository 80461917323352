import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import calenderEventDetailsService from "./calenderEventService";
import { listDashboardQueryParams } from "../../../interface/userInterface";
 
export const calenderEventDetail = createAsyncThunk("/event/currentMonthEvents",(query:listDashboardQueryParams)=>{
  const res = calenderEventDetailsService.calenderEvent(query);
  return res
})



interface calenderEventDetailsState{
  calenderEventDetail:any,
  eventFilters:any,
  firstEventMaxPrice:number,
  isFirstRender:boolean,
}

const calenderEventDetailsSlice = createSlice({
  name: "calenderEventDetails",
  initialState: {
    calenderEventDetail:[],
    eventFilters:{},
    firstEventMaxPrice:0,
    isFirstRender:false
  } as calenderEventDetailsState,
  reducers: {
    setEventFilters: (state, action) => {
			state.eventFilters = action.payload
		},
    resetFirstEventPrice :(state,action)=>{
      state.firstEventMaxPrice = 0
      state.isFirstRender = false
    }
  },
  extraReducers:(builder)=>{
    builder
    .addCase(calenderEventDetail.pending, (state) => { })
    .addCase(calenderEventDetail.fulfilled, (state, action) => {
      state.calenderEventDetail = action.payload;
      if (!state.isFirstRender) {
        state.firstEventMaxPrice = action?.payload?.data?.maxTotalPrice;
        state.isFirstRender = true
      }
    })
    .addCase(calenderEventDetail.rejected, (state, action) => {
      state.calenderEventDetail = action.error;
    });
}
})

export const {setEventFilters,resetFirstEventPrice} = calenderEventDetailsSlice?.actions
export default calenderEventDetailsSlice.reducer;
