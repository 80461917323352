import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowDimension from "../../../../components/hooks/useWindowDimension";
import AuthHeader from "../../../../components/authHeader/authHeader";
import HookForm from "../../../../components/hookForm/hookForm";
import TextField from "../../../../components/textField/textField";
import CustomButton from "../../../../components/customButton/customButton";

interface ForgotPasswordProps {
  forgotForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  busy: boolean;
}

const ForgotPasswordComponent: React.FC<ForgotPasswordProps> = (props) => {
  const { t } = useTranslation();
  const dimensions = useWindowDimension();
  const { forgotForm, onFormSubmit, busy } = props;

  return (
    <React.Fragment>
      <div className="authPages" >
        <div className="authCenter">
          <div className="whiteBack">
            <AuthHeader
              headerTitle={t("FORGOT_PASSWORD").slice(0,-1)}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                    <div className="textField">
                      <TextField
                       icon="icon icon-mail"
                        placeholder={t("EMAIL_ADDRESS")}
                        autoFocus={true}
                        formMethod={formMethod}
                        rules={forgotForm.email.validate}
                        name={forgotForm.email.name}
                        errors={formMethod?.formState.errors}
                        type="text"
                        auth={true}
                      />
                      </div>
                      <CustomButton
                        className="btnAuth"
                        title={t("SUBMIT")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm>
              <div className="forgotPassCenter">
                <Link to="/admin/login" className="forgotPasswordLink">
                  <i className="icon icon-arrow_long_left" />
                  {t("BACK_TO_SIGN_IN")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordComponent;
