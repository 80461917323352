import React, { useEffect } from "react";
import { useForm, SubmitHandler, UseFormReturn } from "react-hook-form";

type HookFormProps = {
  onSubmit: SubmitHandler<any>;
  init?: (method: UseFormReturn<any>) => void;
  defaultForm?: any;
  children: (method: UseFormReturn<any>) => React.ReactNode;
};

function HookForm(props: HookFormProps) {
  const { onSubmit, init } = props;

  const method = useForm({
    mode: "onChange",
    defaultValues: props.defaultForm,
    criteriaMode: "all",
  });

  useEffect(() => {
    init && init(method);
  }, [init, method]);

  return (
    <form onSubmit={method.handleSubmit(onSubmit)}>
      {props.children(method)}
    </form>
  );
}

HookForm.defaultProps = {
  defaultForm: {},
  onSubmit: () => {},
};

export default HookForm;
