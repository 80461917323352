import { CHANGE_USER_STATUS, LIST_USER ,GET_USER_LOCATION} from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import {
	listUserQueryParams,
	changeUserStatusParams,
} from "../../../interface/userInterface";

const listUser = (query: listUserQueryParams) => {
	return instance.get(LIST_USER, { params: query });
};

const changeUserStatus = (data: changeUserStatusParams) => {
	return instance.put(CHANGE_USER_STATUS, data);
};

const getUserLocation = ()=>{
	return instance.get(GET_USER_LOCATION);
}

const userServices = {
	listUser,
	changeUserStatus,
	getUserLocation
};

export default userServices;
