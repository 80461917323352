import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthHeader from "../../../../components/authHeader/authHeader";
import HookForm from "../../../../components/hookForm/hookForm";
import TextField from "../../../../components/textField/textField";
import CustomButton from "../../../../components/customButton/customButton";
import CheckBox from "../../../../components/checkBox/checkBox";


interface SignInProps {
  signInForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  busy: boolean;
  setShowPassword: any;
  showPassword: boolean;
  rememberMeFunction: (value: boolean) => void,
  rememberMe: boolean,
  setRememberMe:any,
  errorMsg?:string
}


const SignInComponent: React.FC<SignInProps> = (props) => {
  const { t } = useTranslation();
  const { signInForm, onFormSubmit, busy, rememberMe,rememberMeFunction, setShowPassword, showPassword ,setRememberMe,errorMsg} = props;
  return (
    <React.Fragment>
      <div className="authPages" >
        <div className="authCenter">
          <div className="whiteBack">
            <AuthHeader
              headerTitle={t("SIGN_INTO_YOUR_ACCOUNT")}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                    <div className="textField">   
                      <TextField
                        icon="icon icon-mail"
                        placeholder={t("EMAIL_ADDRESS")}
                        autoFocus={true}
                        formMethod={formMethod}
                        rules={signInForm.email.validate}
                        name={signInForm.email.name}
                        errors={formMethod?.formState.errors}
                        type="text"
                        auth={true}
                      />
                      <TextField
                        placeholder={t("PASSWORD")}
                        autoFocus={false}
                        formMethod={formMethod}
                        rules={signInForm.password.validate}
                        name={signInForm.password.name}
                        errors={formMethod?.formState.errors || errorMsg}
                        type="password"
                        auth={true}
                        showPassword={showPassword} 
                        togglePasswordVisibility={() =>
                          setShowPassword((prev: any) => !prev)
                        }
                      />
                        {formMethod?.formState.errors && !formMethod?.formState.errors[signInForm.password.name]&&(errorMsg && <div className="errorMsg" id='error-msg'><span className="icon-info"></span> {errorMsg}</div>)}
                      </div>
                      <CheckBox
                        checkboxClass="form-check-input"
                        id="rememberMe"
                        labelTitle={t("REMEMBER_ME")}
                        checked={rememberMe}
                        onCheckedChange={(checked) => rememberMeFunction(checked)}
                      />
                      <CustomButton
                        className="btnAuth"
                        title={t("SIGN_IN")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm>
              <div className="forgotPassCenter">
                <Link to="/admin/forgotpassword" className="forgotPasswordLink">
                  {t("FORGOT_PASSWORD")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SignInComponent;
