import React from "react";
import "./dashboardCard.css";
import { t } from "i18next";
import moment from "moment";
import TextResize from "../../textResize/textResize";

interface MatchCardData {
  _id?: number | string;
  tripStartArea?: string;
  tripEndArea?: string;
  date?: string;
  team1?: string;
  team2?: string;
  amount?: string;
  totalPrice?: string;
  homeTeam?: string;
  otherTeam?: string;
  departingAirport?: string;
  destinationAirport?: string;
  fixtureDate?: string;
  flightList: {
    destinationAirport: string;
    departingAirport: string;
    departureDate: string;
    flightPrice: number;
    returnDate: string;
    totalPrice: number;
    _id: string;
  }[];
  ticketPrice: number;
}

interface MatchCardDataProps {
  matchCardData: MatchCardData;
  travelCard?: boolean;
  handleNavigate?: any;
  classStyle?: string;
}

const MatchCard: React.FC<MatchCardDataProps> = (props) => {
  const { matchCardData, handleNavigate, classStyle } = props;

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return "";
    const date = moment(dateString);
    return date.format("D MMM YY");
  };
  return (
    <>
      <div className={`matchCard ${classStyle}`} onClick={() => handleNavigate && handleNavigate(matchCardData?.flightList[0]?._id)}>
        <div className="subContainer">
          <div className="leftSubContainer">
            <span className="text-label">{matchCardData.tripStartArea || matchCardData?.flightList?.[0]?.departingAirport}</span>
            <span className="icon icon-aeroplane"></span>
            <span className="text-label">{matchCardData.tripEndArea || matchCardData?.flightList?.[0]?.destinationAirport}</span>
          </div>
          <p className="rightSubContainer">{formatDate(matchCardData?.fixtureDate)?.toUpperCase() || matchCardData.date}</p>
        </div>
        <div className="matchBody">
          <div className="subMatchBody">
            <h1 className="matchTitle">
              <TextResize maxFontSize={40} text={(matchCardData.team1?.replace(/_/g, ' ') ?? "") || (matchCardData?.homeTeam?.toUpperCase()?.replace(/_/g, ' ') ?? "")} />
            </h1>

            <h2 className="matchSubTitle">{t("VS")}.&nbsp;&nbsp;{matchCardData.team2?.replace(/_/g, ' ') || matchCardData?.otherTeam?.toUpperCase()?.replace(/_/g, ' ')}</h2>
          </div>
          <div className="matchRightContainer">
            {(matchCardData.amount || matchCardData?.flightList[0]?.totalPrice) && <span>£</span>}
            <div>{matchCardData.amount || matchCardData?.flightList[0]?.totalPrice || "TBC"}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchCard;
