import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
	listUserQueryParams,
	changeUserStatusParams,
	// listUserData,
	listUserErrorMessage,
	changeUserStatusData,
	changeUserStatusErrorMessage
} from "../../../interface/userInterface";
import userServices from "./userService";

export const listUser = createAsyncThunk(
	"/listUser",
	async (query: listUserQueryParams) => {
		const res = await userServices.listUser(query);
		return res;
	}
);

export const changeUserStatus = createAsyncThunk(
	"/changeUserStatus",
	async (data: changeUserStatusParams) => {
		const res = await userServices.changeUserStatus(data);
		return res;
	}
);

export const getUsersLocation = createAsyncThunk("auth/getLocations",
async ()=>{
	const res = await userServices.getUserLocation();
  return res;
})


// Define the initial state type
export interface UserState {
	listUserResponse: any
	listUserErrorMessage: listUserErrorMessage; // Change the type to 'string'
	changeUserStatusResponse: AxiosResponse<string, changeUserStatusData>;
	changeUserStatusErrorMessage: changeUserStatusErrorMessage; // Change the type to 'string'
	userFilters: any;
	isLoading: boolean;
	locationFilter:any,
	pagination:any,
	totalUsers:any
}

const userSlice = createSlice({
	name: "user",
	initialState: {
		listUserResponse: {},
		listUserErrorMessage: {},
		changeUserStatusResponse: {},
		changeUserStatusErrorMessage: {},
		userFilters: {},
		locationFilter:{},
		pagination:{page: 1,limit:20},
		totalUsers:0
	} as UserState,
	reducers: {
		setUserFilters: (state, action) => {
			state.userFilters = action.payload
		},
		paginationFunction : (state,action)=>{
      state.pagination = action?.payload
    }
	},
	extraReducers: (builder) => {
		builder
			.addCase(listUser.pending, (state) => { })
			.addCase(listUser.fulfilled, (state, action) => {
				if (state?.pagination?.page === 1) {
					state.listUserResponse = action.payload?.data;
					state.totalUsers = action.payload;
				}else {
					state.listUserResponse = [...state.listUserResponse,...action.payload?.data];
				}
			})
			.addCase(listUser.rejected, (state, action) => {
				state.listUserErrorMessage = action.error;
			});
		builder
			.addCase(changeUserStatus.pending, (state) => { })
			.addCase(changeUserStatus.fulfilled, (state, action) => {
				state.changeUserStatusResponse = action.payload;
			})
			.addCase(changeUserStatus.rejected, (state, action) => {
				state.changeUserStatusErrorMessage = action.error;
			});
			builder
			.addCase(getUsersLocation.pending, (state) => { })
			.addCase(getUsersLocation.fulfilled, (state, action) => {
				state.locationFilter = action.payload;
			})
			.addCase(getUsersLocation.rejected, (state, action) => {
				state.locationFilter = {};
			});
	},
});

export const { setUserFilters ,paginationFunction} = userSlice.actions;
export default userSlice.reducer;
