import React, { useState } from 'react';
import './toggleButton.css';

interface ToggleButtonProp {
  status: boolean,
  id: string,
  handleCheckChange?: (id: string, status: string) => void,
  openModal?:any,
}

const ToggleButton: React.FC<ToggleButtonProp> = ({ id, status, handleCheckChange ,openModal}) => {
  const [isChecked, setIsChecked] = useState(status);

  const handleChange = () => {
    setIsChecked(!isChecked);
    openModal && openModal(true)
    const status = !isChecked ? 'active' : 'inactive'
    if (handleCheckChange) handleCheckChange(id, status)
  };

  return (
    <label className="switch" title="Toggle">
      <input
        type="checkbox"
        checked={status}
        onChange={handleChange}
        aria-label="Toggle"
        placeholder="Toggle"
      />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleButton;