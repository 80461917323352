import React from "react";
import { useTranslation } from "react-i18next";
import TableHeader from "../../components/userTable/tableHeader";
import Loader from "../../components/loader/Loader";
import StickyBox from "react-sticky-box";

interface Day {
  dayOfMonth: number;
  dayOfWeek: string;
  date: string;
  eventNumber: number;
}
interface eventProps {
  month: string;
  days: Day[];
  onFilter?: () => void;
  filterModal: boolean | undefined;
  openUserDropdown: string | null;
  setOpenUserDropdown: any;
  show: boolean;
  setShow: any;
  event: boolean;
  user: boolean;
  leagueOption?: any;
  airportOption?: any;
  handleRange: any;
  handleShow: () => void;
  handleClose: () => void;
  dimensions: any;
  handleFilter: any;
  locationFilter: any;
  numberOfKeysWithValues: number;
  refreshClick: () => void;
  refresh: string;
  showFilterResult: boolean;
  handleRemoveArrival: any;
  handleRemoveDeparture: any;
  handleRemovePrice:()=> void;
  handleRemoveDate: ()=>void;
  clearFilter: boolean;
  startDate: any;
  setStartDate: any;
  handleRedirect: (data: Day,filter:any) => void;
  loading: boolean;
  calenderEventList: any;
  removeDate: () => void;
  airportDepartureOption: string[];
  range: any;
  maxTotalPriceObject: number;
  minTotalPriceObject:any;
  eventFilters:any
}

const EventsComponent: React.FC<eventProps> = (props) => {
  const {
    month,
    days,
    onFilter,
    filterModal,
    openUserDropdown,
    setOpenUserDropdown,
    show,
    setShow,
    event,
    leagueOption,
    airportOption,
    handleRange,
    handleShow,
    handleClose,
    dimensions,
    handleFilter,
    locationFilter,
    numberOfKeysWithValues,
    refreshClick,
    refresh,
    showFilterResult,
    handleRemoveArrival,
    handleRemoveDeparture,
    handleRemovePrice,
    handleRemoveDate,
    clearFilter,
    startDate,
    setStartDate,
    handleRedirect,
    loading,
    calenderEventList,
    removeDate,
    airportDepartureOption,
    range,
    maxTotalPriceObject,
    minTotalPriceObject,
    eventFilters
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <div className="events-container">
        {/* -- heading section -- */}
        <div className="subContent p-0">
          <div className="events-heading-section">
            <h1 className="dashboardTitle">{t("CALENDAR")}</h1>
            <p className="dashboardDescription">{t("CALENDAR_TXT")}</p>
          </div>

          {/* -- calendar section -- */}
          <StickyBox
          offsetTop={0}
          offsetBottom={20}
          style={{
            position: "sticky",
            zIndex: 200,
            background: "var(--PRIMARY)",
          }}
          className="stickBox pb-7"
        >
          <div className="calendar-section">
            <div className="headerContainer w-100">
              <TableHeader
                handleFilter={handleFilter}
                typeFilter={function (option: String): void {
                  throw new Error("Function not implemented.");
                }}
                locationFilter={locationFilter}
                onFilter={onFilter}
                filterModal={filterModal}
                placeHolder={t("SEARCH_BY_LEAGUE")}
                show={show}
                setShow={setShow}
                openUserDropdown={openUserDropdown}
                setOpenUserDropdown={setOpenUserDropdown}
                event={event}
                user
                leagueOption={leagueOption}
                airportOption={airportOption}
                handleRange={handleRange}
                handleShow={handleShow}
                handleCloseSidebar={handleClose}
                dimensions={dimensions}
                month={month}
                numberOfKeysWithValues={numberOfKeysWithValues}
                refreshClick={refreshClick}
                refresh={refresh}
                showFilterResult={showFilterResult}
                handleRemoveDate={handleRemoveDate}
                handleRemovePrice={handleRemovePrice}
                handleRemoveArrival={handleRemoveArrival}
                handleRemoveDeparture={handleRemoveDeparture}
                clearFilter={clearFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                totalEvent={calenderEventList?.totalEvents}
                removeDate={removeDate}
                airportDepartureOption={airportDepartureOption}
                range={range}
                maxTotalPriceObject={maxTotalPriceObject}
                minTotalPriceObject={minTotalPriceObject}
              />
            </div>
          </div>
          </StickyBox>
          {loading ? (
          <Loader />
        ) : (
          <div className="calender-main">
            <div className="calendar-item-container">
              {days.map((day, index) => {
                return (
                  <>
                    <div
                      className={`
                          ${day?.dayOfMonth === new Date().getDate() &&
                          new Date(day?.date).getMonth() === new Date().getMonth() &&
                          new Date(day?.date).getFullYear() === new Date().getFullYear()
                          ? "calendar-item selectedItem"
                          : ""
                        }
                          ${day?.eventNumber === 0
                          ? "disable-calendar-item calendar-item "
                          : "calendar-item "
                        }
                           ${!(
                          day?.dayOfMonth === new Date().getDate() &&
                          new Date(day?.date).getMonth() === new Date().getMonth() &&
                          new Date(day?.date).getFullYear() === new Date().getFullYear()
                        )
                          ? ""
                          : "selectedItem"
                        }
                     `}
                      key={index}
                      onClick={() =>
                        day?.eventNumber > 0 && handleRedirect(day,eventFilters)
                      }
                      >
                      <h6
                        className={`${day?.dayOfMonth === new Date().getDate() &&
                            new Date(day?.date).getMonth() ===
                            new Date().getMonth() &&
                            new Date(day?.date).getFullYear() ===
                            new Date().getFullYear()
                            ? "mb-0 calender-number selectedDate"
                            : "mb-0 calender-number"
                          }`}
                      >
                        {day?.dayOfMonth}
                      </h6>
                      <div
                        className={
                          day?.eventNumber === 0
                            ? "disable-event"
                            : "total-events-item"
                        }
                      >
                        <span
                          className={
                            day?.eventNumber
                              ? "icon-ticket disable"
                              : "icon-ticket"
                          }
                        ></span>
                        <h3>{day?.eventNumber}</h3>
                        <h3>
                          {day?.eventNumber === 1 ? t("EVENT") : t("EVENTS")}
                        </h3>
                      </div>
                      <div
                        className={
                          day?.eventNumber === 0 ? "disable-view" : "event-view"
                        }
                      >
                        <div className="item d-flex align-items-center">
                          <h3>{t("VIEW")} </h3>
                          <span className="icon-arrow"></span>
                        </div>
                        <h2>{day?.dayOfWeek.toLocaleUpperCase()}</h2>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default EventsComponent;
