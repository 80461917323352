import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settingService from "./settingService";

export const changePassword = createAsyncThunk("/auth/changePassword",
  async (data: any) => {
    const res = await settingService.changePassword(data);
    return res;
  })


export const resetPassword = createAsyncThunk("/auth/setPassword",
  async (data: any) => {
    const res = await settingService.resetPassword(data);
    return res;
  })

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    changePasswordResponse: {},
    resetPasswordResponse: {},
  },
  reducers: {
    handleResetPasswordResponse:(state,action)=>{
      state.resetPasswordResponse =action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => { })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordResponse = action.payload
      })
      .addCase(changePassword.rejected, (state, action) => {
      });
    builder
      .addCase(resetPassword.pending, (state) => { })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordResponse = action.payload
      })
      .addCase(resetPassword.rejected, (state, action) => {
      });
  },
})


export const { handleResetPasswordResponse } = settingSlice.actions;
export default settingSlice.reducer;



