import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import adminDashboardService from "./adminDashboardService";

export const dashboardDetailsList = createAsyncThunk("/dashboardDetails",async(queryParams:any)=>{
  const res = await adminDashboardService.dashBoardDetails(queryParams);
  return res
})


const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState: {
    dashboardListDetails: [],
    userCount: [],
    pagination:{page: 1,limit:20},
    totalEvents:0
  } as any,
  reducers: {
    paginationFunction : (state,action)=>{
      state.pagination = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(dashboardDetailsList.pending, (state) => {
     })
    .addCase(dashboardDetailsList.fulfilled, (state, action) => {
      const { eventList, userCount ,totalEvents} = action.payload.data;
      if (state?.pagination?.page === 1) {
        state.dashboardListDetails = eventList;
        state.userCount = userCount;
        state.totalEvents = totalEvents;
      } else {
        state.dashboardListDetails = [...state.dashboardListDetails, ...eventList];
      }
      })
    .addCase(dashboardDetailsList.rejected, (state, action) => {
      });
  },
})

export const {paginationFunction} = adminDashboardSlice.actions

export default adminDashboardSlice.reducer;