import React, { useEffect, useState } from 'react'
import EventsLinksComponent from './eventLinksComponent'
import { Helmet } from 'react-helmet'
import matchCardData from "../../utils/matchesCardData.json"
import { eventDetailsList } from '../../store/features/eventDetails/eventDetailSlice';
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../interface/userInterface'
import { useNavigate, useParams,  } from 'react-router-dom'
import useWindowDimension from '../../components/hooks/useWindowDimension';

const EventsLinksContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>();
  const [loading,setLoading] = useState(true)
  const dimensions = useWindowDimension();
  const eventDetails = useSelector((state:any)=> state?.eventDetails?.eventDetailsList)
  const [eventCardWidth, setEventCardWidth] = useState<number>(0);
  useEffect(()=>{
    if (id) {
      dispatch(eventDetailsList(id)).then(()=>{
        setLoading(false)
      })
    }
  },[])
  useEffect(() => {
    const eventCardWidthElement = document.getElementsByClassName("eventMatchCard")[0] as HTMLElement;
    if (eventCardWidthElement) {
      setEventCardWidth(eventCardWidthElement.offsetWidth - 100); // Adjust for padding/margin if needed
    }
  }, [dimensions.width]);
  return (
    <>
      <Helmet>
        <title>Event Details | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="View the best offers, departure airports, key dates for fixtures, and browse our partner offers on hotel stays." />
      </Helmet>
      <EventsLinksComponent
        matchCardData={matchCardData?.matchCardData}
        eventDetail={eventDetails?.data}
        loading={loading}
        dimensions={dimensions}
        navigate={navigate}
        eventCardWidth={eventCardWidth}
       
      />
    </>
  )
}

export default EventsLinksContainer