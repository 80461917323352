import { Helmet } from "react-helmet";
import UserManagementComponent from "./userManagementComponent";
import useWindowDimension from "../../../components/hooks/useWindowDimension";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../interface/authInterface";
import { showToast } from "../../../store/features/toast/toastSlice";
import { changeUserStatus, getUsersLocation, listUser,paginationFunction } from "../../../store/features/user/userSlice";
import { listUserQueryParams } from "../../../interface/userInterface";
import { setUserFilters } from "../../../store/features/user/userSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface option {
  label: string,
  value: string
}

const UserManagementContainer = () => {
  const dimensions = useWindowDimension();
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation();
  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  const [busy, setBusy] = useState(true);
  const [locationDetails, setLocationDetails] = useState<any>()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterModal, setFilterModal] = useState(false);
  const [openUserDropdown, setOpenUserDropdown] = useState<string | null>(null);
  const filters = useSelector((state: any) => state?.user?.userFilters)
  const [searchQuery, setSearchQuery] = useState<string>(filters?.search || "");
  const [showModal,setShowModal] = useState(false)
  const [userName,setUserName] = useState<any>({})
  const matchCardDivRef = useRef<any>();
  const [scrollCount, setScrollCount] = useState<number>(1);
  const isDataRef = useRef<boolean>(false)
  const countRef = useRef<number>(0)



  const users = useSelector((state:any)=> state.user?.listUserResponse)
  const totalUsers = useSelector((state:any)=> state.user?.totalUsers?.totalUsers)


  const formatDateString = (date:any) => {
    if (date !== undefined && date !== null && typeof date !== 'string') {
      return moment(date).format('DD-MM-YYYY');
    }
  };


  useEffect(() => {
    if (filters?.filters?.renewalDate) {
      try {
        const { start, end } = filters?.filters?.renewalDate;
        // Splitting date strings into day, month, and year
        const [startDay, startMonth, startYear] = start.split('-');
        const [endDay, endMonth, endYear] = end.split('-');
  
        // Constructing Date objects
        const startDate = new Date(startYear, startMonth - 1, startDay); // Month is 0-based
        const endDate = new Date(endYear, endMonth - 1, endDay);

        // Setting date range
        setDateRange([startDate, endDate]);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
  }, []);


  const handleModal = (data:any) => {
      const userDetails = {
        user: data?.fullName,
        status: data?.status,
        id:data?._id,
        email:data?.email
    };
    setUserName(userDetails);
      setShowModal(true);
  }

  const happendModalStatus = (id:string,status:string)=>{
    const statusMessage = status === "inactive" ? 'active' : 'inactive'
    handleUserStatusChange(id,statusMessage)
    setShowModal(false)
  }

  const handleFilterOption = () => {
    const filterData = {
      ...filters,
      filters: {},
    }
    filterModal && dispatch(setUserFilters(filterData))
    filterModal && setDateRange([null,null])
    filterModal && setScrollCount(1)
    setFilterModal(!filterModal);
  }

  const onFilter = () => {
    setFilterModal(!filterModal);
  }

  const clearDate = () => {
    const filterData = {
      ...filters,
      filters: {
        location: filters?.filters?.location,
        status: filters?.filters?.status,
        plan:filters?.filters?.plan,
        renewalDate: {}
      },
    }
    setDateRange([null,null])
    setScrollCount(1)
    dispatch(setUserFilters(filterData))
  }

  useEffect(() => {
    const formattedStartDate = startDate && formatDateString(startDate);
    const formattedEndDate = startDate && formatDateString(endDate);
    if (formattedStartDate && formattedEndDate) {
      const filterData = {
        ...filters,
        filters: {
          location: filters?.filters?.location,
          status: filters?.filters?.status,
          plan:filters?.filters?.plan,
          renewalDate: ({ start: formattedStartDate, end: formattedEndDate })
        },
      }
      setScrollCount(1)
      dispatch(setUserFilters(filterData))
    }
  }, [dateRange])


  useEffect(() => {
    const tableHeaderHeightElement = document.getElementsByClassName("headerContainer")[0] as HTMLElement;
    if (tableHeaderHeightElement) {
      setTableHeaderHeight(tableHeaderHeightElement.offsetHeight);
    }
  }, [])


  useEffect(() => {
    dispatch(getUsersLocation()).then((resultAction: any) => {
      if (getUsersLocation.fulfilled.match(resultAction)) {
        setLocationDetails(resultAction?.payload?.data)
      }
    });
  }, [])

  const getUserList = () => {
     setBusy(true)
    const queryParams: listUserQueryParams = {
      search: filters?.search || '',
      sortBy: filters?.sortBy || 'createdAt',
      sortOrder: filters?.sortOrder || -1,
      filters: JSON.stringify(filters?.filters) || {},
      page:scrollCount,
      limit: 20,
    }
    dispatch(paginationFunction({page:scrollCount}))
    dispatch(listUser(queryParams)).then((resultAction: any) => {
      if (listUser.fulfilled.match(resultAction as any)) {
        // setUsers(resultAction?.payload?.data)
        if(scrollCount * 20 <= resultAction?.payload?.totalUsers){
          countRef.current = resultAction?.payload?.totalUsers
          isDataRef.current = true
         }else {
          isDataRef.current = false
         }
        setBusy(false);
      } else if (listUser.rejected.match(resultAction)) {
        setBusy(false);
        dispatch(
          showToast({
            message: resultAction?.error?.message || "",
            type: "error",
          })
        );
      }
    });
  }

  useEffect(() => {
    getUserList()
    //  filters.search, filters.sortBy, filters.sortOrder
  }, [JSON.stringify(filters),scrollCount])

  /**
   * Handles the change in user status by dispatching an action to update the user status.
   * Shows a success toast message if the status change is successful, and an error toast message
   * if the status change is rejected.
   * @param {string} id - The id of the user whose status is being changed.
   * @param {string} status - The new status to be set for the user.
   * @returns None
   */
  const handleUserStatusChange = (id: string, status: string) => {
    dispatch(changeUserStatus({ id, status })).then((resultAction) => {
      if (changeUserStatus.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            message: (resultAction as any)?.payload?.message,
            type: "success",
          })
        );
        getUserList()
        setBusy(false);
      } else if (changeUserStatus.rejected.match(resultAction)) {
        setBusy(false);
        dispatch(
          showToast({
            message: resultAction?.error?.message,
            type: "error",
          })
        );
      }
    });
  }

  const handleSearch = (searchQuery: string) => {
    const newFilters = { ...filters, search: searchQuery }
    setScrollCount(1)
    dispatch(setUserFilters(newFilters))
  }

  /**
   * Handles sorting functionality based on the selected option.
   * @param {option} option - The selected sorting option.
   * @returns None
   */
  const handleSort = (option: option) => {
    const sortOrder = option.label === 'A-Z' || option.label === 'Name' ? 'asc' : 'desc'
    const newFilters = { ...filters, sortLabel: option.label, sortBy: option.value, sortOrder: sortOrder }
    setScrollCount(1)
    dispatch(setUserFilters(newFilters))
  }

  /**
   * Updates the filters based on the selected options and dispatches the updated filters.
   * @param {String[]} option - An array of selected filter options.
   * @returns None
   */
  const handleFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      filters: {
        location: filters?.filters?.location,
        status: option.toString(),
        plan: filters?.filters?.plan,
        renewalDate:filters?.filters?.renewalDate
      },
    }
    setScrollCount(1)
    dispatch(setUserFilters(newFilterData))
  }

  /**
   * Updates the filter data based on the selected option for the 'plan' filter.
   * @param {String} option - The selected option for the 'plan' filter.
   * @returns None
   */
  const typeFilter = (option: String) => {
    const newFilterData = {
      ...filters,
      filters: {
        location: filters?.filters?.location,
        status: filters?.filters?.status,
        plan: option.toString(),
        renewalDate:filters?.filters?.renewalDate
      }
    }
    setScrollCount(1)
    dispatch(setUserFilters(newFilterData))
  }

  /**
   * Updates the location filter in the user filters and dispatches the updated filters.
   * @param {String[]} option - The new location filter option to set.
   * @returns None
   */
  const locationFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      filters: {
        location: option,
        status: filters?.filters?.status,
        plan: filters?.filters?.plan,
        renewalDate:filters?.filters?.renewalDate
      }
    }
    setScrollCount(1)
    dispatch(setUserFilters(newFilterData))
  }

  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollCount]);


  const handleScroll = () => {
    const { scrollTop } = matchCardDivRef &&  matchCardDivRef?.current;
    if (
      (matchCardDivRef.current &&
      matchCardDivRef.current.scrollTop + matchCardDivRef.current.clientHeight ===
      matchCardDivRef.current.scrollHeight && isDataRef.current && scrollCount * 20 <= (countRef?.current ||totalUsers))
      ) {

        setScrollCount((prevCount) => prevCount + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>User Management | Triptix Admin Portal</title>
        <meta name="description" content="Manage, filter, sort & view all platform users including their membership and subscription information." />
      </Helmet>
      <UserManagementComponent
        dimensions={dimensions}
        userTableData={users}
        tableHeaderHeight={tableHeaderHeight}
        handleCheckChange={handleUserStatusChange}
        filterModal={filterModal}
        onFilter={onFilter}
        locationDetails={locationDetails}
        handleSearch={handleSearch}
        handleSort={handleSort}
        handleFilter={handleFilter}
        typeFilter={typeFilter}
        locationFilter={locationFilter}
        filtersDetails={filters}
        setDateRange={setDateRange}
        startDate={startDate}
        endDate={endDate}
        placeHolder={t("PLACEHOLDER_SEARCH")}
        clearDate={clearDate}
        handleFilterOption={handleFilterOption}
        busy={busy}
        openUserDropdown={openUserDropdown}
        setOpenUserDropdown={setOpenUserDropdown}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleModal={handleModal}
        showModal={showModal}
        setShowModal={setShowModal}
        userName={userName}
        happendModalStatus={happendModalStatus}
        dateRange={dateRange}
        dateClass ="date-picker-class"
        matchCardDivRef={matchCardDivRef}
        scrollCount={scrollCount}
        handleScroll={handleScroll}
      />
    </>
  );
};

export default UserManagementContainer;