import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../store/features/toast/toastSlice";
import SignUpComponent from "./signUpComponent";
import Constant from "../../../utils/constant";
import { AppDispatch } from "../../../interface/authInterface";
import { signUp, socialLogin } from "../../../store/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { storeEncryptedOTP } from "../../../components/crypto/cryptoUtils";

const SignUpContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const [termsCondition, setTermsCondition] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [loginData,setLoginData] = useState<any>("");
  const [assiocate, setAssiocate] = useState(false)
  const [loginError, setLoginError] = useState<any>("")
  const [socialLoading, setSocialLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
  const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)

	useEffect(() => {
		if (token && authResponse?.data?.accessToken && authResponse?.data?.role === "user" && (authResponse?.data?.isOnBoarded || subscribeResponse?.data?.isOnBoarded)) {
				navigate("/dashboard")
		} else if (token && authResponse?.data?.role === "user" && authResponse?.data?.isOtpVerified && !authResponse?.data?.isOnBoarded) {
			navigate("/onboarding")
		} 
    else {
			setLoading(false)
		}
	}, [authResponse])

  const onFormSubmit = async (value: any) => {
    setBusy(true);
    const params = {
      email: value?.email?.toLowerCase(),
      password: value?.password,
      confirmPassword: value?.confirmpassword,
    }
    setLoginData(params)
    dispatch(signUp(params)).then((resultAction) => {
      if (signUp.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            message: (resultAction as any)?.payload?.message,
            type: "success",
          })
        );
        setBusy(false);
        const timeandDate = new Date()
        const expireTime = new Date(timeandDate.getTime() + 5 * 60 * 1000)
        window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.EXPIRE_TIME, (expireTime as any));
        window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
        storeEncryptedOTP(resultAction?.payload?.data?.otp,process.env.REACT_APP_SECRECT_KEY)
        // window.localStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
        window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
        window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
        window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
        window.localStorage.setItem("userRole", resultAction?.payload?.data?.role);
        navigate("/otp");
      } else if (signUp.rejected.match(resultAction as any)) {
        setBusy(false);
        setLoginError((resultAction as any)?.payload?.message)
        if ((resultAction as any)?.payload?.status === 406) {
           setAssiocate(true)          
        }else {
          dispatch(
            showToast({
              message: (resultAction as any)?.payload?.message,
              type: "error",
            })
            );
          }
      }
    });
  };

  const signUpForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_ADDRESS_IS_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("ENTER_VALID_EMAIL_ADDRESS"),
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },
    confirmemail: {
      name: "confirmemail",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_ADDRESS_IS_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("ENTER_VALID_EMAIL_ADDRESS"),
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },

    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: t("PASSWORD_IS_REQUIRED"),
        },
        validate: {
          hasLowerCase_UpperCase: (value: string) =>
            value && value.match(Constant.REGEX.LOWERCASE) && value.match(Constant.REGEX.UPPERCASE) !== null,
          hasNumbers: (value: string) =>
            value && value.match(Constant.REGEX.NUMBER) !== null,
          hasSpecialChar: (value: string) =>
            value && value.match(Constant.REGEX.SPECIAL_CHARECTERS) !== null,
          length: (value: string) =>
            (value && value.length >= 8 ) || "",
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },

    confirmpassword: {
      name: "confirmpassword",
      validate: {
        required: {
          value: true,
          message: t("PASSWORD_IS_REQUIRED"),
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },
  };
  const multiErrorFields = [
    { length: t("MINIMUM_8_CHARACTERS") },
    { hasSpecialChar: t("MINIMUM_1_SPECIAL") },
    { hasNumbers: t("MINIMUM_1_NUMERIC") },
    { hasLowerCase_UpperCase: t("MINIMUM_1_UPPERCASE_LOWERCASE") },
  ];
  const handleSocialLogin = (provider: string, data: any) => {
    const params: any = {
      loginType: provider,
      email: data.email?.toLowerCase(),
      fullName: data.name
    }
    if (provider === 'google') params.googleId = data.sub
    if (provider === 'facebook') params.facebookId = data.userID
    setSocialLoading(true);
    dispatch(socialLogin(params)).then((resultAction) => {
      if (socialLogin.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            message: (resultAction as any)?.payload?.message,
            type: "success",
          })
        );
        window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
        window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
        window.localStorage.setItem("userRole", resultAction?.payload?.data?.role);
        window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
        setBusy(false);
        setSocialLoading(true);
        resultAction?.payload?.data?.isOnBoarded ? navigate("/dashboard") : navigate("/onboarding",{state:{ fullName:data.name}});
      } else if (socialLogin.rejected.match(resultAction)) {
        setBusy(false);
        setSocialLoading(false);
        setLoginError((resultAction as any)?.payload?.message)
        if ((resultAction as any)?.payload?.status === 406) {
           setAssiocate(true)          
        }else {
          dispatch(
            showToast({
              message: (resultAction as any)?.payload?.message,
              type: "error",
            })
            );
          }
      }
    });
  }

  const associateLogin = ()=>{
    const params : any ={
      ...loginData,
      socialAccount:true
    }
    dispatch(signUp(params)).then((resultAction) => {
      if (signUp.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            message: (resultAction as any)?.payload?.message,
            type: "success",
          })
        );
        setBusy(false);
        const timeandDate = new Date()
        const expireTime = new Date(timeandDate.getTime() + 5 * 60 * 1000)
        window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.EXPIRE_TIME, (expireTime as any));
        window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
        // window.localStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
        storeEncryptedOTP(resultAction?.payload?.data?.otp,process.env.REACT_APP_SECRECT_KEY)
        window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
        window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
        window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
        (resultAction as any)?.payload?.data?.isOnBoarded ? navigate("/dashboard") :  navigate("/onboarding");
      } else if (signUp.rejected.match(resultAction)) {
        setBusy(false);
        resultAction?.error?.message !== "You are registered with google using this email so please login with it to continue." &&  dispatch(
          showToast({
            message: resultAction?.error?.message,
            type: "error",
          })
        );
      }
    });
  }

  if (loading) return null; 
  
  return (
    <>
      <Helmet>
        <title>Sign Up | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Sign up to the Triptix platform for free to see the latest matches, search ticket offers and cheap flights." />
      </Helmet>
      <SignUpComponent
        signUpForm={signUpForm}
        multiErrorFields={multiErrorFields}
        onFormSubmit={onFormSubmit}
        busy={busy}
        handleSocialLogin={handleSocialLogin}
        termsCondition={termsCondition}
        showPassword={showPassword}
        setTermsCondition={setTermsCondition}
        setShowPassword={setShowPassword}
        confirmShowPassword={confirmShowPassword}
        setConfirmShowPassword={setConfirmShowPassword}
        assiocate={assiocate}
        setAssiocate={setAssiocate}
        associateLogin={associateLogin}
        loginError={loginError}
        socialLoading={socialLoading}
      />
    </>
  );
};

export default SignUpContainer;
