import { FILE_UPLOAD } from "../../../api/endPoint";
import instance from "../../../helpers/axios";


const fileUpload = (data:FormData) => {
  return instance.post(FILE_UPLOAD, data,{timeout:5*60*1000});
}

const fileEventServices = {
  fileUpload
};

export default fileEventServices;