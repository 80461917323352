import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../interface/userInterface";
import { handleOnboarding, handleOtpFlag, handlePlanSelect, handlePlanSelectGlobal, logout, setLoginResponse, setSocialAccount } from "../store/features/auth/authSlice";
import { setUserDashboardFilters } from "../store/features/userDashboard/userDashboardSlice";
import { setFootballFilters } from "../store/features/footballEvent/footballEventSlice";
import { resetFirstEventPrice, setEventFilters } from "../store/features/calenderEvent/calenderEventSlice";
import { handleResetPasswordResponse } from "../store/features/setting/settingSlice";

interface SidebarProps { }

const Sidebar: React.FC<SidebarProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()

  const logoutFunction = () => {
    localStorage.clear()
    dispatch(logout(null))
    dispatch(setLoginResponse(null))
    dispatch(setSocialAccount(false))
    dispatch(setUserDashboardFilters({}))
    dispatch(setFootballFilters({}))
    dispatch(setEventFilters({}))
    dispatch(resetFirstEventPrice(null))
    navigate("/login")
    dispatch(handleOnboarding(""))
    dispatch(handlePlanSelect(null))
    dispatch(handlePlanSelectGlobal(""))
    dispatch(handleOtpFlag(false))
    dispatch(handleResetPasswordResponse(null))
  }
  return (
    <div className="sidebar">
      <nav id="sidebar">

        <div className="sidebarMenu">
          <img src={logo} alt="logo" className="dashboardLogo" />
          <NavLink
            to="/dashboard"
            className={({ isActive }) => (isActive ? "active" : "inactive")}>
            <span className="icon-home"></span>
          </NavLink>
          <NavLink
            to="/football"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <span className="icon-football" />

          </NavLink>

          <NavLink
            to="/events"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <span className="icon-calendar" />

          </NavLink>

          <NavLink
            to="/settings"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <span className="icon icon-settings" />
          </NavLink>
        </div>
      </nav>
      <Button className="logOut" onClick={() => logoutFunction()}><span className="icon icon-logOut"></span>{t("LOG_OUT")}</Button>
    </div>
  );
};

export default Sidebar;
