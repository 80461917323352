import React from "react";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import AuthHeader from "../../../components/authHeader/authHeader";
import HookForm from "../../../components/hookForm/hookForm";
import CustomButton from "../../../components/customButton/customButton";
import "../auth.css";

interface OTPVerificationProps {
  busy: boolean;
  onFormSubmit: (e: React.FormEvent) => void;
  digitValue: string;
  onOtpChange: (otp: string) => void;
  timer: number;
  handleResendClick: () => void;
  otpForm: any;
  formatTime: any;
}

const OTPVerificationComponent: React.FC<OTPVerificationProps> = (props) => {
  const { t } = useTranslation();
  const {
    busy,
    onFormSubmit,
    digitValue,
    onOtpChange,
    timer,
    handleResendClick,
    formatTime
  } = props;

  return (
    <React.Fragment>
      <div className="authPages" >
        <div className="authCenter">
          <div className="whiteBack">
            <AuthHeader
              headerTitle={t("VERIFY_YOUR_EMAIL")}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                     <div className="textField">   
                      <OTPInput
                        onChange={onOtpChange}
                        value={digitValue}
                        numInputs={4}
                        inputType={"tel"}
                        renderSeparator={(): JSX.Element => <span> </span>}
                        containerStyle="otpContainer"
                        inputStyle={
                          digitValue
                            ? "otpInputFillStyle"
                            : "otp-input-none-style"
                        }
                        renderInput={(inputProps, index) => (
                          <input {...inputProps} key={index}
                          placeholder="0" />
                        )}
                      />
                      </div>
                      <CustomButton
                        title={t("VERIFY")}
                        disabled={digitValue?.length < 4}
                        loading={busy}
                      />
                      <div className="authOptions h-auto">
                        {t("HAVEN’T_RECIEVED_YOUR_CODE?")}
                        <p className="otpSubTxt">{t("IF_YOU_DON'T_SEE")}</p>
                        {timer > 0 ? (
                          <p className="resend-code-timer-style">
                            {t("RESEND_CODE")}
                            <span>&nbsp;{formatTime(timer)}</span>
                          </p>
                        ) : (
                          <p onClick={handleResendClick} className="authStyle">
                            {t("RESEND_CODE")}
                          </p>
                        )}
                        </div>
                    </>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OTPVerificationComponent;
