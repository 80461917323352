import React, { useEffect, useState } from 'react'
import EventsDetailsComponent from './eventsDetailsComponent'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router'
import { calenderDateEventList } from '../../../store/features/calenderDateEvent/calenderDateEventSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../interface/userInterface'
import useWindowDimension from '../../../components/hooks/useWindowDimension'
import moment from 'moment'

const EventsDetailsContainer: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const [eventList, setEventList] = useState<any>("")
  const [loading,setLoading] = useState(true)
  const dimensions = useWindowDimension();

  const handleNavigate = (id: number) => {
    navigate(`/events/details/${id}`)
  }


  const formatArrayToString = (array: any) => {
    // Check if array is not empty and is an array
    if (array && Array.isArray(array)) {
      return array.join(",");
    }
    else {
      return "";
    }
  };
  useEffect(() => {
    if (location?.state) {
      const filter = {
        date: location?.state?.date,
        airportDeparture: formatArrayToString(location?.state?.filter?.airportDeparture),
        airportArrival:formatArrayToString(location?.state?.filter?.airportArrival),
        month: location?.state?.filter?.filter?.month,
        totalPrice:formatArrayToString(location?.state?.filter?.totalPrice)
      }
      dispatch(calenderDateEventList(filter)).then((res: any) => {
        setEventList(res?.payload?.data)
        setLoading(false)
      })
    }
  }, [])

  const dayOfMonthSuffix = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];

  // Function to get the ordinal suffix for a given day of the month
  function getDayOfMonthSuffix(day: number) {
    if (day >= 11 && day <= 13) {
      return 'th'; // 11th, 12th, and 13th use 'th' suffix
    }
    return dayOfMonthSuffix[day % 10]; // Use the corresponding suffix from the array
  }

  // Function to convert date to the desired format
  function formatDateToCustomFormat(dateString: string) {
    const date = moment(dateString);
    const dayOfWeek = date.format('dddd'); // Get day of the week
    const dayOfMonth = date.date(); // Get day of the month
    const month = date.format('MMMM'); // Get full month name
    const suffix = getDayOfMonthSuffix(dayOfMonth); // Get ordinal suffix for day of the month

    // Construct the formatted string
    const formattedDate = `${dayOfWeek} ${dayOfMonth}${suffix}, ${month}`;

    return formattedDate;
  }

  // Example usage
  const formattedDate = formatDateToCustomFormat(location?.state?.date);
  return (
    <>
      <Helmet>
        <title>Event Details | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="View the best offers, departure airports, key dates for fixtures, and browse our partner offers on hotel stays." />
      </Helmet>
      <EventsDetailsComponent
        handleNavigate={handleNavigate}
        eventList={eventList}
        formattedDate={formattedDate}
        loading={loading}
        dimensions={dimensions}
      />
    </>
  )
}

export default EventsDetailsContainer