import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/customButton/customButton";
import ProfileContainer from "./profile/profileContainer";
import PurchaseContainer from "./purchaseSubscription/purchaseContainer";
import HowItWorksContainer from "./howItWorks/howItWorksContainer";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
interface OnboardingProps {
  onboardingCardData: {
    _id?: Number;
    tripStartArea?: string;
    tripEndArea?: string;
    date?: string;
    team1?: string;
    team2?: string;
    amount?: string;
    imageUri?: string;
  }[];
  onboardingContainerHeight?: any;
  dimensions?: any;
  about: boolean,
  work: boolean,
  purchase: boolean,
  setAbout: any,
  setPurchase: any,
  OnboardingCard: any,
  setWork: any,
  setPlan: any,
  plan: any,
  handlePlan: any,
  fullName: string,

}

const OnBoardingComponent: React.FC<OnboardingProps> = ({
  onboardingCardData, about, setAbout, work, setWork, purchase, setPurchase, OnboardingCard, setPlan, plan, handlePlan, fullName
}) => {
  const [scroll, setScroll] = useState(false)
  const { t } = useTranslation();
  const navigate = useNavigate()

  // set to the page no top
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [scroll])
  
  return (
    <React.Fragment>
      <div>
        {purchase ? (
          <div className="onboardingMain">
            <div className="onboardingContainer">
              <PurchaseContainer
                setPlan={handlePlan}
                plan={plan}
              />
              <CustomButton
                title={t("NEXT")}
                icon="arrow"
                onClick={() => {
                  setWork(false);
                  // setAbout(true);
                  navigate("/profile", {state:{fullName:fullName,plan:plan}})
                }}
                disabled={!plan}
              />
            </div>
          </div>
        ) : work ? (
          <div className="workMainContainer">
            <div className="onboardingContainer">
              <HowItWorksContainer />
              <CustomButton
                title={t("NEXT")}
                icon="arrow"
                onClick={() => {
                  setPurchase(true);
                  setAbout(false);
                  setWork(false);
                  setScroll(true)
                }}
              />
            </div>
          </div>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <div className="onboardMain">
              <div className="onboardingContainer" >
                <img src="https://triptixclient.blob.core.windows.net/triptix-image/onBoarding.png" alt="" className="onboardImg" />
                <div className="subTitleContainer">
                  <h1>{t("ABOUT_TRIPTIX")}</h1>
                  <p>{t("TRIPTIX_EMERGE_FROM")}</p>
                  <CustomButton
                    title={t("NEXT")}
                    onClick={() => { setWork(true); }}
                    icon="arrow"
                  />
                  <div className="bottomContainer">
                    <p>{t("TO_ADD_THIS_WEB")}</p>
                    <div>{t("TAP")} <NavLink><span className="icon-box-arrow-up"></span></NavLink> {" "} {t("OR")}<NavLink><span className="icon-menu"></span></NavLink> {t("AND_THEN_ADD")}</div>
                  </div>
                </div>
              </div>
            </div>
          </Suspense>
        )}
      </div >
    </React.Fragment >
  );
};

export default OnBoardingComponent;
