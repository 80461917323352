import React from "react";
import TableHeader from "../../components/userTable/tableHeader";
import { useTranslation } from "react-i18next";
import MatchCard from "../../components/cards/dashboardCard/dashboardMatchCard";
import Loader from "../../components/loader/Loader";
import StickyBox from "react-sticky-box";
import {Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Mousewheel,Scrollbar } from "swiper/modules";
import 'swiper/css/scrollbar';
interface FootballProps {
  filterModal?: boolean | undefined;
  onFilter?: () => void;
  matchCardData: {
    _id: number;
    tripStartArea: string;
    tripEndArea: string;
    date: string;
    team1: string;
    team2: string;
    amount: string;
  }[];
  handleFilter: (option: any) => void;
  locationFilter: (option: any) => void;
  typeFilter: (option: any) => void;
  handleNavigate: (id: string) => void;
  handleSearch: (Search: string) => void;
  dimensions?: any;
  show?: boolean;
  setShow?: any;
  handleShow?: () => void;
  footballEventDetail: any;
  loading: boolean;
  leagueOption?: any;
  airportOption?: any;
  removeDate: () => void;
  handleRange: any;
  refresh: string;
  user: boolean;
  numberOfKeysWithValues: number;
  showFilterResult: boolean;
  searchResult: boolean;
  filters: any;
  refreshClick: () => void;
  handleRemoveArrival: any;
  handleRemoveLeague: any;
  handleRemoveDeparture: any;
  handleRemovePrice: any;
  handleRemoveDate: any;
  handleClose: () => void;
  startDate: any;
  setStartDate?: any;
  football: boolean;
  searchQuery: string;
  setSearchQuery: (Search: string) => void;
  openUserDropdown: string | null;
  setOpenUserDropdown: any;
  clearFilter: boolean;
  range: any;
  airportDepartureOption: string[];
  maxTotalPriceObject: number;
  dateClass: string;
  minTotalPriceObject:any,
  scrollCount:number,
  matchCardDivRef:React.RefObject<HTMLDivElement>,
  upcomingEventsList:any
}

const FootballComponent: React.FC<FootballProps> = (props) => {
  const { t } = useTranslation();
  const {
    filterModal,
    onFilter,
    handleFilter,
    locationFilter,
    typeFilter,
    handleNavigate,
    dimensions,
    show,
    setShow,
    handleShow,
    footballEventDetail,
    loading,
    leagueOption,
    airportOption,
    removeDate,
    handleSearch,
    handleRange,
    refresh,
    numberOfKeysWithValues,
    showFilterResult,
    searchResult,
    filters,
    refreshClick,
    handleRemoveArrival,
    handleRemoveLeague,
    handleRemoveDeparture,
    handleRemovePrice,
    handleRemoveDate,
    handleClose,
    startDate,
    setStartDate,
    football,
    searchQuery,
    setSearchQuery,
    openUserDropdown,
    setOpenUserDropdown,
    clearFilter,
    range,
    airportDepartureOption,
    maxTotalPriceObject,
    dateClass,
    minTotalPriceObject,
    matchCardDivRef,
    upcomingEventsList,
    scrollCount
  } = props;

  return (
    <>
      <div className="football-container">
        <div className="subContent">
          <h1 className="dashboardTitle">{t("FOOTBALL")}</h1>
          <p className="dashboardDescription">{t("FOOTBALL_HEADER")}</p>
        </div>
        <StickyBox
          offsetTop={0}
          offsetBottom={20}
          style={{
            position: "sticky",
            zIndex: 200,
            background: "var(--PRIMARY)",
          }}
          className="stickBox"
        >
          <div className="headerContainer" style={{ marginBottom: "24px" }}>
            <h1>{t("SEARCH")}</h1>
            <TableHeader
              onFilter={onFilter}
              filterModal={filterModal}
              handleFilter={handleFilter}
              locationFilter={locationFilter}
              typeFilter={typeFilter}
              placeHolder={t("SEARCH_BY_LEAGUE")}
              user
              filter
              dimensions={dimensions}
              show={show}
              setShow={setShow}
              handleShow={handleShow}
              leagueOption={leagueOption}
              airportOption={airportOption}
              removeDate={removeDate}
              onSearch={handleSearch}
              refresh={refresh}
              handleRange={handleRange}
              numberOfKeysWithValues={numberOfKeysWithValues}
              showFilterResult={showFilterResult}
              refreshClick={refreshClick}
              handleRemoveArrival={handleRemoveArrival}
              handleRemoveLeague={handleRemoveLeague}
              handleRemoveDeparture={handleRemoveDeparture}
              handleRemovePrice={handleRemovePrice}
              handleRemoveDate={handleRemoveDate}
              handleCloseSidebar={handleClose}
              startDate={startDate}
              setStartDate={setStartDate}
              football={football}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              openUserDropdown={openUserDropdown}
              setOpenUserDropdown={setOpenUserDropdown}
              clearFilter={clearFilter}
              range={range}
              airportDepartureOption={airportDepartureOption}
              maxTotalPriceObject={maxTotalPriceObject}
              dateClass={dateClass}
              minTotalPriceObject={minTotalPriceObject}
            />
          </div>
        </StickyBox>
        <div
          className={
            numberOfKeysWithValues > 0 ? "footballdiv mt-5" : "footballdiv"
          }
        >
          {(loading && scrollCount === 1) || (filters?.search && !searchResult) ? (
            <Loader />
          ) : (
            <>
              <p
                className={
                  numberOfKeysWithValues > 0
                    ? "matchHeader pt-2"
                    : "matchHeader pt-4"
                }
                id="subHeader"
              >
                {numberOfKeysWithValues > 0 &&
                footballEventDetail?.length === 0 &&
                upcomingEventsList?.length === 0
                  ? t("SEARCH_RESULT")
                  : t("UPCOMING_MATCHES")}
              </p>
              <div className="football-section" >
                {upcomingEventsList?.length !== 0 ? (
                  <div className={dimensions.width<=490?"football-card":""}>
                 { dimensions.width>490?(<Swiper
                        direction="horizontal"                      
                        slidesPerView={'auto'}
                        mousewheel={{releaseOnEdges:true}}
                        scrollbar={{ draggable: true }}
                        freeMode={true}
                        modules={[FreeMode,Mousewheel,Scrollbar]}                        
                        className="myswiper"
                      >
                        {upcomingEventsList?.map(
                          (data: any, index: number) => (
                            <SwiperSlide key={index}>
                              <MatchCard
                                matchCardData={data}
                                classStyle="mb-2"
                                handleNavigate={handleNavigate}
                              />
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>):(<>{upcomingEventsList?.map(
                          (data: any, index: number) => (
                              <MatchCard
                                matchCardData={data}
                                classStyle="mb-2"
                                handleNavigate={handleNavigate}
                              />
                          )
                        )}</>)}
                  </div>
                ) : (
                  footballEventDetail?.length !== 0 && (
                    <div className="error-message">
                      {numberOfKeysWithValues > 0
                        ? t("NO_RESULT_FOUND")
                        : t("NO_MATCHES_FOUND")}
                    </div>
                  )
                )}
              </div>

              {footballEventDetail?.length !== 0 ? (
                <div ref={matchCardDivRef} className={dimensions.width<=490?"football-card":""}>
                  {footballEventDetail?.map(
                    (data: any, index: number) => (
                      <div key={index} >
                        <p className="matchHeader">{data?.leagueName}</p>
                        <div
                          className={dimensions.width<=490?"football-card":""}
                          style={{
                            flexDirection:
                              dimensions.width <= 490 ? "column" : "row",
                          }}
                        >
                            {dimensions?.width>490?(<Swiper
                              direction={dimensions?.width >= 490 ? "horizontal":"vertical"}
                              freeMode={true}
                              slidesPerView={'auto'}
                              className="myswiper"
                              mousewheel={{releaseOnEdges:true}}
                              scrollbar={{ draggable: true }}
                              modules={[FreeMode,Mousewheel,Scrollbar]}
                             
                            >
                              {data?.events?.map((event: any) => (
                                <SwiperSlide key={event._id}>
                                  <MatchCard
                                    matchCardData={event}
                                    handleNavigate={handleNavigate}
                                    classStyle="mb-2"
                                  />
                                </SwiperSlide>
                              ))}
                            </Swiper>):(<> {data?.events?.map((event: any) => (
                                  <MatchCard
                                    matchCardData={event}
                                    handleNavigate={handleNavigate}
                                    classStyle="mb-2"
                                  />
                              ))}</>)}
                             
                        </div>
                      </div>
                    )
                  )}
                   { loading && scrollCount > 1 && <Loader pagination/>}
                </div>
              ) : (
                <div className="error-message">{t("NO_RESULT_FOUND")}</div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default FootballComponent;
