import { Helmet } from "react-helmet";
import PurchaseComponent from "./purchaseComponent";
import purchaseCardData from "../../../utils/purchaseCardData.json";
const PurchaseContainer = (props:any) => {
  const {setPlan,plan} = props; 
  return (
    <>
      <Helmet>
        <title>Purchase | Triptix</title>
        <meta name="description" content="Purchase || Triptix" />
      </Helmet>
      <PurchaseComponent
        setPlan={setPlan}
        purchaseCardData={purchaseCardData.purchaseCardData}
        plan={plan}
      />
    </>
  );
};

export default PurchaseContainer;

