import React from "react";
import "./dashboardCard.css";
import { useTranslation } from "react-i18next";


interface DashboardCardDataProps {
  dashboardCardData: any;
}

const DashboardCard: React.FC<DashboardCardDataProps> = (props) => {
  const { dashboardCardData } = props;
  const { t } = useTranslation();

  const handlePlanName = (name: string) => {
    if (name === "etclite") {
      return "free MEMBERS"
    } else if (name === "etcannual") {
      return "Annual MEMBERS";
    } else if (name === "etcmonthly") {
      return "Monthly MEMBERS";
    } else {
      return "Total MEMBERS"
    }
  }
  return (
    <>
      {dashboardCardData?.planwiseCounts?.map((item: any, index: number) => {
        return (
          <>
            <div className="cardContainer" key={index}>
              <h1>{item?.count}</h1>
              <p className="cardTitle">{handlePlanName(item?.plan)?.toUpperCase()} </p>
            </div>
          </>
        )
      })}
      {dashboardCardData?.totalUsers && <div className="cardContainer" >
        <h1>{dashboardCardData?.totalUsers}</h1>
        <p className="cardTitle">{t("TOTAL_MEMBER")}</p>
      </div>}
    </>
  );
};

export default DashboardCard;
