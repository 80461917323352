import React from "react";
import { useTranslation } from "react-i18next";
import AuthHeader from "../../../components/authHeader/authHeader";
import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import "../auth.css";

interface ResetPasswordProps {
  resetForm: any;
  multiErrorFields: any;
  onFormSubmit: (event: React.FormEvent) => void;
  busy: boolean;
  setShowPassword: any;
  showPassword: boolean
  setConfirmShowPassword: any
  confirmShowPassword: boolean
}

const ResetPasswordComponent: React.FC<ResetPasswordProps> = (props) => {
  const { t } = useTranslation();
  const { resetForm, multiErrorFields, onFormSubmit, busy,   setShowPassword, showPassword, setConfirmShowPassword, confirmShowPassword } = props;
  return (
    <React.Fragment>
      <div className="authPages" >
        <div className="authCenter">
          <div className="whiteBack">
            <AuthHeader
              headerTitle={t("RESET_PASSWORD")}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                    <div className="textField">
                      <TextField
                        placeholder={t("PASSWORD")}
                        autoFocus={false}
                        formMethod={formMethod}
                        rules={resetForm.password.validate}
                        name={resetForm.password.name}
                        errors={formMethod?.formState.errors}
                        type="password"
                        showPassword={showPassword}
                        auth={true}
                        togglePasswordVisibility={() =>
                          setShowPassword((prev: unknown) => !prev)
                        }
                        multiErrorFields={multiErrorFields}
                        onChange={(value) => {
                          formMethod?.watch("confirmpassword") &&
                            formMethod.trigger("confirmpassword");
                        }}
                      />
                      <TextField
                        placeholder={t("CONFIRM_PASSWORD")}
                        autoFocus={false}
                        formMethod={formMethod}
                        rules={{
                          required: {
                            value: true,
                            message:t("PLEASE_RE-ENTER_THE_PASSWORD"),
                          },
                          validate: {
                            matchPassword: (value: string) =>
                              (value &&
                                value === formMethod?.watch("password")) ||
                              t("PLEASE_RE-ENTER_THE_PASSWORD"),
                          },
                        }}
                        name={resetForm.confirmpassword.name}
                        errors={formMethod?.formState.errors}
                        type="password"
                        showPassword={confirmShowPassword}
                        togglePasswordVisibility={() =>
                          setConfirmShowPassword((prev: unknown) => !prev)
                        }
                        onChange={(e) => {
                          formMethod?.watch("password") &&
                            formMethod.trigger("password");
                        }}
                      />
                      </div>
                      <CustomButton
                        className="btnAuth"
                        title={t("SUBMIT")}
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordComponent;
