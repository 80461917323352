import { FOOTBALL_EVENTS } from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { listDashboardQueryParams } from "../../../interface/userInterface";


const footballEvents = (query:listDashboardQueryParams) => {
  return instance.get(FOOTBALL_EVENTS,{params: query});
};

const footballEventService = {
  footballEvents
};

export default footballEventService;