import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import OTPVerificationComponent from "./otpVerificationComponent";
import Constant from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { handleOnboarding, handleOtpFlag, handlePlanSelectGlobal, removeOtp, resendOTP, verifyOTP } from "../../../store/features/auth/authSlice";
import { AppDispatch } from "../../../interface/authInterface";
import { useDispatch, useSelector, } from "react-redux";
import { showToast } from "../../../store/features/toast/toastSlice";
import { useTranslation } from "react-i18next";
import { isOTPExpired, retrieveDecryptedOTP, storeEncryptedOTP } from "../../../components/crypto/cryptoUtils";
import Loader from "../../../components/loader/Loader";


const OTPVerificationContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const [digitValue, setDigitValue] = useState('');
  const [timer, setTimer] = useState(5 * 60 * 1000);
  const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
  const userId = localStorage.getItem("userId")
  const otpFlag = useSelector((state: any) => state?.auth?.otpFlag)

  /**
 * Redirects the user based on authentication response and token.
 * If token exists and the user is authenticated and onboarded, redirects to the dashboard.
 * If token exists and the user is authenticated, OTP verified or OTP flag is true, but not onboarded, redirects to the onboarding page.
 * Otherwise, stops loading.
 */

	useEffect(() => {
		if (token && authResponse?.data?.accessToken && authResponse?.data?.role === "user" && authResponse?.data?.isOnBoarded) {
				navigate("/dashboard")
		}
    else if (token && authResponse?.data?.role === "user"&& (authResponse?.data?.isOtpVerified || otpFlag) && !authResponse?.data?.isOnBoarded) {
			navigate("/onboarding")
		}
    else {
			setLoading(false)
		}
	}, [authResponse])



  useEffect(() => {
    const token : any = localStorage.getItem("accessToken");
    const userID = localStorage.getItem("userId")
    const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_URL}auth/sse?userID=${userID}`);


    eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data?.status === "OTP Verified") {
          setLoading(true)
          dispatch(handleOnboarding(data?.user))
          dispatch(handlePlanSelectGlobal(data?.user?.plan))
          setTimeout(()=>{
           setLoading(false)
           const message = "Your account is verified now.";
           dispatch(
             showToast({
               message,
               type: "success",
               time:10000
             })
           );
           navigate("/onboarding",{state:data?.status}) 
          },6000)
        }
    };

    eventSource.onerror = () => {
        eventSource.close();
    };

    return () => {
        eventSource.close();
    };
}, []);


  const onOtpChange = (value: string) => {
    if (value.length < digitValue.length) {
      setDigitValue(value);
    } else {
      if (digitValue.length === value.length - 1) {
        setDigitValue(value);
      }
    }
  };

  const handleResendClick = () => {
    dispatch(resendOTP()).then((resultAction:any) => {
      if (resendOTP?.fulfilled.match(resultAction)) {
        dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "success",
					})
				);
        dispatch(removeOtp(resultAction?.payload))
        window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
        // window.localStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
        storeEncryptedOTP(resultAction?.payload?.data?.otp,process.env.REACT_APP_SECRECT_KEY)
      }
        setTimeout(() => {
        sessionStorage.removeItem(Constant.SESSIONSTORAGEKEYS.OTP);
        localStorage.removeItem(Constant.SESSIONSTORAGEKEYS.OTP);
      }, 5 * 59 * 1000); 
    })
    setTimer(5 * 60 * 1000);
  };

  const otpForm = {
    otp: {
      name: "otp",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  useEffect(() => {
    if (timer > 0) {
      const timerID = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1000);
      }, 1000);

      return () => {
        clearTimeout(timerID);
      };
    }
  }, [timer]);

  const formatTime = (time: number): string => {
    const minutes: number = Math.floor(time / 60000);
    const seconds: string = ((time % 60000) / 1000).toFixed(0);
    return `${minutes}:${(parseInt(seconds, 10) < 10 ? '0' : '')}${seconds}`;
  };
  const data = retrieveDecryptedOTP(process.env.REACT_APP_SECRECT_KEY)
  const time  = localStorage.getItem(Constant.LOCALSTORAGEKEYS.TIMESTAMP);
  const onFormSubmit = async (value:any) => {
    const timeCheck =  isOTPExpired(time)
    setBusy(true);
    const queryParams : any= {
      userID: userId
    }
    if (timeCheck) {  
    if (digitValue?.toString() === data?.toString()) {
      navigate("/onboarding")
      dispatch(verifyOTP(queryParams)).then((data:any)=>{
        dispatch(handleOtpFlag(true))
        setTimeout(() => {
          const message = data?.payload?.message;
          dispatch(
            showToast({
              message,
              type: "success",
            })
          );
          setBusy(false);
        }, 3000);
      })
    }else {
      setBusy(false);
      dispatch(
        showToast({
          message: t("OTP_NOT_MATCH"),
          type: "error",
        })
      );
    }
  }else {
    setBusy(false);
    dispatch(
      showToast({
        message: t("OTP_EXPIRED"),
        type: "error",
      })
    );
  }

  };

  if (loading) return  <div className="fade offcanvas-backdrop show">
  <Loader />
</div>; 

  return (
    <>
      <Helmet>
        <title>OTP Verification | TRIPTIX</title>
        <meta name="description" content="OTP Verification || TRIPTIX" />
      </Helmet>
      <OTPVerificationComponent
        busy={busy}
        onFormSubmit={onFormSubmit}
        digitValue={digitValue}
        onOtpChange={onOtpChange}
        timer={timer}
        handleResendClick={handleResendClick}
        otpForm={otpForm}
        formatTime={formatTime}
      />
    </>
  );
};

export default OTPVerificationContainer;
