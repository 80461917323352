import React from "react";
import EventsCard from "../../components/cards/eventsCard/eventsCard";
import { t } from "i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import TextResize from "../../components/textResize/textResize";
interface eventLinksProps {
  matchCardData: {
    _id: number;
    tripStartArea: string;
    tripEndArea: string;
    date: string;
    team1: string;
    team2: string;
    amount: string;
  }[];
  eventDetail: {
    _id: string;
    homeTeam: string;
    otherTeam: string;
    fixtureDate: Date;
    dateConfirmed: string;
    leagueName: string;
    departingAirport: string;
    departureDate: Date;
    stops: string;
    destinationAirport: string;
    returnDate: Date;
    premium: string;
    featured: string;
    isNewUploaded: string;
    eventDetails: any;
    totalPrice: string;
    ticketPrice: string;
    ticketLink: string;
    hotelLink: string;
    price: string;
    link: string;
  };
  loading?: boolean;
  dimensions?: any;
  navigate?: any;
  eventCardWidth?:any;
}
const EventsLinksComponent: React.FC<eventLinksProps> = ({
  matchCardData,
  eventDetail,
  dimensions,
  loading,
  navigate,
  eventCardWidth
}) => {
  const upperCase = (value: string) => {
    return value?.toUpperCase();
  };
  return (
    <>
      {loading ? (
        <div className="loading-spinner">
          <div className="loading"></div>
        </div>
      ) : (
        <>
          <div className="eventsLinksContainer">
            <div className="back-button" onClick={() => navigate(-1)}>
              {" "}
              <span className="icon-arrow-left"></span> Back{" "}
            </div>
            <div className="eventsHeading" id="eventsHeader">
              <div className="matchCard eventCard" onClick={()=> window.open(eventDetail?.ticketLink || "https://www.skyscanner.net/","_blank")}>
                {/* matchBody */}
                <div className="matchBody">
                  <div>
                    <h1 className="matchTitle">
                     <TextResize  maxFontSize={32} eventCard={10} text={eventDetail?.homeTeam?.replace(/_/g, " ")} />
                    </h1>
                    <h2 className="matchSubTitle">      
                    <TextResize maxFontSize={32} eventCard={8} text={`VS. ${eventDetail?.otherTeam?.replace(/_/g, " ")}`} />                   
                     </h2>
                  </div>
                  <div className="subContainer" id="subRightMain">
                    <div className="eventLeftContainer" id="dateEventDiv">
                      {moment(eventDetail?.fixtureDate)
                        .format("DD MMM ")
                        .toUpperCase()}
                    </div>
                    <p><TextResize eventCard={7} text={(eventDetail?.leagueName)} /> </p>
                  </div>
                </div>
                <div className="subContainer">
                  <div className="eventLeftContainer">
                    <Link
                      to={
                        eventDetail?.ticketLink || "https://www.skyscanner.net/"
                      }
                      target="blank"
                    >
                      <span className="header">{t("PURCHASE_TICKETS")}</span>
                      <span className="icon icon-link"></span>
                    </Link>{" "}
                  </div>
                  <div className="matchRightContainer">
                    {eventDetail?.ticketPrice && <span>£</span>}
                    <div>{eventDetail?.ticketPrice || "TBC"}</div>
                  </div>
                </div>
              </div>
              <span className="icon-Travel"></span>
            </div>

            <div className="travelSection">
              <p className="matchHeader">
                <span>{t("TRAVEL")}</span>
              </p>
            </div>
            <div className="match-container">
              <div
                className={dimensions.width <= 490 ? "event-details-card" : ""}
                style={{
                  flexDirection: dimensions.width <= 490 ? "column" : "row",
                }}
              >
                {eventDetail?.eventDetails && dimensions.width > 490 ? (
                  <Swiper
                    direction="horizontal"
                    slidesPerView={"auto"}
                    mousewheel={{releaseOnEdges:true}}
                    scrollbar={{ draggable: true }}
                    freeMode={true}
                    modules={[FreeMode, Mousewheel, Scrollbar]}
                    className="myswiper"
                  >
                    {eventDetail?.eventDetails?.map(
                      (data: any, index: number) => (
                        <SwiperSlide key={index}>
                          <EventsCard
                            key={index}
                            eventDetails={data}
                            classStyle="mb-2"
                          />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                ) : (
                  eventDetail?.eventDetails?.map((data: any, index: number) => (
                    <EventsCard
                      key={index}
                      eventDetails={data}
                      classStyle="mb-2"
                    />
                  ))
                )}
              </div>
            </div>

            <div className="hotelsSection">
              <p className="matchHeader">
                <span>{t("HOTELS")}</span>
              </p>
              <div className="eventsHeading">
                <div className="matchCard" onClick={()=> window.open( eventDetail?.hotelLink ||"https://www.manutd.com/tickets","_blank")}>
                  {/* matchBody */}
                  <div className="matchBody">
                    <div>
                      <h1 className="matchTitle">{t("BOOK_YOUR_HOTEL")}</h1>
                      <h2 className="matchSubTitle">{t("HOTEL_DISCOUNT")}</h2>
                    </div>
                  </div>
                  <div className="subContainer">
                    <div className="eventSubContainer">
                      <div className="eventLeftContainer eventContent">
                        <Link
                          to={
                            eventDetail?.hotelLink ||
                            "https://www.manutd.com/tickets"
                          }
                          target="blank"
                        >
                          {t("EXPEDIA")}{" "}
                          <span className="icon icon-link"></span>
                        </Link>{" "}
                      </div>
                    </div>
                    {/* <div className="matchRightContainer">
                  <div> {eventDetail?.price ?`££ ${eventDetail?.price}` : "TBC"}</div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventsLinksComponent;
