import { Helmet } from "react-helmet";
import HowItWorksComponent from "./howItWorksComponent";

const HowItWorksContainer = () => {
  return (
    <>
      <Helmet>
        <title>How It Works | Triptix</title>
        <meta name="description" content="How It Works || Triptix" />
      </Helmet>
      <HowItWorksComponent/>
    </>
  );
};

export default HowItWorksContainer;
