import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../../store/features/toast/toastSlice";
import SignInComponent from "./signInComponent";
import Constant from "../../../../utils/constant";
import { loginUser } from "../../../../store/features/auth/authSlice";
import { AppDispatch } from "../../../../interface/authInterface";

const SignInContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);
  const [errorMsg,setErrorMsg] = useState<string | undefined>()
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
	const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')

  useEffect(() => {
		if (token && authResponse?.data?.accessToken && authResponse?.data?.role === "admin") {
				navigate("/admin/dashboard")
		}else {
			setLoading(false)
		}
	}, [authResponse])

  const rememberMeFunction = (value: boolean) => {
    setRememberMe(value)
  }
  const onFormSubmit = async (value: any) => {
    setBusy(true);
    const params = {
      ...value,
      userType: 'admin'
    }
    dispatch(loginUser(params)).then((resultAction) => {
      if (loginUser.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            message: (resultAction as any)?.payload?.message,
            type: "success",
            time:2000
          })
        );
        window.localStorage.setItem(
          Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN,
          resultAction?.payload?.data?.accessToken
        );
        window.localStorage.setItem(
          Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN,
          resultAction?.payload?.data?.refreshToken
        );
        window.localStorage.setItem(
          "userRole",
          resultAction?.payload?.data?.role
        );
        window.sessionStorage.setItem(
          Constant.SESSIONSTORAGEKEYS.REMEMBER_ME,
          String(rememberMe)
        );
        setBusy(false);
        navigate("/admin/dashboard");
        if (rememberMe === true) {
          window.sessionStorage.setItem(
            Constant.SESSIONSTORAGEKEYS.TRIPTIX_REMEMBER_ME_EMAIL,
            value?.email
          );
          window.sessionStorage.setItem(
            Constant.SESSIONSTORAGEKEYS
              .TRIPTIX_REMEMBER_ME_PASSWORD,
            value?.password
          );
        } else {
          window.sessionStorage.setItem(
            Constant.SESSIONSTORAGEKEYS.TRIPTIX_REMEMBER_ME_EMAIL,
            ""
          );
          window.sessionStorage.setItem(
            Constant.SESSIONSTORAGEKEYS
              .TRIPTIX_REMEMBER_ME_PASSWORD,
            ""
          );
        }
      } else if (loginUser.rejected.match(resultAction)) {
        setBusy(false);
        setErrorMsg(t("INVALID_PASSWORD_LOGIN"))
      }
    });
  };

  const signInForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: t("EMAIL_ADDRESS_IS_REQUIRED"),
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: t("ENTER_VALID_EMAIL_ADDRESS"),
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: t("PASSWORD_IS_REQUIRED")
        },
        maxLength: {
          value: 256,
          message: t("MAXIMUM_CHARACTERS"),
        },
      },
    },
  };

  if (loading) return null


  return (
    <>
      <Helmet>
        <title>Admin Login | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Sign in to your Triptix Admin account or contact the site owner." />
      </Helmet>
      <SignInComponent
        signInForm={signInForm}
        onFormSubmit={onFormSubmit}
        busy={busy}
        rememberMe={rememberMe}
        rememberMeFunction={rememberMeFunction}
        setShowPassword={setShowPassword}
        showPassword={showPassword}
        setRememberMe={setRememberMe}
        errorMsg={errorMsg}
      />
    </>
  );
};

export default SignInContainer;
