import React from "react";
import { t } from "i18next";
import moment from "moment";
import { Link } from "react-router-dom";

interface EventsCardData {
  _id ?: Number;
  tripStartArea ?: string;
  tripEndArea ?: string;
  date ?: string;
  ticketPrice ?: string;
  departingAirport?:string
  destinationAirport?:string
  departureDate?:string;
  returnDate?:string;
  link?:string;
  flightPrice?:number
}

interface EventsCardDataProps {
  eventDetails: EventsCardData;
  classStyle?:string
}

const EventsCard: React.FC<EventsCardDataProps> = (props) => {
  const { eventDetails,classStyle } = props;
  return (
    <>
      <div className={`matchCard ${ classStyle}`} id='eventCard' onClick={()=> window.open(eventDetails?.link || "","_blank")}>
        <div className="subContainer">
        <div >
          <h1 className="matchTitle">{eventDetails?.departingAirport}</h1>
          <h2 className="matchSubTitle">{t('AIRPORT')}</h2>   
          </div>
          <div className="subContainer" id='subRightMain'>
        <div className="eventLeftContainer">
        {moment(eventDetails?.departureDate).format('DD MMM').toUpperCase()}
        </div>
        <div className="eventSubLeft">
        <p><span className="icon-aeroplane"></span>{moment(eventDetails?.departureDate).format('D MMM').toUpperCase()}</p>
        <p><span className="icon-aeroplane"></span>{moment(eventDetails?.returnDate).format('D MMM').toUpperCase()}</p>
        </div>
        </div>
        </div>
        <div className="matchBody">
          <div className="leftSubContainer">  <Link to={eventDetails?.link || ""} className="leftSubContainer" target='_blank'>{eventDetails?.departingAirport}<span className="icon icon-aeroplane"></span> {eventDetails?.destinationAirport} <span className="icon icon-link"></span></Link></div>
          <div className="matchRightContainer">
             {eventDetails?.flightPrice &&<span>£</span>}
            <div>{eventDetails?.flightPrice || "TBC" }</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsCard;