import { CHANGE_PASSWORD,RESET_PASSWORD } from "../../../api/endPoint";
import instance from "../../../helpers/axios";


interface changePasswordParams{
  password:string;
  confirmPassword?:string;
}


const changePassword =(data:changePasswordParams)=>{
return instance.put(CHANGE_PASSWORD,data)
}

const resetPassword =(data:changePasswordParams)=>{
  return instance.put(RESET_PASSWORD,data)
}



const settingService = {
  changePassword,
  resetPassword
}

export default settingService;