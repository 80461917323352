import React, { useState } from "react";
import CustomButton from "../../../../components/customButton/customButton";
import TextField from "../../../../components/textField/textField";
import AuthHeader from "../../../../components/authHeader/authHeader";
import HookForm from "../../../../components/hookForm/hookForm";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useWindowDimension from "../../../../components/hooks/useWindowDimension";

interface ChangePasswordProp {
  signInForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  multiErrorFields: any;
  loading: boolean;
  logoutFunction: () => void;
}

const ChangePasswordComponent: React.FC<ChangePasswordProp> = (props) => {
  const {
    signInForm,
    onFormSubmit,
    multiErrorFields,
    loading,
    logoutFunction,
  } = props;
  const { t } = useTranslation();
  const dimensions = useWindowDimension();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  return (
    <>
      <React.Fragment>
        <div className="authPages">
          <div className="authCenter">
            <div className="whiteBack">
              <AuthHeader headerTitle={t("CHANGE_YOUR_PASSWORD")} />
              <div className="form">
                <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                  {(formMethod) => {
                    return (
                      <>
                        <div className="textField">
                          <TextField
                            placeholder={t("NEW_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={signInForm.password.validate}
                            name={signInForm.password.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            multiErrorFields={multiErrorFields}
                            showPassword={showPassword}
                            togglePasswordVisibility={() =>
                              setShowPassword((prev) => !prev)
                            }
                            onChange={(e) => {
                              formMethod?.watch("password") &&
                                formMethod.trigger("password");
                              // Trigger confirm password validation when password changes
                              if (formMethod?.watch("confirmPassword")) {
                                formMethod?.trigger("confirmPassword");
                              }
                            }}
                          />
                          <TextField
                            placeholder={t("CONFIRM_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={{
                              required: {
                                value: true,
                                message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
                              },
                              validate: {
                                matchPassword: (value: string) =>
                                  value === formMethod?.watch("password") ||
                                  t("PASSWORDS_DON’T_MATCH"),
                              },
                            }}
                            name={signInForm.confirmpassword.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            showPassword={confirmShowPassword}
                            togglePasswordVisibility={() =>
                              setConfirmShowPassword((prev) => !prev)
                            }
                            onChange={(value) => {
                              formMethod?.watch("confirmPassword") &&
                                formMethod.trigger("confirmPassword");
                            }}
                          />
                        </div>
                        <CustomButton
                          className="btnAuth text-uppercase"
                          title={t("CHANGE_PASSWORD")}
                          disabled={!formMethod?.formState.isValid}
                          loading={loading}
                        />
                      </>
                    );
                  }}
                </HookForm>
                {dimensions?.width < 1025 && (
                  <Button
                    className="logOut-inner flex-row mt-3"
                    onClick={() => logoutFunction()}
                  >
                    <span className="icon icon-logOut"></span>
                    {t("LOG_OUT")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ChangePasswordComponent;
