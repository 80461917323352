import React, { useEffect, useState } from 'react';
import FileUploadManagementComponent from './fileUploadManagementComponent';
import { fileUploadEvent, init } from '../../../store/features/fileEvent/fileEventSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../interface/userInterface';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FileUploadManagementContainer:React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [rejectedFilesData, setRejectedFilesData] = useState<any>("")
  const [message,setMessage] = useState<string>("")

  const isLoading = useSelector((state:any)=> state?.event?.isLoading)
  const status = useSelector((state:any)=> state?.event?.status)



  const handleRedirect = ()=>{
    navigate("/admin/dashboard")
  }

  const handleFailedRedirect = ()=>{
    dispatch(init())
  } 
  
  const acceptedFileTypes = {
    'text/csv': [".csv"],
    'application/vnd.ms-excel': [".xls", ".xlsx"],
  };
  
  const handleDrop = (acceptedFiles: File[]): void => {
    setUploadedFiles(acceptedFiles);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    uploadedFiles.forEach(file => {
      formData.append('file', file);
    });
    dispatch(fileUploadEvent(formData)).then((res:any)=>{
      if (res?.error) {
        setMessage(res?.error?.message);
      }
    })
    setUploadedFiles([])
  }

  useEffect(()=>{
    dispatch(init())
  },[])

  return (
    <>
    <div>
    <Helmet>
        <title>Data Upload | Triptix Admin Portal</title>
        <meta name="description" content="Drag and drop or browse your computer to upload the latest platform data." />
      </Helmet>
      <FileUploadManagementComponent 
      handleDrop={handleDrop}
      uploadedFiles={uploadedFiles}
      acceptedFileTypes={acceptedFileTypes}
      handleSubmit={handleSubmit}
      handleRedirect={handleRedirect}
      handleFailedRedirect={handleFailedRedirect}
      status={status}
      isLoading={isLoading}
      rejectedFilesData={rejectedFilesData}
      setRejectedFilesData={setRejectedFilesData}
      message={message}
      />
    </div>
    </>
  )
}

export default FileUploadManagementContainer