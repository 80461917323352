import React from 'react'
import "./fileUploadManagement.css"
import FileUpload from '../../../components/fileUpload/fileUpload'
import { Link } from 'react-router-dom';
import { t } from 'i18next';


interface FileUploadManagementComponentProps {
  handleDrop: (acceptedFiles: File[]) => void;
  uploadedFiles: File[];
  acceptedFileTypes: { [key: string]: string[] };
  handleSubmit: () => void;
  handleRedirect: () => void;
  handleFailedRedirect: () => void;
  isLoading:boolean;
  status:string;
  rejectedFilesData:any;
  setRejectedFilesData:any;
  message:string
}


const FileUploadManagementComponent: React.FC<FileUploadManagementComponentProps> = ({ handleDrop, uploadedFiles, acceptedFileTypes, handleSubmit, handleRedirect, handleFailedRedirect,isLoading ,status,rejectedFilesData,setRejectedFilesData,message}) => {


  return (
    <>
      <div className='file-upload-container'>
        <div className='file-upload-container-header'>
          <FileUpload onDrop={handleDrop} onClick={true} uploadedFiles={uploadedFiles} accept={acceptedFileTypes} handleSubmit={handleSubmit} handleRedirect={handleRedirect}  handleFailedRedirect={handleFailedRedirect} isLoading={isLoading} status={status} message={message}
          rejectedFilesData={rejectedFilesData} setRejectedFilesData={setRejectedFilesData}
          />
         {((!isLoading && status === "") ) &&  <Link to="/sample_download.xlsx" target="_blank" download className='download-excel'> {t("DOWNLOAD")} <span>{t("TEMPLATE")}</span> </Link>}
         </div>
      </div>
    </>
  )
}


export default FileUploadManagementComponent