import React, { ReactNode, useState } from 'react';

interface option {
  label: string,
  value: string
}
interface DropdownProps {
  options: option[];
  selected?: string;
  onSelect: (selectedOption: option) => void;
  icon?: ReactNode;
  className?: string;
  placeholder?: string
  title?: string;
  styles?: string;
  extraClassName?: string | undefined;
}

const CustomDropdown: React.FC<DropdownProps> = ({ options, onSelect, icon, className = "", placeholder, title, styles ,extraClassName=""}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionClick = (option: option) => {
    setSelectedOption(option.label);
    setIsDropdownOpen(false);
    onSelect(option);
  };

  return (
    <div className={styles}>
      <div className={(className   +" " + extraClassName)} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {icon && !placeholder && <span className={`icon-${icon}`}></span>}
        {selectedOption || placeholder || title}
        {icon && placeholder && <span className={`icon-${icon}`}></span>}
      </div>
      {isDropdownOpen && (
        <ul className="dropdownList">
          {options.map((option, index) => (
            <>
            <li key={index} className={selectedOption === option.label ? "active" : ""} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
            </>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
