import React from "react";
import MatchCard from "../../../components/cards/dashboardCard/dashboardMatchCard";
import Loader from "../../../components/loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { EventList } from "../../../interface/eventInterface";

interface eventDetailsProps {
  handleNavigate: (id: number) => void;
  eventList: EventList;
  formattedDate: string;
  loading: boolean;
  dimensions: {height: number; width: number };
}
const EventsDetailsComponent: React.FC<eventDetailsProps> = ({
  dimensions,
  handleNavigate,
  eventList,
  formattedDate,
  loading,

}) => {
  const eventListLength = eventList ? eventList.length : 0;
  const navigate = useNavigate()
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="events-details-container">
            <div className="events-heading-section">
              <div className="back-button" onClick={() => navigate(-1)}>
                {" "}
                <span className="icon-arrow-left"></span> Back{" "}
              </div>
              <h1 className="dashboardTitle">{formattedDate?.toUpperCase()}</h1>
              <p className="dashboardDescription">
                {" "}
                <span className="icon-ticket"></span> {eventListLength}{" "}
                {eventListLength === 1 ? "EVENT" : "EVENTS"}{" "}
              </p>
            </div>
            <div className="calendar-section">
              <div className="title-bar">
                <h1 className="mb-0">
                  {eventListLength === 1 ? "EVENT" : "EVENTS"}
                </h1>
              </div>
              <div className="total-count">
                <h1>
                  {eventListLength}{" "}
                  {eventListLength === 1 ? "GAME FOUND" : "GAMES FOUND"}
                </h1>
              </div>
            </div>
            <div className="match-container">
              <div
                className={dimensions.width <= 490 ? "event-details-card" : ""}
                style={{
                  flexDirection: dimensions.width <= 490 ? "column" : "row",
                }}
              >
                {eventList && dimensions.width > 490 ? (
                  <Swiper
                    direction="horizontal"
                    slidesPerView={"auto"}
                    mousewheel={{releaseOnEdges:true}}
                    scrollbar={{ draggable: true }}
                    freeMode={true}
                    modules={[FreeMode, Mousewheel, Scrollbar]}
                    className="myswiper"
                  >
                    {eventList?.map((data: any, index: number) => (
                      <SwiperSlide key={index}>
                        <MatchCard
                          key={index}
                          matchCardData={data}
                          classStyle="mb-2"
                          handleNavigate={handleNavigate}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  eventList?.map((data: any, index: number) => (
                    <MatchCard
                      key={index}
                      matchCardData={data}
                      handleNavigate={handleNavigate}
                      classStyle="mb-2"
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventsDetailsComponent;
