import React from "react";
import { useTranslation } from "react-i18next";
import UserTable from "../../../components/userTable/userTable";
import TableHeader from "../../../components/userTable/tableHeader";


interface option {
  label: string,
  value: string
}

interface UserManagementProps {
  dimensions?: any;
  tableHeaderHeight?: any;
  handleCheckChange?: (id: string, status: string) => void;
  userTableData: {
    _id: string;
    name?: string;
    email?: string;
    subscriptionStatus?: string;
    subscriptionType?: string;
    renewalDate?: string;
    location?: string;
    teams?: string[];
  }[];
  filterModal?: boolean | undefined,
  onFilter?: () => void,
  locationDetails: {
    _id: string;
    name?: string;
    email?: string;
    subscriptionStatus?: string;
    subscriptionType?: string;
    renewalDate?: string;
    location?: string;
    teams?: string;
  }[];
  handleFilter: (option: any) => void;
  typeFilter: (option: String) => void;
  locationFilter: (option: any) => void;
  handleSearch?: (query: string) => void;
  handleSort?: (option: option) => void;
  filtersDetails: any;
  setDateRange: any;
  startDate: any;
  endDate: any;
  placeHolder?: string;
  clearDate: () => void;
  handleFilterOption: () => void;
  busy: boolean;
  openUserDropdown: string | null,
  setOpenUserDropdown: any,
  searchQuery: string | undefined,
  setSearchQuery: any,
  handleModal: (data: any) => void,
  showModal: boolean,
  setShowModal: any,
  userName: any,
  happendModalStatus: any,
  dateRange: any,
  dateClass: string,
  matchCardDivRef: any,
  scrollCount: number,
  handleScroll: any
}

const UserManagementComponent: React.FC<UserManagementProps> = ({ dimensions, handleCheckChange, userTableData, tableHeaderHeight, onFilter, filterModal, locationDetails, handleSearch, handleSort, typeFilter, locationFilter, handleFilter, filtersDetails, setDateRange, startDate, endDate, placeHolder, clearDate, handleFilterOption, busy, openUserDropdown, setOpenUserDropdown, setSearchQuery, searchQuery, handleModal, showModal, setShowModal, userName, happendModalStatus, dateRange, dateClass, matchCardDivRef, scrollCount, handleScroll }) => {
  const userStyle = {
    height: dimensions.height - 1
  };
  const userTableStyle = {
    height: dimensions.height - tableHeaderHeight - 200
  }

  const { t } = useTranslation();


  return (
    <React.Fragment>
      <div className="userContainer" style={userStyle}>
        <div className="headerContainer" style={{ marginBottom: "24px" }}>
          <h1>{t("USERS")}</h1>
          <TableHeader search={filtersDetails.search} onSearch={handleSearch} selectedSortOption={filtersDetails.sortLabel} onSortBy={handleSort} onFilter={onFilter} filterModal={filterModal} handleFilter={handleFilter} typeFilter={typeFilter} userTableData={userTableData} locationFilter={locationFilter} locationDetails={locationDetails} endDate={endDate} setDateRange={setDateRange} handleFilterOption={handleFilterOption} startDate={startDate} placeHolder={placeHolder} clearDate={clearDate} openUserDropdown={openUserDropdown}
            setOpenUserDropdown={setOpenUserDropdown} searchQuery={searchQuery}
            setSearchQuery={setSearchQuery} dateRange={dateRange}
            extraClassName="extra-class" dateClass={dateClass} />
        </div>
        <UserTable
          userTableData={userTableData}
          style={userTableStyle}
          handleCheckChange={handleCheckChange}
          busy={busy}
          handleModal={handleModal}
          showModal={showModal}
          setShowModal={setShowModal}
          userName={userName}
          happendModalStatus={happendModalStatus}
          matchCardDivRef={matchCardDivRef}
          scrollCount={scrollCount}
          handleScroll={handleScroll}
        />
      </div>
    </React.Fragment>
  );
};

export default UserManagementComponent;
