import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import Constant from '../../../../utils/constant';
import ChangePasswordComponent from './changePasswordComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../interface/userInterface';
import { changePassword } from '../../../../store/features/setting/settingSlice';
import { showToast } from '../../../../store/features/toast/toastSlice';
import { useNavigate } from 'react-router';
import { logout, setLoginResponse, setSocialAccount } from '../../../../store/features/auth/authSlice';
import { setUserFilters } from '../../../../store/features/user/userSlice';

const ChangePasswordContainer:React.FC = () => {
  const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()
  const filters = useSelector((state: any) => state?.user?.userFilters)
	const [loading,setLoading] = useState(false)

  const logoutFunction = ()=>{
    localStorage.clear()
    dispatch(logout(null))
		const filterData = {
			...filters,
			filters: {},
		}
		 dispatch(setUserFilters(filterData))
		 dispatch(setLoginResponse(null))
		 dispatch(setSocialAccount(false))
    navigate("/admin/login")
}

	const onFormSubmit = async (value: any) => {
		setLoading(true)  
		dispatch(changePassword(value)).then((resultAction)=>{
			if (changePassword.fulfilled.match(resultAction)) {
				dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "success",
					})
				);
				navigate("/admin/login")
				setLoading(false);
			}
			else{
				dispatch(
          showToast({
            message: (resultAction as any)?.payload?.message,
            type: "error",
          })
        );
				setLoading(false);
			}
		})
  }

  const resetForm = {
		password: {
			name: "password",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_ENTER_PASSWORD"),
				},
				validate: {
					hasLowerCase_UpperCase: (value: string) =>
						value &&
						value.match(Constant.REGEX.LOWERCASE) &&
						value.match(Constant.REGEX.UPPERCASE) !== null,
					hasNumbers: (value: string) =>
						value && value.match(Constant.REGEX.NUMBER) !== null,
					hasSpecialChar: (value: string) =>
						value &&
						value.match(Constant.REGEX.SPECIAL_CHARECTERS) !== null,
					length: (value: string) =>
						(value && value.length >= 8 ) ||
						"",
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
		confirmpassword: {
			name: "confirmPassword",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
	};

  const multiErrorFields = [
		{ length: t("MINIMUM_8_CHARACTERS") },
		{ hasSpecialChar: t("MINIMUM_1_SPECIAL") },
		{ hasNumbers: t("MINIMUM_1_NUMERIC") },
		{ hasLowerCase_UpperCase: t("MINIMUM_1_UPPERCASE_LOWERCASE") },
	];
  return (
    <>
       <Helmet>
        <title>Settings | Triptix Admin Portal</title>
        <meta name="description" content="Update your password and view other account-based settings." />
      </Helmet>
      <ChangePasswordComponent
      signInForm={resetForm}
      onFormSubmit={onFormSubmit}
      multiErrorFields={multiErrorFields}
			loading={loading}
			logoutFunction={logoutFunction}
      />
    </>
  )
}

export default ChangePasswordContainer