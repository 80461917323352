import React from "react";
import Logo from "../../assets/images/logoWithText.svg";
import ProfileImage from '../../assets/images/profileImage.svg'
import CustomButton from "../customButton/customButton";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
interface SubscriptionDetails {
  status: boolean;
  content: string;
}

interface AuthHeaderProps {
  manageSubscription?: Boolean;
  headerTitle?: string;
  headerSubText?: string;
  headerDescription?: string;
  headerLogo?: React.ReactNode;
  profile?: Boolean;
  onCameraClick?: () => void;
  selectedFile?: string | null;
  userSettings?: boolean;
  socialLogin?: boolean;
  purchaseCardData?: {
    _id: number;
    subscriptionType: string;
    amount: string;
    subscriptionPlan?: string;
    description: string;
    subscriptionDetails: SubscriptionDetails[];
    plan: string;
  }[];
  setPlan?: any;
  plan?: string;
  subscribeResponse?: any
  setModalShow?: any
  planModel?: number
  isImported?:boolean
}

const AuthHeader: React.FC<AuthHeaderProps> = (props) => {
  const { headerTitle, profile, onCameraClick, selectedFile, userSettings, setModalShow, planModel } = props;

  const { t } = useTranslation();
  return (
    <>
      <div className="authHeaderBack">
        {profile ? (
          <div className="profileImgContainer">
            <img src={selectedFile || ProfileImage} alt="profile img" title="profile img" />
            <div className="profileIconBack" onClick={onCameraClick}><span className="icon-camera"></span></div>
          </div>
        ) : (
          <img src={Logo} alt="Logo with text" title="Logo with text" />
        )}
      </div>
      { userSettings && (
        <>
          <div className="mb-5">
            <div className="headerTitle">{t("MANAGE_YOUR_SUBSCRIPTION")}</div>
            <CustomButton
              className="btnAuth text-uppercase w-100"
              title={t("JOIN_TRIPTIX")}
              loading={false}
              onClick={() => setModalShow(true)}
              disabled={planModel === 3}
              disabledExtraClass="w-100"
            />
            <Button className="bg-transparent border-0 text-center w-100 mt-4 button-update"><Link to='https://billing.stripe.com/p/login/9AQ4hg5t31yC1q07ss' target="blank">{t("MANAGE_ACCOUNT")}</Link></Button> <br />
          </div>
        </>
      )}
      {headerTitle && <span className="headerTitle">{headerTitle}</span>}
    </>
  );
};

export default AuthHeader;
