import { useEffect, useState } from "react";

type WindowDimensions = {
  height: number;
  width: number;
};

export default function useWindowDimension() {
  const [dimensions, setDimensions] = useState<WindowDimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return dimensions;
}
