import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../store/features/toast/toastSlice";
import Constant from "../../../utils/constant";
import ForgotPasswordComponent from "./forgotPasswordComponent";
import { AppDispatch } from "../../../interface/authInterface";
import { forgotPassword } from "../../../store/features/auth/authSlice";

const ForgotPasswordContainer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)

	/**
 * Redirects the user based on authentication response and token.
 * If token exists and the user is authenticated, onboarded, and has the role of "user", redirects to the dashboard.
 * If token exists and the user is authenticated but not onboarded and has the role of "user", redirects to the onboarding page.
 * Otherwise, stops loading.
 */
	useEffect(() => {
		if (token && authResponse?.data?.accessToken && authResponse?.data?.role === "user" && authResponse?.data?.isOnBoarded) {
				navigate("/dashboard")
		} else if (token && authResponse?.data?.role === "user" && authResponse?.data?.isOtpVerified && !authResponse?.data?.isOnBoarded) {
			navigate("/onboarding")
		}
		else {
			setLoading(false)
		}
	}, [authResponse])

	const onFormSubmit = async (value: any) => {
		setBusy(true);
		const emailValue = {
			...value,
			email: value.email.toLowerCase(),
		}
		dispatch(forgotPassword(emailValue)).then((resultAction) => {
			if (forgotPassword.fulfilled.match(resultAction)) {
				dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "success",
					})
				);
				setBusy(false);
				navigate("/login");
			} else if (forgotPassword.rejected.match(resultAction)) {
				setBusy(false);
				dispatch(
					showToast({
						message: resultAction?.error?.message,
						type: "error",
					})
				);
			}
		});
	};

	const forgotForm = {
		email: {
			name: "email",
			validate: {
				required: {
					value: true,
					message: t("EMAIL_ADDRESS_IS_REQUIRED"),
				},
				pattern: {
					value: Constant.REGEX.EMAIL,
					message: t("ENTER_VALID_EMAIL_ADDRESS"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
	};

  if (loading) return null; 


	return (
		<>
			<Helmet>
				<title>Forgot Password | Triptix</title>
				<meta name='description' content='Forgotten your password? Reset it on this page to log in to your account.' />
			</Helmet>
			<ForgotPasswordComponent
				forgotForm={forgotForm}
				onFormSubmit={onFormSubmit}
				busy={busy}
			/>
		</>
	);
};

export default ForgotPasswordContainer;
