import React, { useEffect, useState } from "react";
import moment from 'moment';
import { t } from "i18next";
import { OnboardingCardData, featureEvents } from "../../../interface/onBoardingCardInterface";
import TextResize from "../../textResize/textResize";
import useWindowDimension from "../../hooks/useWindowDimension";


interface OnboardingCardDataProps {
  onboardingCardData?: OnboardingCardData;
  backStyle?: Boolean;
  classStyle?: string;
  index?: number;
  featureEvents?: featureEvents;
  handleNavigate?: any;
  enableBlur?: boolean
}
const colors: string[] = ['#FF003F', '#3D7CEC', '#70E78B', '#B672DF', '#EC7F50'];
const OnboardingCard: React.FC<OnboardingCardDataProps> = (props) => {
  const index = props.index || 0;
  const dimensions = useWindowDimension();
  const [onBoardCardWidth, setOnBoardCardWidth] = useState<number>(0);
  const { onboardingCardData, backStyle, featureEvents, handleNavigate, classStyle, enableBlur } = props;
  const getColorForCard = (index: number): string => {
    return colors[index % colors.length]; // Use modulus operator to repeat colors
  };
  useEffect(() => {

    const onBoardCardWidthElement = document.getElementsByClassName("onboardingCard")[0] as HTMLElement;
    if (onBoardCardWidthElement) {
      setOnBoardCardWidth(onBoardCardWidthElement.offsetWidth - 100); // Adjust for padding/margin if needed
    }
  }, [dimensions.width]);


  return (
    <>
      <div className={classStyle} style={backStyle ? { backgroundColor: getColorForCard(index) } : {}} onClick={() => handleNavigate && handleNavigate(featureEvents?.flightList[0]?._id)}>
        <div className={enableBlur ? "enableBlur cardHeader" : "cardHeader"}>
          <h1><TextResize text={(onboardingCardData?.team1?.replace(/_/g, ' ') ?? "") || (featureEvents?.homeTeam?.toUpperCase().replace(/_/g, ' ') ?? "")}></TextResize></h1>
          <div>{onboardingCardData?.date || (featureEvents?.fixtureDate && moment(featureEvents?.fixtureDate)?.format('D MMM').toUpperCase())}</div>
        </div>
        <div className={enableBlur ? "enableBlur cardSubHeader" : "cardSubHeader"}>
          <h6>
            <TextResize
              text={
                t("VS. ") +
                (
                  (onboardingCardData?.team2?.replace(/_/g, ' ') ?? "") ||
                  (featureEvents?.otherTeam?.toUpperCase()?.replace(/_/g, ' ') ?? "")
                )
              }
            />
          </h6>
          <span>{onboardingCardData?.league || featureEvents?.leagueName?.toUpperCase()}</span>
        </div>

        <img src={featureEvents?.featuredImage ? featureEvents.featuredImage : "https://triptixclient.blob.core.windows.net/triptix-image/triptixLogo.png"} alt="" loading="lazy" />
        <div className={enableBlur ? "enableBlur leftSubContainer" : "leftSubContainer"} style={backStyle ? { backgroundColor: getColorForCard(index) } : {}}>
          {onboardingCardData?.tripStartArea || featureEvents?.flightList[0]?.departingAirport?.toUpperCase()}
          <span className={enableBlur ? "enableBlur icon icon-aeroplane" : "icon icon-aeroplane"}></span>
          {onboardingCardData?.tripEndArea || featureEvents?.flightList[0]?.destinationAirport?.toUpperCase()}
        </div>
        <div className={enableBlur ? "disableBlur matchRightContainer" : "matchRightContainer"}>
          <span>£</span>
          {onboardingCardData?.amount || featureEvents?.flightList[0]?.totalPrice}
        </div>
      </div>
    </>
  );
};

export default OnboardingCard;
