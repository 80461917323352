import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useWindowDimension from '../hooks/useWindowDimension';


interface MultiSelectDropdownProps {
  options: option[] ;
  onChange: (selectedOptions: string[],category:string) => void;
  multiSelect?: boolean;
  selectOption?: string[];
  name:string ,
  clearFilter?:boolean;
  isOpen?:boolean
  setIsOpen?:any
  setOpenUserDropdown?:any,
  handleUserToggleDropdown?:any
}


interface option {
  label: string;
  value: string;
}


const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ options, onChange, multiSelect, selectOption ,name,clearFilter,isOpen,setIsOpen,setOpenUserDropdown,handleUserToggleDropdown}) => {

  const dropdownRef = useRef<HTMLDivElement>(null);
  const filters = useSelector((state: any) => state?.user?.userFilters)
  const userRole = localStorage.getItem('userRole')
  const userDashboardFilters = useSelector((state: any) => state?.userDashboard?.userFilters)
  const footballFilters = useSelector((state: any) => state?.footballEvent?.footballFilters)
  const eventFilters = useSelector((state: any) => state?.calenderEvent?.eventFilters)
  const dimensions = useWindowDimension();

  const location = useLocation()
  const [selectedOptions, setSelectedOptions] = useState<any>([]);


  useEffect(() => {
    // Initialize selectedOptions with values from filters
    const initialSelectedOptions: string[] = [];
    if (userRole === "admin" && filters && filters?.filters && Object?.keys(filters?.filters).length > 0) {
      if (filters?.filters?.location) {
        initialSelectedOptions.push(...filters.filters.location);
      }
    } else if (location?.pathname?.includes("/dashboard") &&  userDashboardFilters) {
      if (userDashboardFilters?.airportArrival) {
        initialSelectedOptions.push(...userDashboardFilters?.airportArrival);
      }
      if (userDashboardFilters?.airportDeparture) {
        initialSelectedOptions.push(...userDashboardFilters?.airportDeparture);
      }
      if (userDashboardFilters?.leagueName) {
        initialSelectedOptions.push(...userDashboardFilters?.leagueName);
      }
    }
    else if (location?.pathname?.includes("/football") && footballFilters) {
      if (footballFilters?.airportArrival) {
        initialSelectedOptions.push(...footballFilters?.airportArrival);
      }
      if (footballFilters?.airportDeparture) {
        initialSelectedOptions.push(...footballFilters?.airportDeparture);
      }
      if (footballFilters?.leagueName) {
        initialSelectedOptions.push(...footballFilters?.leagueName);
      }
    }else if (location?.pathname?.includes("/event") && eventFilters) {
      if (eventFilters?.airportArrival) {
        initialSelectedOptions.push(...eventFilters?.airportArrival);
      }
      if (eventFilters?.airportDeparture) {
        initialSelectedOptions.push(...eventFilters?.airportDeparture);
      }
    }
    // Create an array of objects where each object represents a category
    const categoryOptions = {
      airportArrival: (location?.pathname?.includes("/football") ? footballFilters?.airportArrival  : location?.pathname?.includes("/event") ?eventFilters?.airportArrival : userDashboardFilters?.airportArrival) || [],
      airportDeparture: (location?.pathname?.includes("/football") ? footballFilters?.airportDeparture :location?.pathname?.includes("/event") ?eventFilters?.airportDeparture: userDashboardFilters?.airportDeparture) || [],
      leagueName: (location?.pathname?.includes("/football") ? footballFilters?.leagueName:  userDashboardFilters?.leagueName) || [],
      location: filters?.filters?.location || [],
    };
    if (name === "teams") {
      setSelectedOptions(selectOption);      
    }else {
      setSelectedOptions(categoryOptions);
    }
  }, []);

  useEffect(()=>{
    const categoryOptions = {
      airportArrival: [],
      airportDeparture: [],
      leagueName:[],
      location:[],
    };
      if (clearFilter) {
        setSelectedOptions(categoryOptions)
      }
  },[clearFilter])

  const toggleOptionTeams = (option: string) => {
    if (multiSelect) {
      const isSelected = selectedOptions?.includes(option);
      const optionData = isSelected
        ? selectedOptions.filter((item:any) => item !== option)
        : [...selectedOptions, option]
      setSelectedOptions(optionData);
      onChange(optionData,"");
    } else {
      onChange(selectedOptions[0] === option ? [] : [option],"");
      setSelectedOptions(selectedOptions[0] === option ? [] : [option]);
    }
  };
  const toggleOption = (option: string, category: string) => {
    if (multiSelect) {
      const isSelected = selectedOptions[category]?.includes(option);
      const optionData = isSelected
        ? selectedOptions[category].filter((item: string) => item !== option)
        : [...selectedOptions[category], option];
      const updatedOptions = { ...selectedOptions, [category]: optionData };
      setSelectedOptions(updatedOptions);
      onChange(optionData, category);
    } else {
      onChange(selectedOptions[0] === option ? [] : [option],"");
          setSelectedOptions(selectedOptions[0] === option ? [] : [option]);
    }
  };
  

  const handleCheckboxChange = (value: string, category: string) => {
    if (category === "teams") {
      toggleOptionTeams(value);
    }else {
      toggleOption(value, category);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenUserDropdown(null)
        setIsOpen(false)
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className='dropdown-option' >
       {/* {isOpen && <> */}
      {options?.map((option) => (
        <>
  <label key={option?.value} className={(selectedOptions[name]?.includes(option?.value) || filters?.filters?.status === option?.value || filters?.filters?.plan === option?.value || filters?.filters?.location?.includes(option?.value)  || (selectOption ? selectOption?.includes(option?.value) : false)) ? "active multipleOptionActive":  "multipleOptionActive" }>
            <input
              type="checkbox"
              value={option?.value}
              checked={(selectedOptions[name]?.includes(option?.value) || filters?.filters?.status === option?.value || filters?.filters?.plan === option?.value || filters?.filters?.location?.includes(option?.value)  || (selectOption ? selectOption?.includes(option?.value) : false))}
              onChange={() => handleCheckboxChange(option?.value, name)}
              className='me-2'
              />
            <span className= {`${dimensions?.width <1024}` ? 'option-value' :""}>{option?.label}</span>
          </label>
        </>
              
      ))}
    </div>
  );
};


export default MultiSelectDropdown;