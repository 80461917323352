import { createSlice } from "@reduxjs/toolkit";

export interface ToastState {
	show: boolean;
	message: string;
	type: string;
	time: number | string
}

const initialState: ToastState = {
	show: false,
	message: "",
	type: "success",
	time:""
};

const toastSlice = createSlice({
	name: "toast",
	initialState,
	reducers: {
		showToast: (state, action) => {
			state.show = action.payload.message === "Token expired. Please authenticate again!" ? false :true;
			state.message = action.payload.message;
			state.type = action.payload.type;
			state.time = action.payload.time
		},
		hideToast: (state) => {
			state.show = false;
			state.message = "";
		},
	},
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
