import  { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import OTPVerificationComponent from "./otpVerificationComponent";
import { useSelector } from "react-redux";

const OTPVerificationContainer = () => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [digitValue, setDigitValue] = useState("");
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)

	useEffect(() => {
		if (token && authResponse?.data?.accessToken && authResponse?.data?.role === "admin") {
				navigate("/admin/dashboard")
		}else {
			setLoading(false)
		}
	}, [authResponse])

  const onOtpChange = (value: string) => {
    if (value.length < digitValue.length) {
      setDigitValue(value);
    } else {
      if (digitValue.length === value.length - 1) {
        setDigitValue(value);
      }
    }
  };

  const handleResendClick = () => {
    setTimer(30);
  };

  const otpForm = {
    otp: {
      name: "otp",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  useEffect(() => {
    if (timer > 0) {
      const timerID = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearTimeout(timerID);
      };
    }
  }, [timer]);

  const onFormSubmit = async (value: any) => {
    setBusy(true);
    setTimeout(() => {
      setBusy(false);
      navigate('/admin/resetpassword')
    }, 3000);
  };

  if (loading) return null; 

  return (
    <>
      <Helmet>
        <title>OTP Verification | TRIPTIX</title>
        <meta name="description" content="OTP Verification | TRIPTIX" />
      </Helmet>
      <OTPVerificationComponent
        busy={busy}
        onFormSubmit={onFormSubmit}
        digitValue={digitValue}
        onOtpChange={onOtpChange}
        timer={timer}
        handleResendClick={handleResendClick}
        otpForm={otpForm}
      />
    </>
  );
};

export default OTPVerificationContainer;
