import React from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./adminSidebar";

interface AdminLayoutProps {}

function AdminLayout(props: AdminLayoutProps) {
  
  return (
    <React.Fragment>
      <div className="wrapper">
        <AdminSidebar />
        <div className='main-content'>
          <Outlet />
        </div>
         </div>
    </React.Fragment>
  );
}
export default AdminLayout;
