import React from "react";
import PurchaseCard from "../../../components/cards/onboardingCard/purchaseCard";

interface SubscriptionDetails {
  status: boolean;
  content: string;
}

interface PurchaseComponentProps {
  purchaseCardData: {
    _id: number;
    subscriptionType: string;
    amount: string;
    description: string;
    subscriptionDetails: SubscriptionDetails[];
    plan:string;
  }[];
  setPlan?:any
  plan?:string
}

const PurchaseComponent: React.FC<PurchaseComponentProps> = ({ purchaseCardData,setPlan,plan }) => {

  return (
    <React.Fragment>
      <div className="purchaseContainer">
          {purchaseCardData.map((data, index) => (
            <PurchaseCard key={index} purchaseCardData={data} setPlan={setPlan} plan={plan}/>
          ))}
      </div>
    </React.Fragment>
  );
};

export default PurchaseComponent;
