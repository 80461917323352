import React, { FC, ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";

interface TextFieldProps {
  formMethod?: any;
  errors?: any;
  value?: any;
  placeholder: string;
  type: string;
  showPassword?: boolean;
  togglePasswordVisibility?: () => void;
  textarea?: any;
  name: string;
  autoFocus?: boolean;
  multiErrorFields?: any;
  disabled?: boolean;
  handleFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur?: () => void;
  maxLength?: number;
  minLength?: number;
  onKeyDown?: (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  rules: Record<string, any>;
  rows?: number;
  defaultValue?: string;
  readOnly?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  onClick?: () => void;
  ref?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  icon?: string;
  auth?:boolean;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    auth,
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    maxLength,
    minLength,
    onKeyDown,
    formMethod,
    rows,
    defaultValue,
    rules,
    readOnly,
    required,
    min,
    max,
    onClick,
    // onChange,
    showPassword,
    togglePasswordVisibility,
    icon,
  } = props;
  const hasError = errors[name] !== undefined;
  return (
    <div className="formGroup">
      <Controller
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <>
            <div className={type === "password" ? "inline" : ""}>
              <Form.Control
                name={name}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                autoComplete="off"
                required={required}
                type={type === "password" && showPassword ? "text" : type}
                readOnly={readOnly}
                onBlur={handleBlur}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
                minLength={minLength}
                min={min}
                max={max}
                onFocus={(e) => {
                  handleFocus && handleFocus(e);
                }}
                defaultValue={defaultValue}
                onChange={(e) => {
                  onChange && onChange(auth?(e.target.value.trim()):(e.target.value));
                  props.onChange && props.onChange(e);
                }}
                onClick={onClick}
                value={formMethod.watch(name) || value ? value : ""}
                as={textarea}
                rows={rows}
              />
              {icon && (
                <div className="inputIcon">
                  <div className={`icon-${icon}`}></div>
                </div>
              )}

              {type === "password" && (
                <div
                  onClick={togglePasswordVisibility}
                  className={`togglePasswordButton ${
                    !value ? "disabled" : ""
                  } ${hasError ? "invalid" : ""}`}
                >
                  {showPassword ? (
                    <div className="icon-show"></div>
                  ) : (
                    <div className="icon-hide"></div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        name={name}
        control={formMethod?.control}
        rules={rules}
      />
      {multiErrorFields?.length > 0 ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) => {
            if (messages) {
              const isMultipleError = Object.keys(messages).every(
                (errKey) =>
                  multiErrorFields.filter(
                    (m: { [x: string]: undefined }) => m[errKey] !== undefined
                  ).length > 0
              );
              if (isMultipleError) {
                const arr: JSX.Element[] = [];
                for (const fieldError of multiErrorFields) {
                  const key = Object.keys(fieldError)[0];
                  const success = Object.keys(messages).includes(key);
                  arr.push(
                    <span className={success ? "red" : "green"} key={key}>
                      {success ? (
                        <span className="icon icon-info"></span>
                      ) : (
                        <span className="icon icon-checkmark"></span>
                      )}

                      {fieldError[key]}
                    </span>
                  );
                }
                return (
                  <div className="errorMsg 2 show passwordcustom">{arr}</div>
                );
              } else {
                return (
                  <div className="errorMsg 1 show">
                    {errors[name]?.message && (
                      <div className="infoIcon">
                      <span className="icon icon-info"></span>
                      </div>
                    )}
                    {errors[name]?.message}
                  </div>
                );
              }
            } else {
              return <div className="errorMsg 3"></div>;
            }
          }}
        />
      ) : hasError ? (
        <div className="errorMsg 4 show">
          {errors[name]?.message && (
            <div className="infoIcon">
              <span className="icon icon-info"></span>
            </div>
          )}
          <span>{errors[name]?.message}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

TextField.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
  readOnly: false,
  required: true,
};

export default TextField;
