import { FORGOT, LOGIN, RESEND_OTP, RESET, SIGNUP, SOCIAL_LOGIN, VERIFY_OTP, SUBSCRIBE, CREATE_ACCOUNT,UPDATE_PLAN ,UPDATE_PROFILE,GET_USER_DETAILS} from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import {
	forgotParams,
	loginParams,
	resetParams,
	signUpParams,
	socialLoginParams,
	subscribeParams,
	createAccountParams
} from "../../../interface/authInterface";

/**
 * Logs in a user with the provided login parameters.
 * @param {loginParams} data - The login parameters containing the user's email and password.
 * @returns A Promise that resolves to the result of the login request.
 */
const login = (data: loginParams) => {
	return instance.post(LOGIN, {
		userType: data?.userType,
		email: data?.email,
		password: data?.password,
	});
};

const subscribe = (data: subscribeParams) => {
	const { userId, plan,sessionId } = data;
	return instance.put(SUBSCRIBE, {
		userId: userId,
		plan: plan,
		sessionId
	})
}

const createAccount = (data: createAccountParams) => {
	const { userId, fullName, location, teams ,profileImage} = data;
	return instance.put(CREATE_ACCOUNT, {
		userId: userId,
		fullName: fullName,
		location: location,
		teams: teams,
		profileImage:profileImage
	})
}

const updateProfile =(data:createAccountParams) =>{
	const { userId, fullName, location, teams } = data;
	return instance.put(UPDATE_PROFILE, {
		userId: userId,
		fullName: fullName,
		location: location,
		teams: teams,
	})
}


const socialLogin = (data: socialLoginParams) => {
	return instance.post(SOCIAL_LOGIN, data);
};

/**
 * Sends a POST request to the server to initiate the password reset process for a user.
 * @param {forgotParams} data - The data object containing the user's email.
 * @returns A Promise that resolves to the response from the server.
 */
const forgot = (data: forgotParams) => {
	return instance.post(FORGOT, {
		email: data?.email || data,
	});
};

/**
 * Sends a POST request to the server to reset the user's password.
 * @param {resetParams} data - The reset parameters including the reset token and the new password.
 * @returns A Promise that resolves to the response from the server.
 */
const reset = (data: resetParams) => {
	return instance.post(RESET, {
		resetToken: data.resetToken,
		password: data?.password,
	});
};

const signUp = (data: signUpParams) => {
	return instance.post(SIGNUP, data);
};

const resendOtp = () => {
	return instance.post(RESEND_OTP);
};

const verifyOTP = (queryParams:any) => {
	return instance.put(VERIFY_OTP,queryParams);
};

const updateSubscriptionPlan =(planData:any)=>{
  return instance.put(UPDATE_PLAN,planData)
}

const getUserDetails = (data:any) => {
	const {sessionId,userId }= data
	return instance.get(GET_USER_DETAILS,{params:{sessionId,userId}})
}

const authServices = {
	login,
	subscribe,
	createAccount,
	socialLogin,
	forgot,
	reset,
	signUp,
	resendOtp,
	verifyOTP,
	updateSubscriptionPlan,
	updateProfile,
	getUserDetails
};

export default authServices;
