import  { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import DashboardComponent from "./dashboardComponent";
import dashboardCardData from "../../../utils/dashboardCardData.json";
import matchCardData from "../../../utils/matchesCardData.json";
import { dashboardDetailsList ,paginationFunction} from "../../../store/features/adminDashboard/adminDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../interface/userInterface";
import { useNavigate } from "react-router-dom";


const DashboardContainer = () => {
  const navigate = useNavigate()
  const [loading,setLoading]= useState(true)
  const dispatch = useDispatch<AppDispatch>()
  const dashboardDetails = useSelector((state:any)=> state?.adminDashboard?.dashboardListDetails)
  const userCountList = useSelector((state:any)=> state?.adminDashboard?.userCount)
  const totalEvents = useSelector((state:any)=> state?.adminDashboard?.totalEvents)
  const authResponse = useSelector((state:any)=> state?.auth?.loginResponse)
  const matchCardDivRef = useRef<HTMLDivElement>(null);
  const [scrollCount ,setScrollCount] = useState<number>(1)
  const isDataRef = useRef<boolean>(false)

  useEffect(()=>{
    setLoading(true)
    const queryParams: any = {
      page: scrollCount,
      limit: 20,
    };
    dispatch(paginationFunction(queryParams))
    dispatch(dashboardDetailsList(queryParams)).then((res)=>{
      if (dashboardDetailsList.fulfilled.match(res)) {
         if(scrollCount * 20 <= totalEvents){
          isDataRef.current = true
         }else {
          isDataRef.current = false
         }
      }
      setLoading(false)
    })
  },[scrollCount])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCount]);

  const handleScroll = () => {
    if (
      (matchCardDivRef.current &&
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight && isDataRef.current && scrollCount * 20 <= totalEvents)
    ) {
      setScrollCount(scrollCount + 1);
    }
  };

  const handleNavigate = (id: number) => {
    navigate(`/admin/dashboard/events/details/${id}`)
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Triptix Admin Portal</title>
        <meta name="description" content="View the latest membership stats at a glance and browse upcoming fixtures." />
      </Helmet>
      <DashboardComponent
        dashboardCardData={dashboardCardData.dashboardCardData}
        matchCardData={matchCardData.matchCardData}
        dashboardDetails={dashboardDetails}
        loading={loading}
        authResponse={authResponse}
        matchCardDivRef={matchCardDivRef}
        userCountList={userCountList}
        handleNavigate={handleNavigate}
        scrollCount={scrollCount}
      />
    </>
  );
};

export default DashboardContainer;
