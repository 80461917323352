import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
// import { AxiosResponse } from "axios";
import fileEventServices from "./fileEventService";

export const fileUploadEvent = createAsyncThunk("/event/uploadData",async (data:any)=>{
  const res = await fileEventServices.fileUpload(data);
  return res
})


export interface fileEventState {
	// listUserResponse: AxiosResponse<string, listuserData>;
	isLoading: boolean;
  status:  String;
}

const fileEventSlice = createSlice({
  name: "fileEvent",
  initialState: {
    isLoading:false,
    status:"",
  } as fileEventState,
  reducers: {
    init: (state) => {
      state.status = "";
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fileUploadEvent.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
     })
    .addCase(fileUploadEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status ="success";

      })
    .addCase(fileUploadEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
      });
  },
})

export  const {init} = fileEventSlice.actions;
export default fileEventSlice.reducer;