import React from "react";
import { useTranslation } from "react-i18next";
import TableHeader from "../../components/userTable/tableHeader";
import MatchCard from "../../components/cards/dashboardCard/dashboardMatchCard";
import OnboardingCard from "../../components/cards/onboardingCard/onboardingCard";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../../components/customButton/customButton";
import PurchaseCard from "../../components/cards/onboardingCard/purchaseCard";
import Loader from "../../components/loader/Loader";
import onboardingCardData from "../../utils/onboardingCardData.json";
import StickyBox from "react-sticky-box";
import { getNameUpToLastSpace } from "../../utils/firstName";
interface SubscriptionDetails {
  status: boolean;
  content: string;
}
interface DashboardProps {
  onboardingCardData: {
    _id?: Number;
    tripStartArea?: string;
    tripEndArea?: string;
    date?: string;
    team1?: string;
    team2?: string;
    amount?: string;
    imageUri?: string;
  }[];
  purchaseCardData: {
    _id: number;
    subscriptionType: string;
    amount: string;
    description: string;
    subscriptionDetails: SubscriptionDetails[];
    plan: string;
  }[];
  onboardingContainerHeight?: any;
  matchCardData: {
    _id: number;
    tripStartArea: string;
    tripEndArea: string;
    date: string;
    team1: string;
    team2: string;
    amount: string;
  }[];
  setPlan?: any;
  plan?: string;
  filterModal?: boolean | undefined;
  handleFilter: (option: any) => void;
  locationFilter: (option: any) => void;
  typeFilter: (option: any) => void;
  onFilter?: () => void;
  startDate: any;
  handleNavigate: (id: number) => void;
  dimensions: any;
  show?: boolean;
  setShow?: any;
  modalShow?: boolean;
  handlePlan: any,
  setModalShow?: any;
  handleShow?: () => void;
  dashboardTitleHeight: any;
  dashboardDescriptionHeight?: any;
  leagueOption?: any;
  airportOption?: any;
  dashboardDetails?: any;
  handleSearch?: (query: string) => void;
  handleRange?: any,
  range?: any;
  setStartDate?: any,
  handleClose: () => void;
  userDashboard: boolean;
  authResponse: any;
  refresh: string;
  showFilterResult: boolean;
  removeDate: () => void;
  busy: boolean;
  subscribeResponse: any,
  filters: any,
  searchResult: boolean,
  maxTotalPriceObject: number,
  user: boolean,
  numberOfKeysWithValues: number,
  refreshClick: () => void,
  handleRemoveArrival: any
  handleRemoveLeague: any
  handleRemoveDeparture: any
  handleRemovePrice: any
  handleRemoveDate: any,
  clearFilter: boolean,
  dashboard: boolean,
  searchQuery: string,
  setSearchQuery: (Search: string) => void,
  openUserDropdown: string | null,
  setOpenUserDropdown: any,
  airportDepartureOption: string[],
  dateClass: string,
  createAccountResponse: any,
  planModel: any,
  minTotalPriceObject: any
  matchCardDivRef: React.RefObject<HTMLDivElement>;
  scrollCount: number,
  featuredEventsList: any,
  userInfo:any,
  SelectPlan:any
}

const DashboardComponent: React.FC<DashboardProps> = ({
  filterModal,
  matchCardData,
  modalShow,
  handlePlan,
  setModalShow,
  onFilter,
  handleFilter,
  locationFilter,
  typeFilter,
  startDate,
  purchaseCardData,
  setPlan,
  plan,
  handleNavigate,
  dimensions,
  show,
  setShow,
  handleShow,
  leagueOption,
  airportOption,
  dashboardDetails,
  handleSearch,
  handleRange,
  range,
  setStartDate,
  handleClose,
  userDashboard,
  authResponse,
  refresh,
  showFilterResult,
  removeDate,
  busy,
  subscribeResponse,
  filters,
  searchResult,
  maxTotalPriceObject,
  user,
  numberOfKeysWithValues,
  refreshClick,
  handleRemoveArrival,
  handleRemoveLeague,
  handleRemoveDeparture,
  handleRemovePrice,
  handleRemoveDate,
  clearFilter,
  dashboard,
  searchQuery,
  setSearchQuery,
  openUserDropdown,
  setOpenUserDropdown,
  airportDepartureOption,
  dateClass,
  createAccountResponse,
  planModel,
  minTotalPriceObject,
  matchCardDivRef,
  featuredEventsList,
  scrollCount,
  userInfo,
  SelectPlan
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="dashboardContainer user-dashboard">
        <div className="subContent">
          <h1 className="dashboardTitle">{t("WELCOME_JOE")} <span>{getNameUpToLastSpace(authResponse?.data?.fullName?.toUpperCase()) || getNameUpToLastSpace(createAccountResponse?.data?.fullName?.toUpperCase()) || getNameUpToLastSpace(subscribeResponse?.data?.fullName?.toUpperCase()) || getNameUpToLastSpace(userInfo?.fullName?.toUpperCase())}</span></h1>
          <p className="dashboardDescription">{t("USER_LOREM")}</p>
        </div>
        <StickyBox offsetTop={0} offsetBottom={20} style={{ position: "sticky", zIndex: 200, background: 'var(--PRIMARY)' }} className="stickBox">
          <div className="headerContainer" style={{ marginBottom: "24px" }}>
            <h1>{t("SEARCH")}</h1>
            <TableHeader
              onFilter={onFilter}
              filterModal={filterModal}
              handleFilter={handleFilter}
              locationFilter={locationFilter}
              typeFilter={typeFilter}
              startDate={startDate}
              placeHolder={t("FIXTURE")}
              dimensions={dimensions}
              user
              filter
              show={show}
              setShow={setShow}
              handleShow={handleShow}
              leagueOption={leagueOption}
              airportOption={airportOption}
              onSearch={handleSearch}
              handleRange={handleRange}
              range={range}
              setStartDate={setStartDate}
              handleCloseSidebar={handleClose}
              userDashboard
              refresh={refresh}
              showFilterResult={showFilterResult}
              removeDate={removeDate}
              search={filters?.search}
              maxTotalPriceObject={maxTotalPriceObject}
              numberOfKeysWithValues={numberOfKeysWithValues}
              refreshClick={refreshClick}
              handleRemoveArrival={handleRemoveArrival}
              handleRemoveLeague={handleRemoveLeague}
              handleRemoveDeparture={handleRemoveDeparture}
              handleRemovePrice={handleRemovePrice}
              handleRemoveDate={handleRemoveDate}
              clearFilter={clearFilter}
              dashboard={dashboard}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              openUserDropdown={openUserDropdown}
              setOpenUserDropdown={setOpenUserDropdown}
              airportDepartureOption={airportDepartureOption}
              dateClass={dateClass}
              minTotalPriceObject={minTotalPriceObject}
            />
          </div>
        </StickyBox>
        {((busy && scrollCount === 1) || ((filters?.search && !searchResult))) ? <Loader /> :
          <div className={(filters?.search && searchResult) || numberOfKeysWithValues ? "dashboardCards " : "dashboardCards"}>
            {<p className="matchHeader" id="subHeader">
              <span>{t("FEATURED_EVENTS")}</span>
            </p>}
            <div className={SelectPlan === "etclite" ? "dashboardDiv" : "dashboardDiv justify-content-start"}>
          {(featuredEventsList?.length === 0 && SelectPlan === "etclite" )  &&    <h1 className="error-message no-data-message" >{t("NO_FEATURED")}</h1>  }
              <div className="customBtnDiv"> {SelectPlan === "etclite"  && <CustomButton
                onClick={() => setModalShow(true)}
                title={"UPGRADE TO TRIPTIX +"}
              />}

              </div>
              <div className={SelectPlan === "etclite" ? "dashboard-content mb-2" : featuredEventsList?.length === 0 ?"w-100":""}>
                {(SelectPlan === "etclite") && <div className="background"></div>}
                <div className={SelectPlan === "etclite" ? "onboardingCards" : featuredEventsList?.length === 0 ?"onboardingCards w-100 mb-2" :"onboardingCards mb-2"}>
                  {(SelectPlan === "etclite") ? (
                     (
                      <>
                        {featuredEventsList?.length > 0 && featuredEventsList?.map((data: any, index: number) => (
                          <OnboardingCard
                            key={index}
                            featureEvents={data}
                            index={index}
                            backStyle
                            handleNavigate={handleNavigate}
                            classStyle="onboardingCard"
                            enableBlur={(authResponse?.data?.plan === "etclite" || subscribeResponse?.data?.plan === "etclite") ? true : false}
                          />
                        ))}
                      </>
                    )
                  ) : (
                    <>
                     {featuredEventsList?.length === 0  && <h1 className="error-message no-message">{t("NO_FEATURED")}</h1>}
                      {featuredEventsList?.map((data: any, index: number) => (
                        <OnboardingCard
                          key={index}
                          featureEvents={data}
                          index={index}
                          backStyle
                          handleNavigate={handleNavigate}
                          classStyle="onboardingCard"
                          enableBlur={(authResponse?.data?.plan === "etclite" || subscribeResponse?.data?.plan === "etclite") ? true : false}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={((filters?.search && searchResult) || numberOfKeysWithValues) ? "search-header" : ""}>
              <p className={((filters?.search && searchResult) || numberOfKeysWithValues) ? "mb-0 pt-5" : "matchHeader pt-5"}>{((filters?.search && searchResult) || numberOfKeysWithValues) ? t("SEARCH_RESULT") : t("UPCOMING_MATCHES")}</p>
              {((filters?.search && searchResult) || numberOfKeysWithValues && dashboardDetails?.length > 0) ? (<span className="pt-5">{dashboardDetails?.length}{"  "}{t("GAMES_FOUND")}</span>) : ""}
            </div>
            {dashboardDetails?.length !== 0 ? <div ref={matchCardDivRef} className="matchCards matchCardDiv">
              {(filters?.search && !searchResult) ? <Loader /> : dashboardDetails?.map((data: any, index: number) => (<>
                <MatchCard key={index} matchCardData={data} handleNavigate={handleNavigate} />
              </>
              ))}
            </div> : <div className="error-message mb-4">{t("NO_RESULT_FOUND")}</div>}
            {busy && scrollCount > 1 && <Loader pagination />}
          </div>}
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="customModal">
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title" className="d-flex w-100 justify-content-center">
            <p className="mb-0">{t('UPGRADE_YOUR_MEMEBERSHIP')}</p>
            <span className="icon-close" onClick={() => setModalShow(false)}></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="purchaseContainer">
            {purchaseCardData.slice(planModel, 3).map((data, index) => (
              <PurchaseCard
                key={index}
                purchaseCardData={data}
                setPlan={handlePlan}
                plan={plan}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DashboardComponent;
