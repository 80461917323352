
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import toastReducer, { ToastState } from "./features/toast/toastSlice";
import authReducer, { AuthState } from "./features/auth/authSlice";
import userReducer, { UserState } from "./features/user/userSlice";
import eventReducer from "./features/fileEvent/fileEventSlice"
import settingReducer from "./features/setting/settingSlice"
import adminDashboardReducer from "./features/adminDashboard/adminDashboardSlice";
import userDashboardReducer from "./features/userDashboard/userDashboardSlice";
import eventDetailSlice from "./features/eventDetails/eventDetailSlice";
import footballEventSlice from "./features/footballEvent/footballEventSlice";
import calenderEventSlice from "./features/calenderEvent/calenderEventSlice";
import calenderDateEventSlice from "./features/calenderDateEvent/calenderDateEventSlice";
export interface RootState {
  toast: ToastState;
  auth: AuthState;
  user: UserState;
}

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  user: userReducer,
  event: eventReducer,
  setting: settingReducer,
  adminDashboard:adminDashboardReducer,
  userDashboard:userDashboardReducer,
  eventDetails:eventDetailSlice,
  footballEvent:footballEventSlice,
  calenderEvent:calenderEventSlice,
  calenderDateEvent:calenderDateEventSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
