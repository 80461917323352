import { EVENTS_DETAILS } from "../../../api/endPoint";
import instance from "../../../helpers/axios";

const eventDetails =(id:string )=>{
  return instance.get(`${EVENTS_DETAILS}/${id}`);
}

const eventDetailsService ={
  eventDetails,
}

export default eventDetailsService