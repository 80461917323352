import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthHeader from "../../../components/authHeader/authHeader";
import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import FileUpload from "../../../components/fileUpload/fileUpload";
import MultiSelectDropdown from "../../../components/multiSelect/multiSelect";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { leagues } from "../../../utils/constant";

interface ProfileProps {
  ProfileForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  busy: boolean;
  profile?: boolean;
  selectedFile?:any;
  setSelectedFile?: any;
  selectedOptions?:any;
  setSelectedOptions?:any;
  fullName?:string,
  setUploadProfile?:any,
  leagueOptionRef: any,
  isOpenLeague: boolean,
  setIsOpenLeague:any;
  leagueOption:any,
  signInForm:any,
  multiErrorFields:any,
  showPassword:any,
  setShowPassword:any,
  confirmShowPassword:any,
  setConfirmShowPassword:any,
  isImported?:boolean,
}


const ProfileComponent: React.FC<ProfileProps> = (props) => {
  const {selectedFile,setSelectedFile,selectedOptions,setSelectedOptions,fullName,setUploadProfile,isOpenLeague,leagueOptionRef,setIsOpenLeague,leagueOption,signInForm,multiErrorFields,showPassword,setShowPassword,confirmShowPassword ,setConfirmShowPassword,isImported} = props
  const { t } = useTranslation();
  const { ProfileForm, onFormSubmit, busy } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState({
    status: false,
    type: false,
    teams: false,
  });
  const fileUploadRef = useRef(() => {});

  const handleDropdownChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);
  };
  const handleFileUpload = (files: File[]) => {
    const file = files[0];
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      alert("Invalid file type. Please upload a JPEG or PNG file.");
      return;
    }
 
    // dispatch(fileUploadEvent(formData))
    setSelectedFile(URL.createObjectURL(file));
    setUploadProfile(file)

  };
  const handleCameraClick = () => {
    fileUploadRef.current && fileUploadRef.current();
  };

  const acceptedFileTypes = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png']
  };

  
  return (
    <React.Fragment>
      <div className="authPages" >
        <div className="authCenter">
          <div className="whiteBack">
            <AuthHeader
              headerTitle={isImported ? t("YOUR_DETAILS") : t("CREATE_YOUR_ACCOUNT")}
              profile={!isImported}
              onCameraClick={handleCameraClick}
              selectedFile={selectedFile}
              isImported={isImported}
            />
            <div className="form">
              <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                  return (
                    <>
                      <div className="textField">
                        <TextField
                          placeholder={t("FULL_NAME")}
                          autoFocus={false}
                          formMethod={formMethod}
                          rules={ProfileForm.fullName.validate}
                          name={ProfileForm.fullName.name}
                          errors={formMethod?.formState.errors}
                          type={""}
                          defaultValue={fullName || ""}
                        />
                          {process.env.REACT_APP_GOOGLE_MAPS_API_KEY &&
                          <div className="google-places-auto-complete">
                          <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                          selectProps={{
                            isClearable: true,
                            placeholder: t("LOCATION_TXT"),
                            onChange: (place) => {
                              formMethod.setValue(ProfileForm.location.name, place?.value?.description);
                            }
                          }}
                          autocompletionRequest={{
                            bounds: [
                              { lat: 50, lng: 50 },
                              { lat: 100, lng: 100 }
                            ],
                            types: ['(cities)'],
                          }}
                        />
                          </div>

                        }
                        <div className="formDropdown" ref={leagueOptionRef}>
                          <button
                            className="form-control"
                            onClick={(e) =>{
                              e.preventDefault();
                              setOpenDropdown((prevState) => ({
                                ...prevState,
                                teams: !prevState.teams,
                              }))
                              setIsOpenLeague(!isOpenLeague)
                            }}
                          >
                            <span className= {selectedOptions.length > 0
                                ? "selected":""}>
                              {selectedOptions.length > 0
                                ? `${selectedOptions?.length} selected` //selectedOptions.join(", ")
                                : "Leagues"}
                            </span>
                            <span className="icon-downArrow"></span>
                          </button>
                          {isOpenLeague && (
                            <MultiSelectDropdown
                              multiSelect
                              options={leagues}
                              onChange={handleDropdownChange}
                              selectOption={selectedOptions}
                              name="teams"
                              isOpen={isOpen}
                              setIsOpen={setIsOpen}
                            />
                          )}
                        </div>
                        {isImported &&
                        <>
                        
                           <TextField
                            placeholder={t("NEW_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={signInForm.password.validate}
                            name={signInForm.password.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            multiErrorFields={multiErrorFields}
                            showPassword={showPassword}
                            togglePasswordVisibility={() =>
                              setShowPassword((prev: any) => !prev)
                            }
                            onChange={(e) => {
                              formMethod?.watch("password") &&
                                formMethod.trigger("password");
                              if (formMethod?.watch("confirmPassword")) {
                                formMethod?.trigger("confirmPassword");
                              }
                            }}
                          />
                          <TextField
                            placeholder={t("CONFIRM_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={{
                              required: {
                                value: true,
                                message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
                              },
                              validate: {
                                matchPassword: (value: string) =>
                                  (value &&
                                    value === formMethod?.watch("password")) ||
                                  t("PASSWORDS_DON’T_MATCH"),
                              },
                            }}
                            name={signInForm.confirmpassword.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            showPassword={confirmShowPassword}
                            togglePasswordVisibility={() =>
                              setConfirmShowPassword((prev: any) => !prev)
                            }
                            onChange={(value) => {
                              formMethod?.watch("confirmPassword") &&
                                formMethod.trigger("confirmPassword");
                            }}
                          />
                          </>}
                      </div>
                      <FileUpload
                          profile
                          onDrop={handleFileUpload}
                          triggerOpen={fileUploadRef}
                          maxSize={20 * 1024 * 1024}
                          accept={acceptedFileTypes}
                        />
                      <CustomButton
                        className="btnAuth"
                        title={t("SUBMIT")}
                        disabled={
                          !formMethod?.formState.isValid 
                        }
                        loading={busy}
                      />
                    </>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProfileComponent;
