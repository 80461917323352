// CALENDER_EVENTS_DETAILS
import { CALENDER_EVENTS_DETAILS } from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { listDashboardQueryParams } from "../../../interface/userInterface";

const calenderEvent = (query:listDashboardQueryParams)=>{
  return instance.get(CALENDER_EVENTS_DETAILS,{params:query});
}

const calenderEventDetailsService = {
  calenderEvent
}

export default calenderEventDetailsService;