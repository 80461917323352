import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";

interface LayoutProps {}

function Layout(props: LayoutProps) {
  
  return (
    <React.Fragment>
      <div className="userWrapper">
        <Sidebar />    
        <div className='main-content'>
          <Outlet />
        </div>
         </div>
    </React.Fragment>
  );
}
export default Layout;
