import React, { ReactNode } from 'react';

interface ButtonProps {
  icon?: ReactNode;
  title: string;
  style?: string;
  onClick?: () => void;
  subIcon?: string;
  filter?: any;
  dimensions?: any;
  refreshClick?: any;
  userDashboard?: boolean
}

const ButtonComponent: React.FC<ButtonProps> = ({ icon, title, style, onClick, subIcon, filter, dimensions, refreshClick, userDashboard }) => {
  return (
    <>
      {!userDashboard
        ?
        <>
          <button className={style} onClick={onClick}>
            {icon && <span className={`icon-${icon}`}></span>}
            {filter && dimensions?.width < 1024 ? "" : (title)}
            {subIcon && <span className={`icon-${subIcon}`} onClick={refreshClick}></span>}
          </button>
        </>
        : <>
          <div className={`${style} p-0 d-flex align-items-center`}>
            <button className={(dimensions?.width > 1024  &&subIcon) ? "pe-0 userDashboard-button" : 'userDashboard-button'} onClick={onClick} >
              {icon && <span className={`icon-${icon}`}></span>}
              {filter && dimensions?.width < 1024 ? "" : title}
            </button>
            {dimensions?.width > 1024 && subIcon && <span className={`icon-${subIcon} px-2`} onClick={refreshClick}></span>}
          </div>
        </>}
    </>
  );
};

export default ButtonComponent;
