import React, { useEffect, useRef, useState } from "react";
import ToggleButton from "../toggleButton/toggleButton";
import { t } from "i18next";
import moment from "moment";
import Loader from "../loader/Loader";
import { Button, Modal } from "react-bootstrap";

interface UserTableData {
  _id: string;
  fullName?: string;
  email?: string;
  subscriptionStatus?: string;
  plan?: string;
  renewalDate?: string;
  location?: string;
  teams?: string[];
  status?: string;
}

interface UserTableProps {
  userTableData: UserTableData[];
  style: any;
  handleCheckChange: any;
  busy?: boolean;
  handleModal: (data: any) => void;
  showModal: boolean;
  setShowModal: any;
  userName: any;
  happendModalStatus: any;
  matchCardDivRef:any,
  scrollCount:number,
  handleScroll:any
}

const UserTable: React.FC<UserTableProps> = ({
  userTableData,
  style,
  handleCheckChange,
  busy,
  handleModal,
  showModal,
  setShowModal,
  userName,
  happendModalStatus,
  matchCardDivRef,
  scrollCount,
  handleScroll
}) => {
 

  // first letter is capitali
  const capitalizeFirstLetter = (str: string) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  return (
    <>
      <div className="tableMain">
        <div className="tableWrapper"  style={style}  >
          <div className="tableContainer"  style={style}  onScroll={handleScroll}  ref={matchCardDivRef}>
            {(busy && scrollCount === 1) ? (
              <Loader />
            ) : userTableData?.length !== 0 ? (
              <>
              <table>
                <thead>
                  <tr className="sticky">
                    <th>{t("ID")}</th>
                    <th>{t("NAME")}</th>
                    <th>{t("EMAIL")}</th>
                    <th>{t("SUBSCRIPTION_STATUS")}</th>
                    <th>{t("SUBSCRIPTION_TYPE")}</th>
                    <th>{t("RENEWAL_DATE")}</th>
                    <th>{t("LOCATION")}</th>
                    <th>{t("LEAGUES")}</th>
                    <th>{t("ENABLE/DISABLE_USER")}</th>
                  </tr>
                  </thead>
                <tbody>
                  {userTableData?.map((userData, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="columnContainer">
                          {userData?.fullName || "-"}
                        </td>
                        <td className="columnContainer">{userData.email}</td>
                        <td className="columnSubContainer">
                          <div
                            className={
                              userData.subscriptionStatus === "active"
                                ? "subscriptionActive"
                                : "subscriptionInActive"
                            }
                          >
                            {userData.subscriptionStatus === "active"
                              ? "Active"
                              : "Inactive"}
                          </div>
                        </td>
                        <td className="columnSubContainer">
                          {userData?.plan
                            ? capitalizeFirstLetter(
                                userData.plan === "etcannual"
                                  ? "annual"
                                  : userData?.plan === "etcmonthly"
                                  ? "monthly"
                                  : "free"
                              )
                            : "-"}
                        </td>
                        <td className="columnSubContainer">
                          {userData?.renewalDate &&
                            moment(userData?.renewalDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="locationContainer">
                          {userData?.location || "-"}
                        </td>
                        <td className="columnSubContainer">
                          {userData?.teams?.join(", ") || "-"}
                        </td>
                        <td>
                          <ToggleButton
                            id={userData?._id || ""}
                            status={userData?.status === "active" ? true : false}
                            handleCheckChange={
                              userData?.status === "inactive" && handleCheckChange
                            }
                            openModal={() =>
                              userData?.status === "active" && handleModal(userData)
                            }
                          />
                        </td>
                      
                      </tr>
                       
                    ))}
           
                </tbody>
              </table>
              {busy && scrollCount > 1 && <Loader pagination/>}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center h-100">
                {t("NO_USERS_FOUND")}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="userTables"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deactivate user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-nowrap">
            Are you sure you want to{" "}
            {userName?.status === "inactive" ? "active" : "deactivate"}{" "}
            <b>{userName?.user || userName?.email}</b> ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="active-yes"
            onClick={() => happendModalStatus(userName?.id, userName?.status)}
          >
            {" "}
            Yes{" "}
          </Button>
          <Button className="deactive-no" onClick={() => setShowModal(false)}>
            {" "}
            No{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserTable;
