import React from 'react'
import CustomButton from '../../components/customButton/customButton';
import TextField from '../../components/textField/textField';
import AuthHeader from '../../components/authHeader/authHeader';
import HookForm from '../../components/hookForm/hookForm';
import { useTranslation } from "react-i18next";
import { Button, Modal } from 'react-bootstrap';
import {  handleOnboarding, handleOtpFlag, handlePlanSelect, handlePlanSelectGlobal, logout, setLoginResponse, setSocialAccount } from '../../store/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../interface/userInterface';
import { useNavigate } from 'react-router';
import useWindowDimension from '../../components/hooks/useWindowDimension';
import { Link } from 'react-router-dom';
import PurchaseCard from '../../components/cards/onboardingCard/purchaseCard';
import { setUserDashboardFilters } from '../../store/features/userDashboard/userDashboardSlice';
import { setFootballFilters } from '../../store/features/footballEvent/footballEventSlice';
import { resetFirstEventPrice, setEventFilters } from '../../store/features/calenderEvent/calenderEventSlice';
import { handleResetPasswordResponse } from '../../store/features/setting/settingSlice';

interface SubscriptionDetails {
  status: boolean;
  content: string;
}

interface ChangePasswordProp {
  signInForm: any;
  onFormSubmit: (event: React.FormEvent) => void;
  multiErrorFields: any;
  loading: boolean;
  showPassword: boolean;
  confirmShowPassword: boolean;
  setShowPassword: any;
  setConfirmShowPassword: any;
  socialLogin: boolean;
  purchaseCardData ?: {
    _id: number;
    subscriptionType: string;
    amount: string;
    subscriptionPlan?:string;
    description: string;
    subscriptionDetails: SubscriptionDetails[];
    plan: string;
  }[];
  setPlan?: any;
  plan?: string;
  modalShow:boolean,
  setModalShow:any,
  planModel:  number | undefined,
  handlePlan:any,
  authResponse:any,
}

const 
SettingComponent: React.FC<ChangePasswordProp> = (props) => {
  const { t } = useTranslation();
  const { signInForm,purchaseCardData,setPlan, onFormSubmit, multiErrorFields, loading, showPassword, setShowPassword, confirmShowPassword, setConfirmShowPassword, socialLogin,modalShow,setModalShow,planModel,handlePlan,authResponse} = props;
  const dimensions = useWindowDimension();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()
  const logoutFunction = () => {
    localStorage.clear()
    dispatch(logout(null))
    dispatch(setLoginResponse(null))
    dispatch(setSocialAccount(false))
    dispatch(setUserDashboardFilters(null))
    dispatch(setFootballFilters(null))
    dispatch(setEventFilters(null))
    dispatch(resetFirstEventPrice(null))
    dispatch(handleOtpFlag(false))
    dispatch(handleOnboarding(""))
    dispatch(handlePlanSelect(null))
    dispatch(handlePlanSelectGlobal(""))
    dispatch(handleResetPasswordResponse(null))
    navigate("/login")
  }
  return (
    <>
      <React.Fragment>
        <div className="authPages" >
          <div className="authCenter settingContainer">
            <div className="whiteBack">
              <AuthHeader
                headerTitle={(socialLogin)? t("SET_PASSWORD") : t("CHANGE_YOUR_PASSWORD")}
                userSettings
                socialLogin={socialLogin}
                purchaseCardData={purchaseCardData}
                manageSubscription
                setPlan={setPlan}
                setModalShow={setModalShow}
                planModel={planModel}
              />
              <div className="form">
                {!socialLogin ? <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                  {(formMethod) => {
                    return (
                      <>
                        <div className="textField">
                          <TextField
                            placeholder={t("NEW_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={signInForm.password.validate}
                            name={signInForm.password.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            multiErrorFields={multiErrorFields}
                            showPassword={showPassword}
                            togglePasswordVisibility={() =>
                              setShowPassword((prev: any) => !prev)
                            }
                            onChange={(e) => {
                              formMethod?.watch("password") &&
                                formMethod.trigger("password");
                              if (formMethod?.watch("confirmPassword")) {
                                formMethod?.trigger("confirmPassword");
                              }
                            }}
                          />
                          <TextField
                            placeholder={t("CONFIRM_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={{
                              required: {
                                value: true,
                                message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
                              },
                              validate: {
                                matchPassword: (value: string) =>
                                  (value &&
                                    value === formMethod?.watch("password")) ||
                                  t("PASSWORDS_DON’T_MATCH"),
                              },
                            }}
                            name={signInForm.confirmpassword.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            showPassword={confirmShowPassword}
                            togglePasswordVisibility={() =>
                              setConfirmShowPassword((prev: any) => !prev)
                            }
                            onChange={(value) => {
                              formMethod?.watch("confirmPassword") &&
                                formMethod.trigger("confirmPassword");
                            }}
                          />
                        </div>
                        <CustomButton
                          className="btnAuth text-uppercase"
                          title={t("CHANGE_PASSWORD")}
                          disabled={!formMethod?.formState.isValid}
                          loading={loading}
                        />
                      </>
                    );
                  }}
                </HookForm> : <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                {(formMethod) => {
                    return (
                      <>
                        <div className="textField">
                          <TextField
                            placeholder={t("NEW_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={signInForm.password.validate}
                            name={signInForm.password.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            multiErrorFields={multiErrorFields}
                            showPassword={showPassword}
                            togglePasswordVisibility={() =>
                              setShowPassword((prev: any) => !prev)
                            }
                            onChange={(e) => {
                              formMethod?.watch("password") &&
                                formMethod.trigger("password");
                              if (formMethod?.watch("confirmPassword")) {
                                formMethod?.trigger("confirmPassword");
                              }
                            }}
                          />
                          <TextField
                            placeholder={t("CONFIRM_PASSWORD")}
                            autoFocus={false}
                            formMethod={formMethod}
                            rules={{
                              required: {
                                value: true,
                                message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
                              },
                              validate: {
                                matchPassword: (value: string) =>
                                  (value &&
                                    value === formMethod?.watch("password")) ||
                                  t("PASSWORDS_DON’T_MATCH"),
                              },
                            }}
                            name={signInForm.confirmpassword.name}
                            errors={formMethod?.formState.errors}
                            type="password"
                            showPassword={confirmShowPassword}
                            togglePasswordVisibility={() =>
                              setConfirmShowPassword((prev: any) => !prev)
                            }
                            onChange={(value) => {
                              formMethod?.watch("confirmPassword") &&
                                formMethod.trigger("confirmPassword");
                            }}
                          />
                        </div>
                        <CustomButton
                          className="btnAuth text-uppercase"
                          title={t("SET_PASSWORD")}
                          disabled={!formMethod?.formState.isValid}
                          loading={loading}
                        />
                      </>
                    );
                  }}
                  </HookForm>}
                  <Button className="bg-transparent border-0 text-center w-100 mt-4 button-update"><Link to='https://triptix.co/app-privacy-policy/' target='blank'>{t("PRIVACY_POLICY")}</Link></Button>
                {(dimensions?.width < 1025) && <Button className="logOut flex-row mt-3" onClick={() => logoutFunction()}><span className="icon icon-logOut"></span>{t("LOG_OUT")}</Button>}
              </div>
            </div>
          </div>
        </div>


        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
          className="customModal">
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title" className="d-flex w-100 justify-content-center">
              <p className="mb-0">{t('UPGRADE_YOUR_MEMEBERSHIP')}</p>
              <span className="icon-close" onClick={() => setModalShow(false)}></span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="purchaseContainer">
              {purchaseCardData && purchaseCardData.slice(planModel, 3).map((data, index) => (
                <PurchaseCard
                  key={index}
                  purchaseCardData={data}
                  setPlan={handlePlan}
                />
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  )
}

export default SettingComponent