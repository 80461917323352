import React, { forwardRef, useState, Ref, useEffect, useMemo, useRef } from "react";
import SearchComponent from "../searchComponent/searchComponent";
import { t } from "i18next";
import CustomDropdown from "../dropDown/dropDown";
import ButtonComponent from "../buttonComponent/buttonComponent";
import DatePicker from "react-datepicker";
import MultiSelectDropdown from "../multiSelect/multiSelect";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import CustomButton from "../customButton/customButton";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useLocation } from "react-router";
import SelectOptionComponent from "../selectOption/selectOption";
import moment from "moment";

export interface option {
  label: string;
  value: string;
}

interface UserTableData {
  _id: string;
  fullName?: string;
  email?: string;
  subscriptionStatus?: string;
  plan?: string;
  renewalDate?: string;
  location?: string;
  teams?: string[];
  status?: string;
}
interface TableHeaderProps {
  onSearch?: (query: string) => void;
  onFilter?: () => void;
  onSortBy?: (option: option) => void;
  style?: string;
  search?: string;
  selectedSortOption?: string;
  filterModal?: boolean | undefined;
  filter?: boolean;
  user?: boolean;
  subIcon?: string;
  extraClassName?: string;
  handleFilter: (option: any) => void;
  typeFilter: (option: String) => void;
  userTableData?: UserTableData[];
  locationFilter: (option: any) => void;
  locationDetails?: {
    _id: string;
    name?: string;
    email?: string;
    subscriptionStatus?: string;
    subscriptionType?: string;
    renewalDate?: string;
    location?: string;
    teams?: string;
  }[];
  startDate?: any;
  endDate?: any;
  setDateRange?: any;
  placeHolder?: string;
  dimensions?: any;
  setShow?: any
  show?: any;
  handleShow?: () => void;
  leagueOption?: any,
  airportOption?: any,
  handleRange?: any,
  range?: any;
  setStartDate?: any,
  handleCloseSidebar?: () => void;
  userDashboard?: boolean;
  refresh?: string;
  clearDate?: () => void;
  showFilterResult?: boolean;
  removeDate?: () => void;
  handleFilterOption?: () => void;
  maxTotalPriceObject?: number,
  numberOfKeysWithValues?: number,
  refreshClick?: () => void;
  handleRemoveArrival?: any
  handleRemoveLeague?: any
  handleRemoveDeparture?: any
  handleRemovePrice?: any
  handleRemoveDate?: any,
  clearFilter?: boolean,
  dashboard?: boolean,
  football?: boolean,
  searchQuery?: string,
  setSearchQuery?: any,
  setOpenUserDropdown?: any,
  openUserDropdown?: any,
  event?: boolean,
  month?: string,
  totalEvent?: number,
  airportDepartureOption?: any,
  dateClass?:string,
dateRange?:any,
  minTotalPriceObject?:any
}

const options = [
  { label: "Recent", value: "createdAt" },
  { label: "A-Z", value: "fullName" },
  { label: "Z-A", value: "fullName" },
];
const TableHeader: React.FC<TableHeaderProps> = ({
  search,
  onSearch,
  onFilter,
  selectedSortOption,
  onSortBy,
  style,
  filterModal,
  handleFilter,
  typeFilter,
  locationFilter,
  locationDetails,
  user,
  subIcon,
  extraClassName,
  startDate,
  endDate,
  setDateRange,
  placeHolder,
  dimensions,
  filter,
  setShow,
  show,
  handleShow,
  leagueOption,
  airportOption,
  handleRange,
  range,
  setStartDate,
  handleCloseSidebar,
  userDashboard,
  refresh,
  clearDate,
  showFilterResult,
  removeDate,
  handleFilterOption,
  maxTotalPriceObject,
  numberOfKeysWithValues,
  refreshClick,
  handleRemoveArrival,
  handleRemoveLeague,
  handleRemoveDeparture,
  handleRemovePrice,
  handleRemoveDate,
  clearFilter,
  dashboard,
  football,
  searchQuery,
  setSearchQuery,
  openUserDropdown,
  setOpenUserDropdown,
  event,
  month,
  totalEvent,
  airportDepartureOption,
  dateClass,
  dateRange,
  minTotalPriceObject
}) => {
  const location = useLocation()
  const [showCalendar, setShowCalendar] = useState(false);
  const [toggleHeader, setToggleHeader] = useState(t("FILTER"));
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [showRange, setShowRange] = useState(false);
  const [showDeparture, setShowDeparture] = useState(false);
  const [showArrival, setShowArrival] = useState(false);
  const [showLeague, setShowLeague] = useState(false);
  const [leagueOptionData, setLeagueOptionData] = useState<option[]>([])
  const [airportOptionData, setAirportOptionData] = useState<option[]>([])
  const [airportDepartureOptionData, setAirportDepartureOptionData] = useState<option[]>([])
  const [locationFilterData, setLocationFilterData] = useState<option[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenPrice, setIsOpenPrice] = useState(false);
  const priceDropdownRef = useRef<HTMLDivElement>(null);
  const [isOpenLeague, setIsOpenLeague] = useState(false);
  const leagueDropdownRef = useRef<HTMLDivElement>(null);
  const [isOpenDeparture, setIsOpenDeparture] = useState(false);
  const departureDropdownRef = useRef<HTMLDivElement>(null);
  const [isOpenArrival, setIsOpenArrival] = useState(false);
  const arrivalDropdownRef = useRef<HTMLDivElement>(null);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const dateContainerRef = useRef<HTMLDivElement>(null);
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const locationFilterRef = useRef<HTMLDivElement>(null);
  const [isOpenPlan, setIsOpenPlan] = useState(false);
  const planRef = useRef<HTMLDivElement>(null);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const statusRef = useRef<HTMLDivElement>(null);

  const filtersData = useSelector((state: any) => state?.user?.userFilters);
  const userFilter = useSelector((state: any) => state?.userDashboard?.userFilters)
  const footballFilter = useSelector((state: any) => state?.footballEvent?.footballFilters)
  const eventFilter = useSelector((state: any) => state?.calenderEvent?.eventFilters)

  // previous use this code for open and close 
  // const handleUserToggleDropdown = (dropdownName: string) => {
  //   setOpenUserDropdown((prev: any) => {
  //     setIsOpen(typeof dropdownName === "string" ? true : false);
  //     return prev === dropdownName ? null : dropdownName
  //   })
  // };

  const handlePriceToggleDropdown = (dropdownName: string) => {
    setIsOpenPrice(prevIsOpenPrice => !prevIsOpenPrice);
  }

  const handleLeagueToggleDropdown = (dropdownName: string) => {
    setIsOpenLeague(prevIsOpenLeague => !prevIsOpenLeague);
  }

  const handleDepartureToggleDropdown = (dropdownName: string) => {
    setIsOpenDeparture(prevIsOpenDeparture => !prevIsOpenDeparture); 
  }

  const handleArrivalToggleDropdown = (dropdownName: string) => {
    setIsOpenArrival(prevIsOpenArrival => !prevIsOpenArrival);
  }

  const handleDateToggleDropdown = (dropdownName: string) => {
    setIsOpenDatePicker(prevIsOpenDate => !prevIsOpenDate);
  }


  const handleLocationToggleDropdown = (dropdownName: string) => {
    setIsOpenLocation(prevIsOpenLoction => !prevIsOpenLoction);
  }

  const handlePlanToggleDropdown = (dropdownName: string) => {
    setIsOpenPlan(prevIsOpenPlan => !prevIsOpenPlan);
  }

  const handleStatusToggleDropdown = (dropdownName: string) => {
    setIsOpenStatus(prevIsOpenStatus => !prevIsOpenStatus);
  }

  useEffect(() => {
    // Function to handle click outside of the price dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (priceDropdownRef.current && !priceDropdownRef.current.contains(event.target as Node)) {
        setIsOpenPrice(false); // Close the price dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [priceDropdownRef.current]);

  useEffect(() => {
    // Function to handle click outside of the price dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (leagueDropdownRef.current && !leagueDropdownRef.current.contains(event.target as Node)) {
        setIsOpenLeague(false); // Close the price dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [leagueDropdownRef.current]);

  useEffect(() => {
    // Function to handle click outside of the price dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (departureDropdownRef.current && !departureDropdownRef.current.contains(event.target as Node)) {
        setIsOpenDeparture(false); // Close the price dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [departureDropdownRef.current]);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dateContainerRef.current &&
        !dateContainerRef.current.contains(event.target as Node)
      ) {
        setIsOpenDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateContainerRef]);

  useEffect(() => {

    const handleClickOutside = (event: MouseEvent) => {
      if (locationFilterRef.current && !locationFilterRef.current.contains(event.target as Node)) {
        setIsOpenLocation(false); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [locationFilterRef?.current]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (planRef.current && !planRef.current.contains(event.target as Node)) {
        setIsOpenPlan(false); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [planRef?.current]);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (statusRef.current && !statusRef.current.contains(event.target as Node)) {
        setIsOpenStatus(false); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [statusRef?.current]);


  useEffect(() => {
    // Function to handle click outside of the price dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (arrivalDropdownRef.current && !arrivalDropdownRef.current.contains(event.target as Node)) {
        setIsOpenArrival(false); // Close the price dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [arrivalDropdownRef.current]);

  const handleSortChange = (option: option) => {
    if (onSortBy) onSortBy(option);
  };


//   const [startNewDate, setStartNewDate] = useState(new Date());
// const [endNewDate, setEndNewDate] = useState(null); 
    
  const handleDropdownChange = (selectedOptions: any, category: string) => {
    handleFilter(selectedOptions);
    setSelectedOptions(selectedOptions);
  };

  const handleTypeDropdownChange = (selectedOptions: any, category: string) => {
    typeFilter(selectedOptions);
    setSelectedOptions(selectedOptions);
  };

  const handleLocationDropdown = (selectedOptions: any, category: string) => {
    locationFilter(selectedOptions);
    setSelectedOptions(selectedOptions);
  };

  const handleClose = () => {
    setToggleHeader("FILTER");
    setShowCalendar(false);
    setShowArrival(false);
    setShowDeparture(false);
    setShowLeague(false);
    setShowRange(false);
    onFilter && onFilter()
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    if (onSearch) onSearch("");
  };

  const handleChange = (value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const deBounce = setTimeout(() => {
      if (onSearch && searchQuery && searchQuery?.length > 2) {
        onSearch(searchQuery);
      }
    }, 1000);
    return () => {
      clearTimeout(deBounce);
    };
  }, [searchQuery]);

  const statusOption = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const typeOption = [
    { label: "Annual", value: "etcannual" },
    { label: "Free", value: "etclite" },
    { label: "Monthly", value: "etcmonthly" },
  ];

  const uniqueLocation = useMemo(
    () =>
      Array.from(
        new Set(locationDetails?.map((item: any) => item?.toLowerCase().trim()))
      )?.map((item: any) => {
        const capitalizedLabel = item?.charAt(0)?.toUpperCase() + item?.slice(1);
        return { label: capitalizedLabel, value: item };
      }),
    [locationDetails]
  );

  useEffect(() => {
    if (leagueOption) {
      const optionData = leagueOption?.map((item: any) => {
        const capitalizedLabel = item?.charAt(0)?.toUpperCase() + item?.slice(1);
        return { label: capitalizedLabel, value: item };
      })
      setLeagueOptionData(optionData)
    }
  }, [leagueOption])


  // useEffect(() => {
  //   if (airportOption) {
  //       // Create a Set to store unique values
  //     const uniqueOptions = new Set(airportOption);

  //     // Convert the Set to an array
  //     const uniqueOptionArray = Array.from(uniqueOptions);

  //     // Map the unique options and capitalize the labels
  //     const optionData = uniqueOptionArray?.map((item: any) => ({
  //       label: item?.charAt(0)?.toUpperCase() + item?.slice(1),
  //       value: item
  //     }));

  //     // Set the airportOptionData state with unique option data
  //     setAirportOptionData(optionData);
  //   }
  // }, [airportOption]);

  useEffect(() => {
    if (airportOption?.length > 0) {
      // Sort the array based on destinationAirport in ascending order
      const sortedAirportOption = airportOption.sort((a:any, b:any) => {
        if (a?.destinationAirportFullName < b?.destinationAirportFullName) return -1;
        if (a?.destinationAirportFullName > b?.destinationAirportFullName) return 1;
        return 0;
      });

      // Create a Set to store unique destinationAirport values
      const uniqueOptions = new Set<string>();

      // Filter and map the sorted array to ensure uniqueness and create the required structure
      const optionData = sortedAirportOption
        .filter((item:any) => {
          if (uniqueOptions.has(item?.destinationAirport)) {
            return false;
          } else {
            uniqueOptions.add(item?.destinationAirport);
            return true;
          }
        })
        .map((item:any) => ({
          label:item?.destinationAirportFullName ? item?.destinationAirportFullName + ` (${item?.destinationAirport})` : item?.destinationAirport,
          value: item?.destinationAirport
        }));
      // Set the airportOptionData state with unique option data
      setAirportOptionData(optionData);
    }
  }, [airportOption]);

  // useEffect(() => {
  //   if (airportDepartureOption) {
  //     // Create a Set to store unique values
  //     const uniqueOptions = new Set(airportDepartureOption);

  //     // Convert the Set to an array
  //     const uniqueOptionArray = Array.from(uniqueOptions);

  //     // Map the unique options and capitalize the labels
  //     const optionData = uniqueOptionArray?.map((item: any) => ({
  //       label: item?.charAt(0).toUpperCase() + item?.slice(1),
  //       value: item
  //     }));

  //     // Set the airportOptionData state with unique option data
  //     setAirportDepartureOptionData(optionData);
  //   }
  // }, [airportDepartureOption]);

  useEffect(() => {
    if (airportDepartureOption?.length > 0) {
      // Sort the array based on departingAirport in ascending order
      const sortedAirportOption = airportDepartureOption.sort((a:any, b:any) => {
        if (a.departingAirportFullName < b.departingAirportFullName) return -1;
        if (a.departingAirportFullName > b.departingAirportFullName) return 1;
        return 0;
      });

      // Create a Set to store unique departingAirport values
      const uniqueOptions = new Set<string>();

      // Filter and map the sorted array to ensure uniqueness and create the required structure
      const optionData = sortedAirportOption
        .filter((item:any) => {
          if (uniqueOptions.has(item.departingAirport)) {
            return false;
          } else {
            uniqueOptions.add(item.departingAirport);
            return true;
          }
        })
        .map((item:any) => ({
          label:item?.departingAirportFullName ? item?.departingAirportFullName + ` (${item.departingAirport})` :item.departingAirport,
          value: item.departingAirport
        }));
      // Set the airportDepartureOptionData state with unique option data
      setAirportDepartureOptionData(optionData);
    }
  }, [airportDepartureOption]);


  useEffect(() => {
    if (uniqueLocation) {
      setLocationFilterData(uniqueLocation);
    }
  }, [locationDetails]);

  interface customInputProps {
    value?: any;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }

  const CustomInput = forwardRef(
    ({ value, onClick }: customInputProps, ref: Ref<HTMLButtonElement>) => (
      <button className="toggle-button" onClick={onClick} ref={ref}>
        {value || t("RENEWAL_DATE")}
      </button>
    )
  );
  const CustomInputUser = forwardRef(
    ({ value, onClick }: customInputProps, ref: Ref<HTMLButtonElement>) => (
      <button className={value ? "active-toggle" : "toggle-button"} onClick={onClick} ref={ref}>
        {moment(value).format("DD-MM-YYYY") || t("DEPARTURE_DATE")} {!value && <span className="icon-downArrow"></span>}
      </button>
        )
  );


  const CustomInputEvent = forwardRef(
    ({ value, onClick }: customInputProps, ref: Ref<HTMLButtonElement>) => (
      <>
       
        <button className={value ? "active-toggle" : "toggle-button"} onClick={onClick} ref={ref}>
          {(value && moment(value, 'MM/YYYY')?.format('MMM-YYYY')) || t("MONTH_AND_YEAR")} {!value && <span className="icon-downArrow"></span>}
        </button>
      
      </>
    )
  );


  const renderMonthContent = (monthIndex: number, shortMonthText: string, fullMonthText: string) => {
    const fullYear = new Date().getFullYear();
    const tooltipText = `Tooltip for month: ${fullMonthText} ${fullYear}`;
    return <span title={tooltipText}>{shortMonthText}</span>;
  };
  const currentYear = new Date().getFullYear();

  function ToggleHeaderFunc(val: string) {
    setToggleHeader(val)
  }


  // check for any value have or not
  const hasAnyValue = Object.values(filtersData?.filters || {}).some((value: any) => (Array.isArray(value) && value?.length) || (typeof value === 'string' && value?.trim()) || (typeof value === 'object' && Object.keys(value)?.length));
  return (
    <>
      <div className="tableHeader">
        <div className={event && dimensions?.width <= 375 ? "searchContainer align-items-center" :"searchContainer "}>
          {month && <h1 className='mb-0 calender-month d-flex align-items-center'>{month?.toUpperCase()}</h1>}
          {!event && <SearchComponent
            searchVal={searchQuery || ""}
            onSearch={handleChange}
            onClear={handleClearSearch}
            placeHolder={placeHolder}
          />}
          <ButtonComponent
            title={
              (filterModal && user)
                ? t(`CLEAR ${numberOfKeysWithValues && numberOfKeysWithValues > 0 ? `(${numberOfKeysWithValues})` : ''}`)
                : t("FILTER")
            }
            onClick={() => {
              onFilter && onFilter();
            }}
            style={t("FILTERBTN")}
            icon={t("FILTER_ICON")}
            filter
            dimensions={dimensions}
            subIcon={(numberOfKeysWithValues && filterModal) ? refresh : ""}
            refreshClick={refreshClick}
            userDashboard
          />
          {event && <div className='total-count w-100'>
            <h1 className="mb-0">{totalEvent}{" "}{(totalEvent ?? 0) === 1 ? t("GAME") : t("GAMES")} FOUND</h1>
          </div>}
        </div>
        <div className="sortContainer">
          <CustomDropdown
            options={options}
            styles="dropdownContainer"
            className="selectedOption"
            icon={t("DROPDOWN")}
            onSelect={handleSortChange}
            title={t("SORT_BY")}
            extraClassName={extraClassName}
          />
        </div>
      </div>

      {filterModal && (
        <>
          {user ? (
            <>
              <Offcanvas show={dimensions?.width < 1023 ? show : false} onHide={() => handleCloseSidebar && handleCloseSidebar()} placement="end">
                <Offcanvas.Header>
                  <Offcanvas.Title>
                    {toggleHeader !== "FILTER" ? (
                      <span className="icon-arrow-left" onClick={() => { setShowCalendar(false); setShowRange(false); setShowArrival(false); setShowDeparture(false); setShowLeague(false); setToggleHeader("FILTER"); }}></span>
                    ) : (
                      ""
                    )}
                    {toggleHeader}
                  </Offcanvas.Title>
                  <span className="icon-close" onClick={handleClose}></span>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {
                    showArrival && (
                      <div className="dateContainer">
                        <MultiSelectDropdown
                          options={airportOptionData}
                          onChange={handleDropdownChange}
                          multiSelect
                          name="airportArrival"
                          clearFilter={clearFilter}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          setOpenUserDropdown={setOpenUserDropdown}
                        />
                      </div>
                    )
                  }
                  {
                    showDeparture && (
                      <div className="dateContainer">
                        <MultiSelectDropdown
                          options={airportDepartureOptionData}
                          onChange={handleLocationDropdown}
                          multiSelect
                          name="airportDeparture"
                          clearFilter={clearFilter}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          setOpenUserDropdown={setOpenUserDropdown}
                        />
                      </div>
                    )
                  }
                  {showCalendar && (
                    <div className={"dateContainer"}>
                      {!event
                        ? (
                          <> <div className="dateHeader">{t("WHAT_ARE_DATES")}</div>
                            <DatePicker
                              selected={startDate}
                              onChange={(update: any) => {
                                if (update === null) {
                                  removeDate && removeDate()
                                }
                                setStartDate(update);
                              }}
                              inline
                              isClearable={true}
                            /></>) :
                        <DatePicker
                          selected={startDate}
                          renderMonthContent={renderMonthContent}
                          showMonthYearPicker
                          dateFormat="MM/yyyy"
                          minDate={new Date(currentYear, 0, 1)}
                          onChange={(update: any) => {
                            setStartDate(update);
                          }}
                          className="event-picker text-center"
                          isClearable={startDate !== null && startDate !== ""}
                          customInput={<CustomInputEvent />}
                          inline
                        />}
                    </div>
                  )}
                  {
                    !event && showLeague && (
                      <div className="dateContainer">
                        <MultiSelectDropdown
                          options={leagueOptionData && leagueOptionData}
                          onChange={handleTypeDropdownChange}
                          multiSelect
                          name="leagueName"
                          clearFilter={clearFilter}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          setOpenUserDropdown={setOpenUserDropdown}
                        />
                      </div>
                    )
                  }
                  {
                    showRange && (
                      <div className="rangeContainer">
                        <div className="rangeMain">
                          <span>£{range ? range[0] : minTotalPriceObject}</span>
                          <span>£{range ? range[1] : maxTotalPriceObject}</span>
                        </div>
                        <Slider range onChange={(e) => handleRange(e)} max={maxTotalPriceObject} min={minTotalPriceObject} allowCross={false} />
                      </div>
                    )
                  }
                  {!showCalendar && !showRange && !showArrival && !showDeparture && !showLeague && (
                    <div className="toggleSideBar">
                      <div onClick={() => { ToggleHeaderFunc(t("AIRPORT_DEPARTURE")); setShowDeparture(!showDeparture) }}>{t("AIRPORT_DEPARTURE")}</div>
                      <div onClick={() => { ToggleHeaderFunc(t("AIRPORT_ARRIVAL")); setShowArrival(!showArrival) }}>{t("AIRPORT_ARRIVAL")}</div>
                      <div onClick={() => { ToggleHeaderFunc(event ? t("MONTH_AND_YEAR") : t("DEPARTURE_DATE")); setShowCalendar(!showCalendar) }}>{event ? t("MONTH_AND_YEAR") : t("DEPARTURE_DATE")}</div>
                      <div onClick={() => { ToggleHeaderFunc(t("PRICE")); setShowRange(!showRange) }}>{t("PRICE")}</div>
                      {!event && <div onClick={() => { ToggleHeaderFunc(t("LEAGUE")); setShowLeague(!showLeague) }}>{t("LEAGUE")}</div>}
                    </div>
                  )}
                  <div className="sideBarBtn">
                    <CustomButton title={"VIEW RESULTS"} onClick={() => handleCloseSidebar && handleCloseSidebar()} />
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
              <div className="d-flex filter-Subgroup">
                <div className="filterToggleDiv" ref={departureDropdownRef}>
                  <button
                    className={
                      (isOpenDeparture || (location?.pathname?.includes("/dashboard") ? userFilter?.airportDeparture?.length > 0 : location?.pathname?.includes("/football") ? footballFilter?.airportDeparture?.length > 0 : eventFilter?.airportDeparture?.length > 0))
                        ? "active-toggle"
                        : "toggle-button"
                    }
                    onClick={() => handleDepartureToggleDropdown("departure")}
                    id='toggleBtn'
                  >
                    {t("AIRPORT_DEPARTURE")}   {location?.pathname?.includes("/dashboard") ? userFilter?.airportDeparture?.length > 0 && `(${userFilter?.airportDeparture?.length})` : location?.pathname?.includes("/football") ? footballFilter?.airportDeparture?.length > 0 && `(${footballFilter?.airportDeparture?.length})` : eventFilter?.airportDeparture?.length > 0 && `(${eventFilter?.airportDeparture?.length})`}
                    <span className="icon-downArrow"></span>
                  </button>
                  {isOpenDeparture && (
                    <MultiSelectDropdown
                      options={airportDepartureOptionData}
                      onChange={handleLocationDropdown}
                      multiSelect
                      name="airportDeparture"
                      clearFilter={clearFilter}
                    />
                  )}
                </div>
                <div ref={arrivalDropdownRef}>
                  <button
                    className={
                      (isOpenArrival || (location?.pathname?.includes("/dashboard") ? userFilter?.airportArrival?.length > 0 : location?.pathname?.includes("/football") ? footballFilter?.airportArrival?.length > 0 : eventFilter?.airportArrival?.length > 0)
                      )
                        ? "active-toggle"
                        : "toggle-button"
                    }
                    onClick={() => handleArrivalToggleDropdown("date")}
                  >
                    {t("AIRPORT_ARRIVAL")} {location?.pathname?.includes("/dashboard") ? userFilter?.airportArrival
                      ?.length > 0 && `(${userFilter?.airportArrival?.length})` : location?.pathname?.includes("/football") ? footballFilter?.airportArrival?.length > 0 && `(${footballFilter?.airportArrival?.length})` : eventFilter?.airportArrival?.length > 0 && `(${eventFilter?.airportArrival?.length})`}
                    <span className="icon-downArrow"></span>
                  </button>
                  {isOpenArrival && (
                    <MultiSelectDropdown
                      options={airportOptionData}
                      onChange={handleDropdownChange}
                      multiSelect
                      name="airportArrival"
                      clearFilter={clearFilter}
                    />
                  )}
                </div>
                <div  ref={dateContainerRef}  className={event ? "event dateContainer" : `dateContainer ${dateClass}`} id="dateMainDiv">
                 <button
                    className= {startDate ? "active-toggle" :"toggle-button "}
                    onClick={() => handleDateToggleDropdown("date")}
                  >{
                    startDate ? 
                      (location?.pathname?.includes("event") ? moment(startDate).format("MMM-yyyy") : moment(startDate).format("DD-MM-YYYY")) 
                      : (event ? <>{t("MONTH")}<span className="icon-downArrow"></span></> : <>{t("DEPARTURE_DATE")}<span className="icon-downArrow"></span></>)
                  }
                {startDate &&   <span className="icon-cross " onClick={()=>handleRemoveDate()}></span>}
                  </button>
                  {!event
                    ? isOpenDatePicker && <DatePicker
                      selected={startDate}
                      onChange={(update: any) => {
                        if (update === null) {
                          removeDate && removeDate()
                        }
                        setStartDate(update);
                      }}
                      className="user-date"
                      // customInput={<CustomInputUser />}
                      isClearable={startDate !== null && startDate !== ""}
                      inline
                      dateFormat="dd-MM-yyyy"
                    />
                    :  isOpenDatePicker && <DatePicker
                      selected={startDate}
                      renderMonthContent={renderMonthContent}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      minDate={new Date(currentYear, 0, 1)}
                      onChange={(update: any) => {
                        if (update === null) {
                          removeDate && removeDate()
                        }
                        setStartDate(update);
                      }}
                      className="event-picker text-center"
                      isClearable={startDate !== null && startDate !== ""}
                      inline
                    />}
                </div>

                <div ref={priceDropdownRef}>
                  <button
                    className={
                      isOpenPrice || (location?.pathname?.includes("/dashboard") ? userFilter?.totalPrice?.length > 0 : location?.pathname?.includes("/football") ? footballFilter?.totalPrice?.length > 0 : eventFilter?.totalPrice?.length > 0)
                        ? "active-toggle"
                        : "toggle-button"
                    }
                    onClick={() => handlePriceToggleDropdown("price")}
                  >
                    {t("PRICE")}
                    <span className="icon-downArrow"></span>
                  </button>
                  {isOpenPrice && (
                    <div className="rangeContainer" id="rangeDiv">
                      <div className="rangeMain">
                        <span>£{range ? range[0] : minTotalPriceObject}</span>
                        <span>£{range ? range[1] : maxTotalPriceObject}</span>
                      </div>
                      <Slider range value={range} onChange={(e) => handleRange(e)} min={minTotalPriceObject} max={maxTotalPriceObject} allowCross={false} />
                    </div>
                  )}
                </div>
                {!event &&
                  <div ref={leagueDropdownRef}>
                    <button
                      className={
                        (isOpenLeague|| (location?.pathname?.includes("/dashboard") ? userFilter?.leagueName
                          ?.length > 0 : footballFilter?.leagueName?.length > 0))
                          ? "active-toggle"
                          : "toggle-button"
                      }
                      onClick={() => handleLeagueToggleDropdown("league")}
                    >
                      {t("LEAGUE")}  {location?.pathname?.includes("/dashboard") ? userFilter?.leagueName
                        ?.length > 0 && `(${userFilter?.leagueName?.length})` : footballFilter?.leagueName?.length > 0 && `(${footballFilter?.leagueName?.length})`}
                      <span className="icon-downArrow"></span>
                    </button>
                    {isOpenLeague && (
                      <MultiSelectDropdown
                        options={leagueOptionData}
                        onChange={handleTypeDropdownChange}
                        multiSelect
                        name="leagueName"
                        clearFilter={clearFilter}
                      />
                    )}
                  </div>
                }
              </div>
            </>
          ) : (
            <>
              <div className="d-flex filter-group">
                <div  ref={dateContainerRef} className={`dateContainer ${dateClass}`} id="dateMainDiv">
                <button
                    className= {startDate ? "toggle-button justify-content-betwee text-nowrap" : "toggle-button"}
                    onClick={() => handleDateToggleDropdown("date")}
                  > {startDate && moment(startDate).isValid() ? moment(startDate).format("DD-MM-YYYY") : ""}{startDate && endDate && moment(startDate).isValid() && moment(endDate).isValid() ? " - " : ""}{endDate && moment(endDate).isValid() ? moment(endDate).format("DD-MM-YYYY") : ""}{!startDate && !endDate ? t("RENEWAL_DATE") : ""}
                {startDate && <span className="icon-cross" onClick={()=> clearDate && clearDate()}></span>}
                  </button>
                  {isOpenDatePicker && <DatePicker
                    selectsRange={true}
                    onChange={(update: any) => {
                      if (update[0] === null && update[1] === null) {
                        clearDate && clearDate()
                      }
                      setDateRange(update);
                    }}
                    className="datepicker"
                    // customInput={<CustomInput />}
                    startDate={startDate}
                    endDate={endDate}
                    isClearable={true}
                    inline
                  />}
                </div>
                <div ref={statusRef}>
                  <button
                    className={
                     isOpenStatus ||
                        filtersData?.filters?.status
                        ? "active-toggle"
                        : "toggle-button"
                    }
                    onClick={() => handleStatusToggleDropdown("status")}
                  >
                    {t("STATUS")}
                  </button>
                  {isOpenStatus && (
                    <MultiSelectDropdown
                      options={statusOption}
                      onChange={handleDropdownChange}
                      name="status"
                      clearFilter={clearFilter}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      setOpenUserDropdown={setOpenUserDropdown}
                    />
                  )}
                </div>
                <div ref={planRef}>
                  <button
                    className={
                      isOpenPlan || filtersData?.filters?.plan
                        ? "active-toggle"
                        : "toggle-button"
                    }
                    onClick={() => handlePlanToggleDropdown("plan")}
                  >
                    {t("TYPE")}
                  </button>
                  { isOpenPlan && (
                    <MultiSelectDropdown
                      options={typeOption}
                      onChange={handleTypeDropdownChange}
                      name="plan"
                      clearFilter={clearFilter}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      setOpenUserDropdown={setOpenUserDropdown}
                    />
                  )}
                </div>

                <div className="locationDropdown" ref={locationFilterRef}>
                  <button
                    className={
                      isOpenLocation ||
                        filtersData?.filters?.location?.length > 0
                        ? "active-toggle"
                        : "toggle-button"
                    }
                    onClick={() => handleLocationToggleDropdown("location")}
                  >
                    {t("LOCATION")}
                  </button>
                  {isOpenLocation && (
                    <MultiSelectDropdown
                      options={locationFilterData?.sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={handleLocationDropdown}
                      multiSelect
                      name="location"
                      clearFilter={clearFilter}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      setOpenUserDropdown={setOpenUserDropdown}
                    />

                  )}

                </div>
                <div>
                  {(hasAnyValue) &&
                    <button
                      className={"clear-btn"}
                      onClick={() => handleFilterOption && handleFilterOption()}
                    >
                      {t("CLEAR_ALL")}
                    </button>
                  }
                </div>
              </div>
            </>
          )}
        </>
      )}

      {showFilterResult && dashboard &&
        <>
          <SelectOptionComponent
            handleRemoveLeague={handleRemoveLeague}
            handleRemoveArrival={handleRemoveArrival}
            handleRemovePrice={handleRemovePrice}
            handleRemoveDate={handleRemoveDate}
            handleRemoveDeparture={handleRemoveDeparture}
            name={userFilter}
          />
        </>
      }
      {showFilterResult && football &&
        <>
          <SelectOptionComponent
            handleRemoveLeague={handleRemoveLeague}
            handleRemoveArrival={handleRemoveArrival}
            handleRemovePrice={handleRemovePrice}
            handleRemoveDate={handleRemoveDate}
            handleRemoveDeparture={handleRemoveDeparture}
            name={footballFilter}
          />
        </>
      }
      {showFilterResult && event &&
        <>
          <SelectOptionComponent
            handleRemoveArrival={handleRemoveArrival}
            handleRemovePrice={handleRemovePrice}
            handleRemoveDate={handleRemoveDate}
            handleRemoveDeparture={handleRemoveDeparture}
            name={eventFilter}
          />
        </>
      }
    </>
  );
};

export default TableHeader;

