// Auth
export const LOGIN = "/auth/login";
export const SOCIAL_LOGIN = "/auth/socialLogin";
export const FORGOT = "/auth/forgotPassword";
export const RESET = "/auth/resetPassword";
export const SIGNUP = "/auth/signUp";
export const RESEND_OTP = "/auth/resendOtp";
export const VERIFY_OTP = "/auth/verifyOtp";
export const SUBSCRIBE = "/auth/subscribe";
export const GET_USER_DETAILS = "/auth/getUserDetails";
export const  CREATE_ACCOUNT = "/auth/createAccount";

// User
export const LIST_USER = "/user/list";
export const CHANGE_USER_STATUS = "/user/changeUserStatus";
export const GET_USER_LOCATION = "auth/getLocations";

// event 
export const FILE_UPLOAD ="/event/uploadData";

// settings
export const CHANGE_PASSWORD = "/auth/changePassword"
export const RESET_PASSWORD = "/auth/setPassword"
export const UPDATE_PROFILE = "/auth/updateProfile"


// admin dashboard 
export const ADMIN_DASHBOARD ="/event/admin/eventList"

// user Dashboard
export const USER_DASHBOARD ="/event/eventList"
export const LEAGUE_LIST ="/event/leagueList"
export const AIRPORT_LIST ="/event/destinationAirportList"
export const AIRPORT_DEPARTURE = "/event/departingAirportList"
export const UPDATE_PLAN ="/auth/updateSubscription" //update plan 

//user event details 
export const EVENTS_DETAILS ="/event/eventDetails"
export const FOOTBALL_EVENTS = "/event/footballScreenEvents"

//calender event details
export const CALENDER_EVENTS_DETAILS ="/event/currentMonthEvents"
export const CALENDER_DATE_EVENT="/event/eventListByDate"


