import React from "react";
import { useTranslation } from "react-i18next";
import DashboardCard from "../../../components/cards/dashboardCard/dashboardCard";
import MatchCard from "../../../components/cards/dashboardCard/dashboardMatchCard";
import Loader from "../../../components/loader/Loader";
import StickyBox from "react-sticky-box";

interface DashboardProps {
  dashboardCardData: { title: string }[];
  matchCardData: {
    _id: number;
    tripStartArea: string;
    tripEndArea: string;
    date: string;
    team1: string;
    team2: string;
    amount: string;
  }[];
  dashboardDetails: any;
  loading: boolean;
  authResponse:any;
  matchCardDivRef: React.RefObject<HTMLDivElement>;
  handleNavigate:any
  userCountList:any
  scrollCount:number
}

const DashboardComponent: React.FC<DashboardProps> = ({
  dashboardDetails,
  loading,
  authResponse,
  matchCardDivRef,
  userCountList,
  handleNavigate,
  scrollCount
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {(loading && scrollCount === 1) ? <Loader /> : <div className="dashboardContainer adminDashboard">
        <h1 className="dashboardTitle">{t("WELCOME_JOE")} {authResponse?.data?.fullName ? authResponse.data.fullName.toUpperCase() : t("ADMIN")}</h1>
        <p className="dashboardDescription">{t("ADMIN_LOREM")}</p>
        <div className="subCards">
          {userCountList?.map((data: any, index: number) => (
            <DashboardCard key={index} dashboardCardData={data} />
          ))}
        </div>
        <StickyBox offsetTop={0} offsetBottom={20} style={{position:"sticky", zIndex:200, background:'var(--PRIMARY)'}} className="stickBox">
        <p className="matchHeader mb-0">{t("UPCOMING_MATCHES")}</p>
        </StickyBox>
       {<div className="admin-dashboardCards admin-matchCards">       
          <div ref={matchCardDivRef} className={dashboardDetails?.length ===0 ?" matchCards matchCardDiv matchCards-container d-flex w-100" :"matchCards matchCardDiv matchCards-container"} >
            {dashboardDetails?.length !==0 ?  dashboardDetails?.map((data: any, index: number) => (
              <MatchCard key={index} matchCardData={data} handleNavigate={handleNavigate}/>
            )):<div className="error-message w-100 d-flex justify-content-center">{t("NO_DATA")}</div>}
          </div>
            {(loading && scrollCount > 1) && <Loader pagination/> }
        </div>}
      </div>}
    </React.Fragment>
  );
};

export default DashboardComponent;
