import CryptoJS from 'crypto-js';
import Constant from '../../utils/constant';

interface OTPData {
  otp: string;
  timestamp: number;
}

// Function to store encrypted OTP with timestamp
export function storeEncryptedOTP(otp: string, secretKey: any): void {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const data = JSON.stringify(timestamp); // Combine OTP and timestamp
    // const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    localStorage.setItem(Constant.LOCALSTORAGEKEYS.TIMESTAMP,data)
    localStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, otp);
}

// Function to retrieve the OTP and timestamp
export function retrieveDecryptedOTP(secretKey: any): OTPData | null {
  const encryptedOTP = localStorage.getItem(Constant.SESSIONSTORAGEKEYS.OTP);
  if (!encryptedOTP) {
      // its necessary this console dont remove it 
      console.log('No OTP found in storage');
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedOTP, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    const data: OTPData = JSON.parse(decryptedData);

  return data;
}

// Function to validate OTP expiration
export function isOTPExpired(timestamp: any , expiryMinutes: number = 5): boolean {
  const currentTime = Date.now();
  const timeDiff = (currentTime - timestamp) / 1000 / 60; // Time difference in minutes
  return timeDiff < expiryMinutes;
}