import React from "react";

interface CardHeaderProps {
  title?: string;
  trip?: boolean;
  tripStart?: string;
  tripEnd?: string;
  className?: string;
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, trip, tripStart, tripEnd, className }) => {
  return (
    <div className={className}>
      {trip ? (
        <div className="trip">
         <span>{tripStart}</span>
          <span className="icon-aeroplane"></span>
         <span>{tripEnd}</span> 
        </div>
      ) : (
        <h2 className="cardTitle">{title}</h2>
      )}
    </div>
  );
};

export default CardHeader;
