import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import footballEventService from "./footballEventService";
import { listDashboardQueryParams } from "../../../interface/userInterface";


export  const footballDetails = createAsyncThunk("/event/football",async(query: listDashboardQueryParams)=>{
  const res = await footballEventService.footballEvents(query);
  return res
})

interface footballEventState {
  footballDetail:any,
  footballFilters: any;
  pagination:any,
  upcomingEventsList:any,
  totalLeagues:number
}

const footballSlice = createSlice({
  name: "football",
  initialState: {
    footballDetail:[],
    footballFilters:{},
    pagination:{page: 1,limit:20},
    upcomingEventsList :[],
    totalLeagues:0
  }as footballEventState,
  reducers:{
    setFootballFilters: (state, action) => {
			state.footballFilters = action.payload
		},
    paginationFunction : (state,action)=>{
      state.pagination = action?.payload
    }
  },
  extraReducers:(builder)=>{
    builder
    .addCase(footballDetails.pending, (state) => { })
    .addCase(footballDetails.fulfilled, (state, action) => {
      const {leagueWiseEvents,upcomingEvents,totalLeagues} = action.payload?.data;
      if (state.pagination.page === 1) {
          state.upcomingEventsList =  upcomingEvents
          state.footballDetail = leagueWiseEvents;
          state.totalLeagues = totalLeagues;
      }else {
        state.footballDetail = [...state.footballDetail,...leagueWiseEvents]
      }
    })
    .addCase(footballDetails.rejected, (state, action) => {
      state.footballDetail = action.error;
    });
  }
})

export  const {setFootballFilters,paginationFunction} = footballSlice?.actions
export default footballSlice.reducer;