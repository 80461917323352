  import React, { useState, useEffect } from "react";
  import { useSelector, useDispatch } from "react-redux";
  import { Toast } from "react-bootstrap";
  import { hideToast } from "../../store/features/toast/toastSlice";

  interface ToastType {
    type: "success" | "error" | "info";
    show: boolean;
    customClass?: string;
    message?: string;
    time:number | string
  }

  const ToastComponent: React.FC = () => {
    const toast: ToastType = useSelector((state: any) => state.toast);
    const [showToast, setShowToast] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setShowToast(toast.show);
    }, [toast.show]);


    let toastClass: string | undefined;
    let iconClass: string | undefined = toast.customClass;

    if (toast.type === "success") {
      toastClass = "success";
      iconClass = "doneAll";
    } else if (toast.type === "error") {
      toastClass = "error";
      iconClass = "error";
    } else if (toast.type === "info") {
      toastClass = "info";
      iconClass = "error";
    }

    return (
      <Toast
        onClose={() => {
          const time = toast?.time ? +toast?.time : 1000
          setTimeout(() => {
            setShowToast(false);
            dispatch(hideToast());
          }, time);
        }}
        className={toastClass}
        show={showToast}
        delay={3000}
        autohide
        animation={true}
        key={toast?.message + toast?.type}
      >
        <div className="toastCenter">
          <span className={`icon-${iconClass}`}></span>
          {toast?.message !== "Token expired. Please authenticate again!" && toast.message  && (
            <div
              className="txtcontain"
              key={`${toast.message + toast?.type }`} // Unique key generation
              dangerouslySetInnerHTML={{ __html: toast.message }}
            />
          )}
        </div>
      </Toast>
    );
  };

  export default ToastComponent;
